import { Button, DatePicker, Form, Select, Tabs } from 'antd';
import axios from "axios";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getReceivedSkidBarcodes, getUsers } from '../../../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';

let dateFormat = require('dateformat');
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TabPane } = Tabs;

export const ReportUI = (params) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);
  const barCodes = useSelector(state => state.receivedSkidsBarcodes);
  const allusers = useSelector(state => state.users);
  const [companies, setcompanies] = useState([]);
  const [loading, setloading] = useState(false);


  useEffect(() => {
    if (!user || (user.role !== "superAdmin" && user.role !== "admin" && user.role !== "businessRep")) {
      history.push('/');
    } else {
      getUsers(user, dispatch);
      getReceivedSkidBarcodes(user, dispatch);
    }
  }, []);

  useEffect(() => {
    if (allusers) {
      setcompanies(allusers.filter(
        user => user.companyName !== "LACERO" && user.role === "client"
      ));
    }
  }, [allusers])



  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 10,
      span: 12,
    },
  };
  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };

  const onNextProcessReport = async (values) => {

    try {
      let endDate = moment(values.date[1]).add(1, 'days').format('YYYY-MM-DD');

      const data = {
        'companyID': values.companyID,
        'startDate': values.date[0].format('YYYY-MM-DD'),
        'endDate': dateFormat(endDate, "yyyy-mm-dd"),
      };

      const options = HEADER_OPTIONS_AUTH(user.token);
      options.responseType = 'blob';
      //send request to get a blob xlsx report
      setloading(true);
      await axios.post(`${BASE_URL}/download-processed-item-report`, data, options)
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `processed_report_${data.startDate}_to_${data.endDate}.xlsx`);
          document.body.appendChild(link);
          link.click();
          openNotificationWithIcon('success', `Download Report Success`);
          setloading(false);
        })
        .catch(function (error) {
          setloading(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.statusText
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    } catch (e) {
      console.log(e);
      setloading(false);
      openNotificationWithIcon('error', `Download Report Failed`)
    }

  }


  const onNextSkidReport = async (values) => {
    console.log(values.skidBarcode);
    try {
      const skidBarCode = values.skidBarcode;
      const data = {
        'skidBarcode': values.skidBarcode
      };

      const options = HEADER_OPTIONS_AUTH(user.token);
      options.responseType = 'blob';
      //send request to get a blob xlsx report
      setloading(true);
      await axios.post(`${BASE_URL}/download-processed-item-report-skid`, data, options)
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `processed_report_${skidBarCode}.xlsx`);
          document.body.appendChild(link);
          link.click();
          openNotificationWithIcon('success', `Download Report Success`);
          setloading(false);
        })
        .catch(function (error) {
          setloading(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    } catch (e) {
      console.log(e);
      setloading(false);
      openNotificationWithIcon('error', `Download Report Failed`)
    }

  }

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Process Report" key="1">
          <Form
            {...layout}
            name="process-report"
            onFinish={onNextProcessReport}
          >
            <Form.Item name="companyID" label="Company" rules={[{ required: true, message: "Please select company!" }]}>
              <Select
                placeholder="Select a company"
                allowClear
              >
                {companies.map((company, index) => {

                  return (
                    <Option value={company.companyID} key={index}>{company.companyName}</Option>
                  )
                })}
              </Select>
            </Form.Item>

            <Form.Item name="date" label="Date" {...rangeConfig}>
              <RangePicker />
            </Form.Item>


            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
                    </Button>
            </Form.Item>
          </Form>
        </TabPane>




        {/* skid report */}
        <TabPane tab="Specific Skid Report" key="2">
          <Form
            {...layout}
            name="skid-report"
            onFinish={onNextSkidReport}
          >
            <Form.Item name="skidBarcode" label="Skid" rules={[{ required: true, message: "Please select skid!" }]}>
              <Select
                showSearch
                style={{ width: 350 }}
                placeholder="Search a Skid"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                }

                }
              >
                {barCodes && barCodes.map((skid, index) => <Option value={skid.barCode} key={index}>{skid.barCode}</Option>)}
              </Select>
            </Form.Item>



            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
                    </Button>
            </Form.Item>
          </Form>
        </TabPane>
      </Tabs>
    </div>

  );

}
