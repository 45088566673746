import {
  CheckCircleTwoTone
} from '@ant-design/icons';
import { notification } from "antd";
import localStorage from "local-storage";
import React, { useEffect, useState } from "react";
import {
  Button, Container,

  Form, Jumbotron,


  Nav
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from '../actions/index';
import {
  TEST_HOME_PAGE
} from "./lib/basic";


export const LoginUI = (params) => {

  const dispatch = useDispatch();
  let history = useHistory();
  const user = useSelector(state => state.authenticated);
  const errorMessage = useSelector(state => state.errorMessage);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (user) {
      history.push("/home");
    }
  }, [])

  const openNotification = () => {
    let notificationText = "Your access session will be an hour";
    notification.open({
      message: `Hi`,
      description: notificationText,
      icon: < CheckCircleTwoTone twoToneColor="#52c41a" />
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === true) {
      event.stopPropagation();
      const formData = new FormData(event.target),
        formDataObj = Object.fromEntries(formData.entries())

      await login(formDataObj, dispatch);
      if (user.role === "admin" || user.role === "superAdmin") {
        localStorage.clear();
        openNotification();
        history.push("/home");
      } else {
        window.location.replace(TEST_HOME_PAGE);
      }
    }
    setValidated(true);
  };

  return (
    <Container className={"loading"}>
      <style type="text/css">
        {`
                        .loading {
                            background-color: red;
                            min-height: 100vh;
                            min-width: 100vw;
                            background-image: url('/img/background.jpg');
                            background-size: 120vw;
                            opacity: 0.6;
                        }
          
                        .vertical-center {
                           width: 50vh;
                           margin: 0 auto;
                           background: linear-gradient( rgba(0, 255, 204,0.5),rgba(0, 204, 102, 1));
                        }
                        .btn-flat {
                          background-color: purple;
                          color: white;
                          margin: 3vh auto 0;
                        }
                    
                        .btn-xxl {
                          padding: 1rem 1.5rem;
                          font-size: 1.5rem;
                        }
                       
                        .login{
                             text-align: center;
                             margin-bottom: 1em;
                             color: white
                        }
                        .login-label{
                           font-size: 1.5rem;
                           font-weight:bold; 
                        }
                        
                    `}
      </style>
      <hr style={{ marginBottom: "20vh", marginTop: "0" }} />
      <Jumbotron className="vertical-center">
        <h1 className={"login"}>LOG IN</h1>
        <h2 style={{ textAlign: "center", color: "red" }}>
          {errorMessage}
        </h2>
        <Form noValidate validated={validated} onSubmit={handleLogin}>
          <Form.Group controlId="formGroupEmail">
            <Form.Label className={"login-label"}>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name={"email"}
              required
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please provide a valid email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formGroupPassword">
            <Form.Label className={"login-label"}>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              name={"password"}
              required
            ></Form.Control>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please input password.
            </Form.Control.Feedback>
          </Form.Group>
          <Nav.Link
            href="/reset-password"
            style={{ textAlign: "right", fontSize: "1.5em" }}
          >
            forget password
          </Nav.Link>

          <Button variant="primary" type="submit" size="lg">
            Log In
            </Button>
        </Form>

      </Jumbotron>
    </Container>
  )

}
