import { GET_USERS, UPDATE_USER } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {
        case GET_USERS:
            return action.payload;
        case UPDATE_USER:
            return state.map((user) => {
                if (user.id === action.payload.id) {
                    // Return a new object
                    return action.payload;
                }
                return user;
            });
        default:
            return state;
    }
}

// const newItem = 0;
// return [    // a new array
//     newItem,  // add the new item first
//     ...state  // then explode the old state at the end
// ];