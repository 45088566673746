import { Button, Col, Form, InputNumber, Row, Select, Tooltip } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProducts, getSkidsForProductTransfer } from '../../../../actions/index';
import {
  BASE_URL,
  HEADER_OPTIONS_AUTH
} from "../../../lib/basic";
import { openNotificationWithIcon } from "../../../lib/utils";
const { Option } = Select;


export const ProductTransfer = (data) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);
  const skids = useSelector(state => state.skidsForProductTransfer);
  const products = useSelector(state => state.products);

  const [form] = Form.useForm();
  const [productNameMap, setproductNameMap] = useState({});
  const [originCompanyList, setoriginCompanyList] = useState({});
  const [formattedData, setformattedData] = useState({});
  const [destSkidData, setdestSkidData] = useState({});
  const [selectedOriginCompanyName, setSelectedOriginCompanyName] = useState(undefined);
  const [selectedDestCompanyName, setSelectedDestCompanyName] = useState(undefined);
  const [selectedOriginBarCode, setSelectedOriginBarCode] = useState(undefined);
  const [selectedDestBarCode, setSelectedDestBarCode] = useState(undefined);
  const [selectedOriginProductId, setSelectedOriginProductId] = useState(undefined);
  const [selectedDestProductId, setSelectedDestProductId] = useState(undefined);
  const [transferQuantity, settransferQuantity] = useState(undefined);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (!user || (user.role !== "superAdmin" && user.role !== "admin")) {
      history.push('/');
    } else {
      getSkidsForProductTransfer(user, dispatch);
      getProducts(user, dispatch);
    }
  }, []);

  useEffect(() => {
    if (skids && Object.getOwnPropertyNames(formattedData).length === 0) {
      formatData(skids);
    }
  }, [skids]);

  useEffect(() => {
    if (products && Object.getOwnPropertyNames(productNameMap).length === 0) {
      createProductNameMap(products);
    }
  }, [products]);

  const createProductNameMap = products => {
    let productsMap = {};
    products.forEach(product => {
      let id = product.id;
      productsMap[id] = product.productName;
    });
    setproductNameMap({ ...productsMap });
  }

  const handleFormValuesChange = changedValues => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "originCompanyName") {
      setSelectedOriginCompanyName(changedValues[formFieldName]);
      setSelectedOriginBarCode(undefined);
      setSelectedOriginProductId(undefined);
      settransferQuantity(undefined);
      form.setFieldsValue({ originBarCode: undefined });
      form.setFieldsValue({ originProduct: undefined });
      form.setFieldsValue({ transferQuantity: undefined });
    } else if (formFieldName === "destCompanyName") {
      setSelectedDestCompanyName(changedValues[formFieldName]);
      setSelectedDestBarCode(undefined);
      setSelectedDestProductId(undefined);
      form.setFieldsValue({ destBarCode: undefined });
      form.setFieldsValue({ destProduct: undefined });
    } else if (formFieldName === "originBarCode") {
      setSelectedOriginBarCode(changedValues[formFieldName]);
      setSelectedOriginProductId(undefined);
      settransferQuantity(undefined);
      form.setFieldsValue({ originProduct: undefined });
      form.setFieldsValue({ transferQuantity: undefined });
    } else if (formFieldName === "destBarCode") {
      setSelectedDestBarCode(changedValues[formFieldName]);
      setSelectedDestProductId(undefined);
      form.setFieldsValue({ destProduct: undefined });
    } else if (formFieldName === "originProduct") {
      settransferQuantity(undefined);
      form.setFieldsValue({ transferQuantity: undefined });
      setSelectedOriginProductId(changedValues[formFieldName]);
    } else if (formFieldName === "destProduct") {
      setSelectedDestProductId(changedValues[formFieldName]);
    } else if (formFieldName === "transferQuantity") {
      settransferQuantity(changedValues[formFieldName]);
    }
  };

  const formatData = async (skids) => {
    let formattedData = {};
    let destSkidsProductData = {};
    skids.map((skid) => {
      let companyName = skid.companyName;
      let barCode = skid.barCode;
      let productName = skid.productName;

      if (!formattedData.hasOwnProperty(companyName)) {
        formattedData[companyName] = {};
      }
      if (!formattedData[companyName].hasOwnProperty(barCode)) {
        formattedData[companyName][barCode] = [];
      }
      if (productName !== "null" && !formattedData[companyName][barCode].hasOwnProperty(productName)) {
        formattedData[companyName][barCode][productName] = [skid]
        if (!originCompanyList.hasOwnProperty(companyName)) {
          let newOriginCompanyList = originCompanyList;
          newOriginCompanyList[companyName] = true;
          setoriginCompanyList(newOriginCompanyList);
        }
      }

      if (!destSkidsProductData.hasOwnProperty(barCode)) {
        destSkidsProductData[barCode] = [skid];;
      }

    })
    setformattedData(formattedData);
    setdestSkidData(destSkidsProductData);
    setloading(false);
  }

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  const onNext = async (values) => {
    let data = { ...values };
    data["originProduct"] = formattedData[selectedOriginCompanyName][selectedOriginBarCode][selectedOriginProductId][0]["productId"];
    data["originSkidId"] = formattedData[selectedOriginCompanyName][selectedOriginBarCode][selectedOriginProductId][0]["skidId"];
    data["destSkidId"] = destSkidData[selectedDestBarCode][0]["skidId"];
    data["destCompanyId"] = destSkidData[selectedDestBarCode][0]["companyId"];

    try {
      const options = HEADER_OPTIONS_AUTH(user.token);
      setloading(true);
      await axios.patch(`${BASE_URL}/productTransfer`, data, options)
        .then(function (response) {
          setloading(false);
          openNotificationWithIcon(
            "success",
            "Products Transfered successfully."
          );
          setSelectedOriginCompanyName(undefined);
          setSelectedOriginBarCode(undefined);
          setSelectedOriginProductId(undefined);
          settransferQuantity(undefined);
          setSelectedDestCompanyName(undefined);
          setSelectedDestBarCode(undefined);
          setSelectedDestProductId(undefined);
          form.setFieldsValue({ originCompanyName: undefined });
          form.setFieldsValue({ originBarCode: undefined });
          form.setFieldsValue({ originProduct: undefined });
          form.setFieldsValue({ transferQuantity: undefined });
          form.setFieldsValue({ destCompanyName: undefined });
          form.setFieldsValue({ destBarCode: undefined });
          form.setFieldsValue({ destProduct: undefined });
          setformattedData({});
          getSkidsForProductTransfer(user, dispatch);
        })
        .catch(function (error) {
          setloading(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });


    }
    catch (e) {
      alert(e.message);
      setloading(false);
      return (e.message)
    }


  }

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginBottom: "20px" }}>Product Transfer</h1>

      <Form
        {...layout}
        size="middle"
        className="product-transfer-form"
        form={form}
        requiredMark={false}
        onFinish={(val) => { if (window.confirm("Do you confirm to transfer products?")) { onNext(val) } }}
        onValuesChange={handleFormValuesChange}
      >
        <Row>
          <Col span={11}>

            <h4 style={{ textAlign: 'center', marginTop: "20px", marginBottom: "30px" }}>~Origin Skid~</h4>

            <Form.Item
              label="Company/Customer"
              rules={[
                { required: true, message: "Please select Company!" }
              ]}
              name="originCompanyName"
            >
              <Select style={{ width: "90%" }} name="originCompanyName">
                {Object.keys(originCompanyList).map((companyName, index) => {
                  return (
                    <Option key={index} value={companyName}>
                      {companyName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

          </Col>
          <Col span={13}>
            <h4 style={{ textAlign: 'center', marginTop: "20px", marginBottom: "30px" }}>~Destination Skid~</h4>

            <Form.Item
              label="Company/Customer"

              rules={[
                { required: true, message: "Please select Company!" }
              ]}
              name="destCompanyName"

            >
              <Select style={{ width: "90%" }} name="destCompanyName">
                {Object.keys(formattedData).map((companyName, index) => {
                  return (
                    <Option key={index} value={companyName}>
                      {companyName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

          </Col>
        </Row>
        <Row>
          <Col span={11}>

            <Form.Item
              label="Origin Skid BarCode"
              name="originBarCode"
              hidden={!selectedOriginCompanyName}
              rules={[
                { required: true, message: "Please select Origin Barcode!" }
              ]}
            >
              <Select
                style={{ width: "90%" }} name="originBarCode"
              >
                {selectedOriginCompanyName ? Object.keys(formattedData[selectedOriginCompanyName]).filter(barCode => Object.keys(formattedData[selectedOriginCompanyName][barCode]).length > 0)
                  .map(barCode => (
                    <Option key={barCode} value={barCode}>
                      {barCode}
                    </Option>
                  )) : null}
              </Select>
            </Form.Item>
          </Col>

          <Col span={13}>
            <Form.Item
              label="Destination Skid BarCode"
              name="destBarCode"
              hidden={!selectedDestCompanyName}
              rules={[
                { required: true, message: "Please select Destination Barcode!" }
              ]}

            >
              <Select
                style={{ width: "90%" }} name="destBarCode"
              >
                {selectedDestCompanyName ? Object.keys(formattedData[selectedDestCompanyName]).map(barCode => (
                  <Option key={barCode} value={barCode}>
                    {barCode}
                  </Option>
                )) : null}
              </Select>
            </Form.Item>
          </Col>

        </Row>

        <Row>
          <Col span={11}>

            <Form.Item
              label="Origin Product"
              name="originProduct"
              hidden={!(selectedOriginCompanyName && selectedOriginBarCode && Object.keys(formattedData[selectedOriginCompanyName][selectedOriginBarCode]).length > 0)}
              rules={[
                { required: true, message: "Please select Origin Product Name!" }
              ]}
            >
              {selectedOriginCompanyName && selectedOriginBarCode && Object.keys(formattedData[selectedOriginCompanyName][selectedOriginBarCode]).length > 0 ?

                <Select
                  style={{ width: "90%" }} name="originProduct"
                >
                  {Object.keys(formattedData[selectedOriginCompanyName][selectedOriginBarCode]).map(productName => (
                    <Option key={productName} value={productName}>
                      {productName}
                    </Option>
                  ))}
                </Select> : <div></div>}
            </Form.Item>
          </Col>

          <Col span={13}>
            <Form.Item
              label="Destination Product"
              name="destProduct"
              hidden={!selectedDestBarCode}
              rules={[
                { required: true, message: "Please select Destination Product Name!" }
              ]}

            >
              <Select
                style={{ width: "90%" }} name="destProduct"
              >
                {selectedDestBarCode ? Object.keys(JSON.parse(destSkidData[selectedDestBarCode][0]["skidContent"])).map(productId => (
                  <Option key={productId} value={productId}>
                    {productNameMap[productId]}
                  </Option>
                )) : null}
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col span={11}>
            {selectedOriginCompanyName && selectedOriginBarCode && selectedOriginProductId &&
              <Tooltip title={"Max Allowed Quantity is " + formattedData[selectedOriginCompanyName][selectedOriginBarCode][selectedOriginProductId][0]["processedQuantity"]}>
                <Form.Item
                  label="Transfer Quantity"
                  name="transferQuantity"
                  hidden={!(selectedOriginCompanyName && selectedOriginBarCode && selectedOriginProductId)}
                  rules={[
                    { required: true, message: "Please select Transfer Quantity!" }
                  ]}
                >
                  <InputNumber
                    min={1}
                    max={formattedData[selectedOriginCompanyName][selectedOriginBarCode][selectedOriginProductId][0]["processedQuantity"]}
                    name="transferQuantity"
                  />
                </Form.Item>
              </Tooltip>
            }
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col span={14} offset={10}>
            <Form.Item
              hidden={!(selectedDestProductId && transferQuantity)}
            >
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Transfer
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )

}