import {
  ArrowUpOutlined
} from '@ant-design/icons';
import {
  Card,




  Col, InputNumber,




  Row, Statistic,


  Tag
} from "antd";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from "../../../lib/utils";

class BufferSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bufferList: []
    };
  }

  componentWillMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const user = this.props.authenticated;
      const options = HEADER_OPTIONS_AUTH(user.token);
      await axios.get(`${BASE_URL}/adjustmentBuffer`, options)
        .then(function (response) {
          let bufferList = response.data.data;
          this.setState({ bufferList });
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    } catch (error) {
      openNotificationWithIcon("error", `Getting data failed`);
    }
  };

  onChange = async (newBuffer, value) => {
    const user = this.props.authenticated;
    const options = HEADER_OPTIONS_AUTH(user.token);
    try {
      let bufferList = this.state.bufferList;
      for (let buffer of bufferList) {
        if (buffer === newBuffer) {
          buffer.buffer_percentage = value
        }
      }
      await axios.put(`${BASE_URL}/updateAdjustmentBuffer`, newBuffer, options)
        .then(function (response) {
          this.setState({
            bufferList
          });
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    } catch (error) {
      openNotificationWithIcon("error", `update buffer failed`);
    }
  };

  _renderBufferList = () => {
    let bufferRenderedList = [];
    let bufferList = this.state.bufferList;
    bufferList.forEach(buffer => {
      // console.log(buffer.buffer_percentage)
      bufferRenderedList.push(
        <Row gutter={16}>
          <Col span={24}>
            <Card>

              <Statistic
                title={
                  <p style={{ fontWeight: "bold", color: "black" }}>
                    {buffer.type}
                    <Tag color="#f50">{buffer.companyName}</Tag>
                  </p>
                }
                value={buffer.buffer_percentage * 100}
                precision={2}
                valueStyle={{ color: "#3f8600" }}
                prefix={<ArrowUpOutlined />}
                suffix="%"
              />

              <InputNumber
                min={0}
                max={1}
                step={0.01}
                style={{ marginLeft: 16 }}
                value={buffer.buffer_percentage}
                onChange={(value) => this.onChange(buffer, value)}
              />

            </Card>
          </Col>
        </Row>
      );
    });
    return bufferRenderedList;
  };

  render() {
    const bufferRenderedList = this._renderBufferList();

    return (
      <div>
        <h1 align="center">Buffer Setting</h1>
        <div style={{ background: "#ECECEC", padding: "30px" }}>
          {bufferRenderedList}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    authenticated: state.authenticated,
    errorMessage: state.errorMessage,
    skids: state.skids,
    warehouses: state.warehouses,
    users: state.users,
    processItems: state.processItems,
    companies: state.companies,
    priceList: state.priceList
  };
};

export default connect(
  mapStateToProps,
  actions
)(BufferSetting);
