import { BarcodeOutlined, ContactsOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Button,









  Card, Col,




  DatePicker, Divider, Form,
  Input,



  InputNumber, Popconfirm, Row, Select, Tabs
} from "antd";
import "antd/dist/antd.css";
import axios from "axios";
import localStorage from "local-storage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import { getCarriers, getClients, getProducts, getPurchaseOrders, getSkidDimensions, getUsers, getWarehouse } from "../../actions/index";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../components/lib/basic";
import { openNotificationWithIcon } from "../lib/utils";
const { TabPane } = Tabs;

export const ManageShipmentUI = (params) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);
  const allusers = useSelector(state => state.users);
  const products = useSelector(state => state.products)
  const warehouses = useSelector(state => state.warehouses)
  const purchaseOrders = useSelector(state => state.purchaseOrder)
  const carriers = useSelector(state => state.carriers)
  const skidDimensions = useSelector(state => state.skidDimensions)[0]
  const [users, setusers] = useState([]);
  const [activeTab, setactiveTab] = useState("1");


  useEffect(() => {
    if (
      !user ||
      (user.role !== "superAdmin" &&
        user.role !== "admin" &&
        user.role !== "businessRep")
    ) {
      history.push("/");
    } else {

      getUsers(user, dispatch);
      getProducts(user, dispatch);
      getWarehouse(user, dispatch);
      getPurchaseOrders(user, dispatch);
      getCarriers(user, dispatch);
      getSkidDimensions(user, dispatch);
      getClients(user, dispatch);

    }
  }, []);

  useEffect(() => {
    if (allusers) {
      setusers(allusers.filter(
        user => user.companyName !== "LACERO" && user.role === "client"
      ));
    }
  }, [allusers]);

  const changeTab = async (activeKey) => {
    setactiveTab(activeKey);
  };


  const downoladExistingLabels = async (POId, shipDate) => {

    const options = HEADER_OPTIONS_AUTH(user.token);

    await axios.get(`${BASE_URL}/getSkidsByPOIdShipDate/${POId}/${shipDate}`, options)
      .then(function (response) {
        localStorage.set("newRowsPO", response.data.data);
        window.open("/SkidLabelDocumentPO");
      })
      .catch(function (error) {

        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }

  const ManageShipmentTabs = () => {
    return (
      <Tabs
        type="card"
        centered
        activeKey={activeTab}
        onChange={changeTab}
      >
        <TabPane tab="List" key="1">
          <ListTab />
        </TabPane>
        <TabPane tab="Create New" key="2">
          <CreateNewTab />
        </TabPane>
        <TabPane tab="Labels" key="3">
          <DownloadExistingLabelsTab />
        </TabPane>
      </Tabs>
    );
  };

  const DownloadExistingLabelsTab = () => {
    const { Option } = Select;
    const [selectedPO, setselectedPO] = useState(undefined);
    const [form] = Form.useForm();
    const onNext = values => {
      downoladExistingLabels(selectedPO.id, values.scheduledShipDate)
    }

    const setSelectedPO = (index) => {
      setselectedPO(purchaseOrders[index])
      form.setFieldsValue({ scheduledShipDate: purchaseOrders[index].shipDateArray.split(',')[0] });
    }

    return (

      <div style={{ padding: "5px" }}>
        <Form
          layout="vertical"
          form={form}
          size="medium"
          onFinish={onNext}
        >
          <Row>
            <Col span={8}></Col>
            <Col span={12} >
              <h3 style={{ width: "100%", marginTop: "20px", marginBottom: "25px" }}>
                Download Existing Labels
                <BarcodeOutlined
                  style={{ color: "#30394e", marginLeft: "15px" }}
                />
              </h3>

              <Form.Item
                label="Order Number"
                name="orderNumber"
                style={{ width: "50%" }}
                rules={[
                  { required: true, message: "Please select Order Number!" }
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  onChange={setSelectedPO}
                >
                  {purchaseOrders ? purchaseOrders.map((po, index) => {
                    return (
                      <Option key={index} value={index}>
                        {po.orderNumber}
                      </Option>
                    );
                  }) : <div></div>}
                </Select>
              </Form.Item>
              {selectedPO ? (
                <div>
                  <Form.Item
                    label="Shipment Date"
                    name="scheduledShipDate"
                    style={{ width: "50%" }}
                    rules={[
                      { required: true, message: "Please input Shipment Date!" }
                    ]}

                    initialValue={selectedPO ? (selectedPO.shipDateArray.split(','))[0] : ''}
                  >
                    <Select
                      style={{ width: "100%" }}
                    >
                      {selectedPO ? selectedPO.shipDateArray.split(',').map((shipDate, index) => {
                        return (
                          <Option key={index} value={shipDate}>
                            {shipDate}
                          </Option>
                        );
                      }) : <div></div>}
                    </Select>

                  </Form.Item>

                  <Form.Item
                    style={{ marginTop: "35px" }}
                  >


                    <Button
                      type="primary"
                      htmlType="submit"
                      size="medium"
                    >
                      Download
                </Button>
                  </Form.Item>
                </div>) : <div></div>}
            </Col>
          </Row>
        </Form>

      </div>)

  }

  const CreateNewTab = () => {
    const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showCreate, setshowCreate] = useState(false);
    const [skidList, setSkidList] = useState([]);
    const [newPurchaseOrder, setnewPurchaseOrder] = useState({});
    const [priceMap, setPriceMap] = useState({});
    const [productNameMap, setproductNameMap] = useState({});
    const { Option } = Select;
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const addProductToPriceMap = productId => {
      products.filter(product => product.id === productId).map(product => {
        let newPriceMap = priceMap;
        product.priceList.forEach(priceItem => {
          newPriceMap[priceItem.priceId] = priceItem.price;
        });
        setPriceMap({ ...newPriceMap });
      });
    };

    const removeProductFromPriceMap = productId => {
      products.filter(product => product.id === productId).map(product => {
        let newPriceMap = priceMap;
        product.priceList.forEach(priceItem => {
          delete newPriceMap[priceItem.priceId];
        });
        setPriceMap({ ...newPriceMap });
      });
    };

    const updatePriceMapValue = (key, newValue) => {
      let newPriceMap = priceMap;
      newPriceMap[key] = newValue;
      setPriceMap({ ...newPriceMap });
    };

    const handleFormValuesChange = changedValues => {
      const formFieldName = Object.keys(changedValues)[0];
      if (formFieldName === "companyId") {
        setSelectedCompanyId(changedValues[formFieldName]);
        setSelectedProducts([]);
        setPriceMap({});
        form.setFieldsValue({ products: undefined }); //reset product selection
      }
      if (formFieldName === "products") {
        setSelectedProducts(changedValues[formFieldName]);
      }
    };

    const onNext = values => {
      var newPurchaseOrder = {};
      newPurchaseOrder.scheduledShipDate = moment(
        values.scheduledShipDate
      ).format("YYYY-MM-DD");
      newPurchaseOrder.orderNumber = values.orderNumber;
      newPurchaseOrder.assignedWarehouseID = values.assignedWarehouseID;
      newPurchaseOrder.carrierName = values.carrierName;
      newPurchaseOrder.custOrderNum = values.custOrderNum;
      newPurchaseOrder.carrierPhone = values.carrierPhone
        ? values.carrierPhone
        : "";
      newPurchaseOrder.custOrderNum = values.custOrderNum
        ? values.custOrderNum
        : "";
      newPurchaseOrder.priceMap = priceMap;
      newPurchaseOrder.trackingNumber = values.trackingNumber
        ? values.trackingNumber
        : "";
      newPurchaseOrder.productList = JSON.stringify(values.products);
      newPurchaseOrder.origin = values.origin;
      newPurchaseOrder.companyId = values.companyId;
      newPurchaseOrder.businessRepId = values.businessRepId;
      users.forEach(user => {
        if (user.companyID === values.companyId) {
          newPurchaseOrder.clientId = user.id;
        }
      });
      setnewPurchaseOrder(newPurchaseOrder);
      // console.log(this.state.newPurchaseOrder);

      let newSkid = {};
      values.products.forEach(productId => {
        newSkid[productId] = 0;
      });
      let newSkidList = [];
      newSkidList.push(newSkid);
      setSkidList([...newSkidList]);
      let productsMap = {};
      products.forEach(product => {
        let id = product.id;
        productsMap[id] = product.productName;
      });
      setproductNameMap({ ...productsMap });
      setshowCreate(true);
    };

    const createPurchaseOrder = async values => {
      newPurchaseOrder.orderLines = skidList;
      newPurchaseOrder.skidNumber = skidList.length;

      //    let expectedItems = [];
      //  let actualItems = [];

      // skidList.forEach(skid => {
      //   var totalUnits = 0;
      //   Object.keys(skid).forEach(key => {
      //     totalUnits += parseInt(skid[key], 10);
      //   });
      //   expectedItems.push(totalUnits);
      //   actualItems.push(0);
      // });
      //  newPurchaseOrder.expectedItems = JSON.stringify(expectedItems);
      //newPurchaseOrder.acturalItems = JSON.stringify(actualItems);
      await createNewPurchaseOrder(user, newPurchaseOrder);
    };

    const createNewPurchaseOrder = async (user, data) => {
      setLoading(true);
      try {
        const options = HEADER_OPTIONS_AUTH(user.token);

        await axios.post(
          `${BASE_URL}/insertNewPurchaseOrder`,
          data,
          options
        )
          .then(function (response) {
            setLoading(false);
            localStorage.set("newRowsPO", response.data.rows);
            window.open("/SkidLabelDocumentPO");
            window.location.reload();
          })
          .catch(function (error) {
            setLoading(false);
            if (error.response) {
              // Request made and server responded
              openNotificationWithIcon(
                "error",
                error.response.data.message
              );
            } else if (error.request) {
              // The request was made but no response was received
              openNotificationWithIcon(
                "error",
                "No internet!"
              );
            } else {
              // Something happened in setting up the request that triggered an Error
              openNotificationWithIcon(
                "error",
                error.message
              );
            }
          });

      } catch (e) {
        if (e.message.includes('409')) {
          alert("Please enter a unique PO#. Duplicate PO#'s are not allowed while creating new orders");
        } else {
          alert(e.message);
        }
        setLoading(false);
      }
    };

    const updateSkidQuantity = (skid, key, e, index) => {
      skid[key] = e;
      let newSkidList = skidList;
      newSkidList.splice(index, 1, skid);
      setSkidList([...newSkidList]);
    };

    const deleteSkid = index => {
      if (index !== 0) {
        let newSkidList = skidList;
        newSkidList.splice(index, 1);

        setSkidList([...newSkidList]);
      }
    };

    const insertSkid = skid => {
      setSkidList(skidList.concat([{ ...skid }]));
    };

    return (
      <div style={{ padding: "5px" }}>
        <Form
          layout="vertical"
          size="medium"
          form={form}
          className="shipping-form"
          requiredMark={true}
          onFinish={onNext}
          onValuesChange={handleFormValuesChange}
        >
          <Row>
            <Col span={12} style={{ paddingLeft: "30px" }}>
              <h3 style={{ width: "100%" }}>
                Ship From
                <ContactsOutlined
                  style={{ color: "#30394e", marginLeft: "15px" }}
                />
              </h3>

              <Form.Item
                label="Company/Customer"
                rules={[
                  { required: true, message: "Please select Company!" }
                ]}
                name="companyId"
              >
                <Select style={{ width: "50%" }} name="companyId">
                  {users.map((user, index) => {
                    return (
                      <Option key={index} value={user.companyID}>
                        {user.companyName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Order Number"
                name="orderNumber"
                style={{ width: "50%" }}
                rules={[
                  { required: true, message: "Please input Order Number!" }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Customer PO#"
                name="custOrderNum"
                style={{ width: "50%" }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Shipment Date"
                name="scheduledShipDate"
                style={{ width: "50%" }}
                rules={[
                  { required: true, message: "Please input Shipment Date!" }
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                label="Origin"
                name="origin"
                style={{ width: "40%" }}
                rules={[{ required: true, message: "Please input Origin!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Business Representative"
                name="businessRepId"
              >
                <Select style={{ width: "50%" }} name="businessRepId" defaultValue={0}>
                  <Option key={0} value={0}>
                    {" --- Select Business Representative --- "}
                  </Option>
                  {allusers.filter(user => user.role === "businessRep").map(user =>
                  (
                    <Option key={user.id} value={user.id}>
                      {user.firstName + " " + user.lastName}
                    </Option>
                  )
                  )}
                </Select>
              </Form.Item>
              <Divider />
            </Col>
            <Col span={12}>
              <h3>
                Ship To
                <ContactsOutlined
                  style={{ color: "#30394e", marginLeft: "15px" }}
                />
              </h3>
              <Form.Item
                label="Warehouse"
                name="assignedWarehouseID"
                rules={[
                  { required: true, message: "Please select Ship To!" }
                ]}
              >
                <Select style={{ width: "50%" }}>
                  {warehouses.map((warehouse, index) => {
                    return (
                      <Option key={index} value={warehouse.id}>
                        {warehouse.warehouseName} - {warehouse.city}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Divider />
              <Form.Item
                label="Products"
                name="products"
                rules={[
                  { required: true, message: "Please select Products!" }
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "70%" }}
                  onSelect={addProductToPriceMap}
                  onDeselect={removeProductFromPriceMap}
                >
                  {products
                    .filter(
                      product => product.companyId === selectedCompanyId
                    )
                    .map(product => (
                      <Option key={product.id} value={product.id}>
                        {product.productName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Price Map"
                rules={[{ required: true, message: "Please input Prices!" }]}
              >
                {selectedProducts.map(productId =>
                  products
                    .filter(product => product.id === productId)
                    .map(product => (
                      <Row key={productId}>
                        <Col span={9}>
                          <h6>{product.productName + " : "}</h6>
                        </Col>
                        <Col span={15}>
                          {product.priceList.map(priceObj => (
                            <Col span={12} key={priceObj.priceId}>
                              <Input.Group compact>
                                <Form.Item
                                  label={priceObj.type}
                                  name={priceObj.priceId}
                                  initialValue={priceObj.price}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input price!"
                                    }
                                  ]}
                                >
                                  <InputNumber
                                    min={0}
                                    initialValue={priceObj.price}
                                    onChange={newValue => {
                                      updatePriceMapValue(
                                        priceObj.priceId,
                                        newValue
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Col>
                          ))}
                        </Col>
                        <Divider />
                      </Row>
                    ))
                )}
              </Form.Item>
              <Divider />
              <Form.Item
                label="Carrier Method (FOB Lacero Solutions):"
                name="carrierName"
                rules={[
                  { required: true, message: "Please select Carrier Method!" }
                ]}
              >
                <Select style={{ width: "50%" }}>
                  {carriers.map((carrier, index) => {
                    return (
                      <Option key={carrier.id} value={carrier.carrierName}>
                        {carrier.carrierName}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Carrier Phone Number:"
                name="carrierPhone"
                style={{ width: "50%" }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Tracking/Waybill/Bill of Loading Number:"
                name="trackingNumber"
                style={{ width: "50%" }}
              >
                <Input />
              </Form.Item>
              <Divider />
              <Form.Item
                wrapperCol={{
                  span: 12,
                  offset: 12
                }}
                style={{ marginTop: "35px" }}
              >
                <Button type="danger" size="large">
                  Cancel
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "15px" }}
                  size="large"
                >
                  Next
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Modal
          backdrop="static"
          show={showCreate}
          onHide={() => setshowCreate(false)}
          centered
          dialogClassName={"createnewskid-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New SKID</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              layout="horizontal"
              onFinish={createPurchaseOrder}
              style={{ margin: "0", padding: "0" }}
            >
              {skidList.map((skid, index) => (
                <Card
                  key={index}
                  style={{
                    marginBottom: "5px"
                  }}
                  bodyStyle={{ padding: "0" }}
                >
                  <Row style={{ backgroundColor: "#2b579a" }}>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Piece{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Weight (lbs)
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Length (inches)
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        Width (inches)
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Height (inches)
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "15px", marginRight: "5px" }}>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {index + 1}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {skidDimensions.grossWeight}{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {skidDimensions["length"]}{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {skidDimensions["width"]}{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {skidDimensions["height"]}{" "}
                      </div>
                    </Col>
                    <Col span={5} />
                    <Col span={2} style={{ padding: "5px" }}>
                      <Button
                        type="primary"
                        size="middle"
                        shape="circle"
                        danger
                        onClick={() => deleteSkid(index)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Col>
                    <Col span={2} style={{ padding: "5px" }}>
                      <Button
                        type="primary"
                        icon={<CopyOutlined />}
                        size="middle"
                        shape="circle"
                        onClick={() => insertSkid(skid)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ margin: "0", padding: "0" }}>
                    <Col
                      span={24}
                      style={{
                        marginLeft: "35px",
                        margintRight: "15px",
                        marginBottom: "15px"
                      }}
                    >
                      {Object.keys(skid).map(key => (
                        <Row key={key}>
                          <Col span={11}>
                            <h6>{productNameMap[key.toString()]}</h6>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label=""
                              name={`${index}${key}ProductQuantity`}
                              initialValue={skid[key]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Quantity!"
                                }
                              ]}
                              style={{ margin: "5px" }}
                            >
                              <InputNumber
                                min={0}
                                value={skid[key]}
                                onChange={e => {
                                  updateSkidQuantity(skid, key, e, index);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Card>
              ))}

              <Button
                variant="primary"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ float: "right", marginLeft: "12px" }}
              >
                Create
              </Button>
              <Button
                variant="secondary"
                style={{ float: "right" }}
                onClick={() => setshowCreate(false)}
              >
                Close
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  const ListTab = () => {

    const [showCreate, setshowCreate] = useState(false);
    const [showAddSKids, setshowAddSkids] = useState(false);
    const [selectedSkid, setSelectedSkid] = useState(false);
    const [addSkidList, setAddSkidList] = useState([]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const { Option } = Select;
    const [productNameMap, setproductNameMap] = useState({});
    const [selectedPO, setselectedPO] = useState(undefined);
    const [showDownloadLabelModal, setshowDownloadLabelModal] = useState(false);

    const addSkidsToExistingPO = async (shipDate) => {
      // console.log(shipDate);

      let expectedItems = [];
      let actualItems = [];

      addSkidList.forEach(skid => {
        var totalUnits = 0;
        Object.keys(skid).forEach(key => {
          totalUnits += parseInt(skid[key], 10);
        });
        expectedItems.push(totalUnits);
        actualItems.push(0);
      });

      // console.log(selectedSkid);
      var purchaseOrder = {};
      purchaseOrder.POId = selectedSkid.id;
      purchaseOrder.scheduledShipDate = moment(
        shipDate
      ).format("YYYY-MM-DD");
      purchaseOrder.orderNumber = selectedSkid.orderNumber;
      purchaseOrder.origin = selectedSkid.origin;
      purchaseOrder.assignedWarehouseID = selectedSkid.assignedWarehouseID;
      purchaseOrder.carrierName = selectedSkid.carrierName;
      purchaseOrder.carrierPhone = selectedSkid.carrierPhone;
      purchaseOrder.priceMap = JSON.parse(selectedSkid.priceMap);
      purchaseOrder.trackingNumber = selectedSkid.trackingNumber;
      purchaseOrder.productList = JSON.parse(selectedSkid.productList);
      purchaseOrder.origin = selectedSkid.origin;
      purchaseOrder.companyId = selectedSkid.companyId;
      users.forEach(user => {
        if (user.companyID === selectedSkid.companyId) {
          purchaseOrder.clientId = user.id;
        }
      });
      purchaseOrder.skidNumber = addSkidList.length;
      purchaseOrder.orderLines = addSkidList;
      purchaseOrder.expectedItems = JSON.stringify(expectedItems);
      purchaseOrder.acturalItems = JSON.stringify(actualItems);

      try {
        const options = HEADER_OPTIONS_AUTH(user.token);

        await axios.post(
          `${BASE_URL}/insertSkidsToExistingPO`,
          purchaseOrder,
          options
        )
          .then(function (response) {
            setLoading(false);
            localStorage.set("newRowsPO", response.data.rows);
            window.open("/SkidLabelDocumentPO");
            window.location.reload();
          })
          .catch(function (error) {
            setLoading(false);
            if (error.response) {
              // Request made and server responded
              openNotificationWithIcon(
                "error",
                error.response.data.message
              );
            } else if (error.request) {
              // The request was made but no response was received
              openNotificationWithIcon(
                "error",
                "No internet!"
              );
            } else {
              // Something happened in setting up the request that triggered an Error
              openNotificationWithIcon(
                "error",
                error.message
              );
            }
          });


      } catch (e) {
        console.log(e)
        setLoading(false);
        alert(e.message);
      }


    }

    const updateSkidQuantity = (skid, key, e, index) => {
      skid[key] = e;
      let newSkidList = addSkidList;
      newSkidList.splice(index, 1, skid);
      setAddSkidList([...newSkidList]);
    };

    const deleteSkid = index => {
      if (index !== 0) {
        let newSkidList = addSkidList;
        newSkidList.splice(index, 1);
        setAddSkidList([...newSkidList]);
      }
    };

    const insertSkid = skid => {
      setAddSkidList(addSkidList.concat([{ ...skid }]));
    };

    const updatePriceMapValue = (key, newValue) => {
      var newSkid = selectedSkid;
      let newPriceMap = JSON.parse(newSkid.priceMap);
      newPriceMap[key] = newValue;
      newSkid.priceMap = JSON.stringify(newPriceMap);
      setSelectedSkid(newSkid);
    };

    const handleDeletePO = async po => {

      let data = {};
      data.orderNumber = po;
      try {
        const options = HEADER_OPTIONS_AUTH(user.token);
        await axios.post(`${BASE_URL}/deletePurchaseOrder`, data, options)
          .then(function (response) {
            getPurchaseOrders(user, dispatch);
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              openNotificationWithIcon(
                "error",
                `Delete Purchase Order due to ${error.response.data.message}`
              )
            } else if (error.request) {
              // The request was made but no response was received
              openNotificationWithIcon(
                "error",
                "No internet!"
              );
            } else {
              // Something happened in setting up the request that triggered an Error
              openNotificationWithIcon(
                "error",
                error.message
              );
            }
          });

      } catch (e) {
        console.log(e);
      }
    };

    const handleEditShow = async po => {
      setSelectedSkid(po);
      setshowCreate(true);
    };
    const handleAddSkids = async po => {
      setSelectedSkid(po.original);
      //  console.log(po);
      let productsMap = {};
      products.forEach(product => {
        let id = product.id;
        productsMap[id] = product.productName;
      });
      setproductNameMap({ ...productsMap });

      let newSkid = {};
      JSON.parse(po.original.productList).forEach(productId => {
        newSkid[productId] = 0;
      });
      let newSkidList = [];
      newSkidList.push(newSkid);
      setAddSkidList([...newSkidList]);
      setshowAddSkids(true);
    };
    const handleFormValuesChange = changedValues => {
      const formFieldName = Object.keys(changedValues)[0];
      var skid = selectedSkid;
      if (formFieldName === "carrierName") {

        skid.carrierName = changedValues[formFieldName];
        setSelectedSkid(skid);
      }
      if (formFieldName === "trackingNumber") {

        skid.trackingNumber = changedValues[formFieldName];
        setSelectedSkid(skid);
      } else if (formFieldName === "shipDate") {

        skid.shipDate = moment(
          changedValues[formFieldName]
        ).format("YYYY-MM-DD");
        setSelectedSkid(skid);
      } else if (formFieldName === "receivedDate") {

        skid.receivedDate = moment(
          changedValues[formFieldName]).isValid() ? moment(
            changedValues[formFieldName]
          ).format("YYYY-MM-DD") : "0000-00-00";
        setSelectedSkid(skid);
      } else if (formFieldName === "custOrderNum") {

        skid.custOrderNum = changedValues[formFieldName];
        setSelectedSkid(skid);
      }
      // console.log(selectedSkid);
    };

    const openDownloadLabelModal = (row) => {
      setselectedPO(row);
      setshowDownloadLabelModal(true);
    }

    const closeDownloadLabelModal = () => {
      setselectedPO(undefined);
      setshowDownloadLabelModal(false);
    }
    const columns = [
      {
        Header: "PURCHASE ORDER#",
        accessor: "orderNumber",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "CUSTOMER PO#",
        accessor: "custOrderNum",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "COMPANY NAME",
        accessor: "companyName",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "# UNITs",
        id: "expectedItems",
        accessor: "units",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "SHIP DATE",
        id: "shipDate",
        Cell: pro =>
        (
          <Button
            type="default"
            size="sm block"
            onClick={() => openDownloadLabelModal(pro.original)}
          >
            {(pro.original.shipDate !== undefined && pro.original.shipDate !== "0000-00-00") ? pro.original.shipDate.slice(0, 10) : "NOT RECEIVED"}
          </Button>
        ),
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "RECEIVED DATE",
        id: "receivedDate",
        accessor: row =>
          row.receivedDate !== undefined && row.receivedDate !== "0000-00-00"
            ? `${row.receivedDate.slice(0, 10)}`
            : "NOT RECEIVED",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },

      {
        Header: "CARRIER NAME",
        accessor: "carrierName",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "CARRIER PHONE",
        accessor: "carrierPhone",
        filterMethod: (filter, row) => row[filter.id].startsWith(filter.value)
      },
      {
        Header: "ADD SKID",
        sortable: false,
        filterable: false,
        Cell: pro =>

          (user.role === "admin" ||
            user.role === "superAdmin" ||
            user.role === "operator" ||
            user.role === "businessRep") ? (
            <div>
              <Button
                type="primary"
                size="sm block"
                onClick={() => handleAddSkids(pro)}
              >
                Add Skids
            </Button>
            </div>
          ) : <div></div>

      },
      {
        Header: "EDIT PO",
        sortable: false,
        filterable: false,
        Cell: pro =>
          (user.role === "admin" ||
            user.role === "superAdmin" ||
            user.role === "operator" || user.role === "businessRep") ? (
            <Button
              type="primary"
              size="sm block"
              onClick={() => handleEditShow(pro.original)}
            >
              Edit PO
            </Button>
          ) : (
            <div> </div>
          )
      },
      {
        Header: "DELETE",
        sortable: false,
        filterable: false,
        Cell: pro => {
          if (
            pro.original.receiveSkidStatus === "NOT_RECEIVED" &&
            (user.role === "admin" ||
              user.role === "superAdmin" || user.role === "businessRep")
          ) {
            return (
              <Popconfirm
                title="Are you sure that you want to DELETE this purchase order?"
                onConfirm={() => handleDeletePO(pro.original.orderNumber)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="danger" size="sm block">
                  DELETE
                </Button>
              </Popconfirm>
            );
          } else {
            return <div> </div>;
          }
        }
      }
    ];

    const updatePO = async (values) => {
      setLoadingUpdate(true);
      let data = {};
      data.id = selectedSkid.id;
      data.carrierName = selectedSkid.carrierName;
      data.trackingNumber = selectedSkid.trackingNumber;
      data.productList = selectedSkid.productList;
      data.shipDate = selectedSkid.shipDate;
      data.receivedDate = selectedSkid.receivedDate;
      data.priceMap = selectedSkid.priceMap;
      data.custOrderNum = selectedSkid.custOrderNum;
      try {
        const options = HEADER_OPTIONS_AUTH(user.token);
        await axios.put(`${BASE_URL}/updatePurchaseOrder`, data, options)
          .then(function (response) {
            setLoadingUpdate(false);
            openNotificationWithIcon('success', 'Purchase Order Updated');
            setshowCreate(false);
          })
          .catch(function (error) {
            setLoadingUpdate(false);
            if (error.response) {
              // Request made and server responded
              openNotificationWithIcon('error', `Update Purchase Order due to ${error.response.data.message}`)
            } else if (error.request) {
              // The request was made but no response was received
              openNotificationWithIcon(
                "error",
                "No internet!"
              );
            } else {
              // Something happened in setting up the request that triggered an Error
              openNotificationWithIcon(
                "error",
                error.message
              );
            }
          });

      }
      catch (e) {

      }
    };


    const onNext = values => {
      downoladExistingLabels(selectedPO.id, values.scheduledShipDate)
    }

    return (
      <div style={{ marginLeft: "1%", marginRight: "1%" }}>
        {purchaseOrders.length !== undefined && (
          <ReactTable
            data={purchaseOrders ? purchaseOrders : []}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            columns={columns}
          />
        )}
        <Modal
          backdrop="static"
          show={showDownloadLabelModal}
          onHide={() => closeDownloadLabelModal()}
          centered
          dialogClassName={"donwloadLabel-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title> Download Existing Labels
                <BarcodeOutlined
                style={{ color: "#30394e", marginLeft: "15px" }}
              /></Modal.Title>
          </Modal.Header>
          <Modal.Body >

            <div style={{ padding: "5px" }}>
              <Form
                layout="vertical"
                size="medium"
                onFinish={onNext}
              >
                <Row>

                  <Col span={22} offset={1}>
                    {selectedPO ? (
                      <div>
                        <Form.Item
                          label="Order Number"
                          name="orderNumber"
                          style={{ width: "50%" }}
                          initialValue={selectedPO.orderNumber}
                        >
                          <Input disabled />
                        </Form.Item>
                        <Form.Item
                          label="Shipment Date"
                          name="scheduledShipDate"
                          style={{ width: "50%" }}
                          rules={[
                            { required: true, message: "Please input Shipment Date!" }
                          ]}
                          initialValue={(selectedPO.shipDateArray.split(','))[0]}
                        >
                          <Select
                            style={{ width: "100%" }}
                          >
                            {selectedPO.shipDateArray.split(',').map((shipDate, index) => {
                              return (
                                <Option key={index} value={shipDate}>
                                  {shipDate}
                                </Option>
                              );
                            })}
                          </Select>

                        </Form.Item>

                        <Form.Item
                          style={{ marginTop: "35px" }}
                        >

                          <Button
                            type="primary"
                            htmlType="submit"
                            size="medium"
                          >
                            Download
                </Button>
                        </Form.Item> </div>) : (<div></div>)}
                  </Col>
                </Row>
              </Form>

            </div>
          </Modal.Body>
        </Modal>

        <Modal
          backdrop="static"
          show={showAddSKids}
          onHide={() => setshowAddSkids(false)}
          centered
          dialogClassName={"addSkids-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add More Skids to PO {selectedSkid.orderNumber}</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Form
              layout="horizontal"
              onFinish={(val) => { if (window.confirm("Do you confirm to create and add new skids to existing purchase order?")) { addSkidsToExistingPO(val.shipDate) } }}
              style={{ margin: "0", padding: "0" }}
            >
              <Form.Item
                label="Shipment Date:"
                name="shipDate"
                rules={[
                  { required: true, message: "Please input Shipment Date!" }
                ]}
                style={{ marginTop: 13 }}
              >
                <DatePicker />
              </Form.Item>

              {addSkidList.map((skid, index) => (
                <Card
                  key={index}
                  style={{
                    marginBottom: "5px"
                  }}
                  bodyStyle={{ padding: "0" }}
                >
                  <Row style={{ backgroundColor: "#2b579a" }}>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Piece{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Weight (lbs)
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Length (inches)
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        Width (inches)
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        Height (inches)
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "15px", marginRight: "5px" }}>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {index + 1}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {skidDimensions.grossWeight}{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {skidDimensions["length"]}{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {skidDimensions["width"]}{" "}
                      </div>
                    </Col>
                    <Col span={3}>
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontSize: "14px"
                        }}
                      >
                        {" "}
                        {skidDimensions["height"]}{" "}
                      </div>
                    </Col>
                    <Col span={5} />
                    <Col span={2} style={{ padding: "5px" }}>
                      <Button
                        type="primary"
                        size="middle"
                        shape="circle"
                        danger
                        onClick={() => deleteSkid(index)}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Col>
                    <Col span={2} style={{ padding: "5px" }}>
                      <Button
                        type="primary"
                        icon={<CopyOutlined />}
                        size="middle"
                        shape="circle"
                        onClick={() => insertSkid(skid)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ margin: "0", padding: "0" }}>
                    <Col
                      span={24}
                      style={{
                        marginLeft: "35px",
                        margintRight: "15px",
                        marginBottom: "15px"
                      }}
                    >
                      {Object.keys(skid).map(key => (
                        <Row key={key}>
                          <Col span={11}>
                            <h6>{productNameMap[key.toString()]}</h6>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label=""
                              name={`${index}${key}ProductQuantity`}
                              initialValue={skid[key]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Quantity!"
                                }
                              ]}
                              style={{ margin: "5px" }}
                            >
                              <InputNumber
                                min={0}
                                value={skid[key]}
                                onChange={e => {
                                  updateSkidQuantity(skid, key, e, index);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Card>
              ))}

              <Button
                variant="primary"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ float: "right", marginLeft: "12px" }}
              >
                Create
              </Button>
              <Button
                variant="secondary"
                style={{ float: "right" }}
                onClick={() => setshowAddSkids(false)}
              >
                Close
              </Button>
            </Form>

          </Modal.Body>
        </Modal>



        <Modal
          backdrop="static"
          show={showCreate}
          onHide={() => setshowCreate(false)}
          centered
          dialogClassName={"editPO-modal"}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Purchase Order</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            {selectedSkid ? (
              <Form

                labelCol={{ span: 7 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                onFinish={updatePO}
                onValuesChange={handleFormValuesChange}
                requiredMark={true}
                style={{ margin: "15px", padding: "0" }}
              >
                <Form.Item label="Order Number">
                  <Input defaultValue={selectedSkid.orderNumber} disabled />
                </Form.Item>
                <Form.Item label="Customer PO#" name="custOrderNum">
                  <Input defaultValue={selectedSkid.custOrderNum} />
                </Form.Item>
                <Form.Item
                  label="Carrier Method:"
                  name="carrierName"
                  rules={[
                    {
                      required: true,
                      message: "Please select Carrier Method!"
                    }
                  ]}
                  initialValue={selectedSkid.carrierName}
                >
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={selectedSkid.carrierName}
                  >
                    {carriers.map((carrier, index) => {
                      return (
                        <Option key={carrier.id} value={carrier.carrierName}>
                          {carrier.carrierName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Tracking Number:" name="trackingNumber">
                  <Input defaultValue={selectedSkid.trackingNumber} />
                </Form.Item>

                <Form.Item
                  label="Shipment Date:"
                  name="shipDate"
                  initialValue={moment(selectedSkid.shipDate, "YYYY-MM-DD")}
                  rules={[
                    { required: true, message: "Please input Shipment Date!" }
                  ]}
                >
                  <DatePicker
                    defaultValue={moment(selectedSkid.shipDate, "YYYY-MM-DD")}
                  />
                </Form.Item>
                <Form.Item label="Received Date:" name="receivedDate">
                  <DatePicker
                    defaultValue={
                      moment(
                        selectedSkid.receivedDate,
                        "YYYY-MM-DD"
                      ).isValid()
                        ? moment(selectedSkid.receivedDate, "YYYY-MM-DD")
                        : ""
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Price Map"
                  rules={[
                    { required: true, message: "Please input Prices!" }
                  ]}
                >

                  {JSON.parse(selectedSkid.productList).map(productId =>
                    products
                      .filter(product => product.id === productId)
                      .map(product => (
                        <Row key={productId}>
                          <Col span={9}>
                            <h6>{product.productName + " : "}</h6>
                          </Col>
                          <Col span={15}>
                            {product.priceList.map(priceObj => (
                              <Col span={12} key={priceObj.priceId}>
                                <Input.Group compact>
                                  <Form.Item
                                    label={priceObj.type}
                                    name={priceObj.priceId}
                                    initialValue={
                                      JSON.parse(selectedSkid.priceMap)[
                                      priceObj.priceId
                                      ]
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input price!"
                                      }
                                    ]}
                                  >
                                    <InputNumber
                                      min={0}
                                      initialValue={
                                        JSON.parse(selectedSkid.priceMap)[
                                        priceObj.priceId
                                        ]
                                      }
                                      onChange={newValue => {
                                        updatePriceMapValue(
                                          priceObj.priceId,
                                          newValue
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Input.Group>
                              </Col>
                            ))}
                          </Col>
                          <Divider />
                        </Row>
                      ))
                  )}
                </Form.Item>

                <Button
                  variant="primary"
                  type="primary"
                  htmlType="submit"
                  style={{ float: "right", marginLeft: "12px" }}
                  loading={loadingUpdate}
                >
                  Update
                </Button>

                <Button
                  variant="secondary"
                  style={{ float: "right" }}
                  onClick={() => setshowCreate(false)}
                >
                  Close
                </Button>
              </Form>
            ) : (
              <div></div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  };

  return (
    <div>
      <h2
        style={{ color: "#30394e", paddingTop: "25px", textAlign: "center" }}
      >
        Manage Shipments - Purchase Orders
      </h2>
      <ManageShipmentTabs />
    </div>
  );

}
