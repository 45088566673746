import { CREATE_SHIPMENT, GET_SHIPMENTS, UPDATE_SHIPMENT } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {
        case GET_SHIPMENTS:
            return action.payload;
        case UPDATE_SHIPMENT:
            return state.map((shipment) => {
                if (shipment.id === action.payload.id) {
                    // Return a new object
                    return action.payload;
                }
                return shipment;
            });
        case CREATE_SHIPMENT:
            return [...state, ...action.payload]
        default:
            return state;
    }
}