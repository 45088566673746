import {
  Card,



  Col, Divider,
  message,



  Row, Select,
  Spin,


  Tag
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSkids } from '../../../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from "../../../lib/utils";

const { Option } = Select;

export const SkidsStatusControler = (params) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.authenticated);
  const skids = useSelector(state => state.skids);
  const [dateToSkidsDic, setdateToSkidsDic] = useState({});
  const [optionList, setoptionList] = useState([]);
  const [selectedDate, setselectedDate] = useState("default");
  const [items, setitems] = useState([]);
  const [selected, setselected] = useState([]);

  useEffect(() => {
    if (!user || (user.role !== "superAdmin" && user.role !== "admin")) {
      history.push('/');
    } else {
      getSkids(user, dispatch);
    }
  }, []);

  useEffect(() => {
    if (skids) {
      let dateToSkidsDic = {};
      skids.forEach(skid => {
        if (skid.close_status !== "ClosedAndInvoiced") {
          let skidReceivedDate = skid.receivedDate.slice(0, 10);
          if (!dateToSkidsDic[skidReceivedDate]) {
            dateToSkidsDic[skidReceivedDate] = [];
            dateToSkidsDic[skidReceivedDate].push(skid);
          } else {
            dateToSkidsDic[skidReceivedDate].push(skid);
          }
        }
      });
      setdateToSkidsDic({ ...dateToSkidsDic });
      //console.log(dateToSkidsDic);
    }
  }, [skids]);

  useEffect(() => {
    let optionList = [];
    optionList.push(
      <Option key="default" value={"default"}>
        Please Select a receiving date
      </Option>
    );
    for (let skidReceivedDate in dateToSkidsDic) {
      if (skidReceivedDate !== '0000-00-00') {
        optionList.push(
          <Option key={skidReceivedDate} value={skidReceivedDate}>
            {skidReceivedDate}
          </Option>
        );
      } else {
        optionList.push(
          <Option key={skidReceivedDate} value={skidReceivedDate}>
            Unknown Receiving Date
        </Option>
        );
      }
    }
    setoptionList(optionList);
  }, [dateToSkidsDic]);



  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    console.log(source);
    console.log(destination);
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const grid = 14;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightyellow" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "rgb(235, 236, 240)",
    padding: grid,
    width: 450,
    height: 800,
    overflowY: "scroll"
  });


  const handleChange = value => {
    //closed skids
    let items = [];
    //open skids
    let selected = [];
    if (value !== "default") {
      let skidList = dateToSkidsDic[value];

      for (let skid of skidList) {
        let skidCard = {
          id: `${skid.barCode}`,
          content: (
            <Card title={`Skid: ${skid.barCode}`}>
              <span>
                From {skid.origin} with {skid.units} received on {value}
              </span>
            </Card>
          )
        };
        if (skid.close_status === "Open") {
          items.push(skidCard);
        } else if (skid.close_status === "Closed") {
          selected.push(skidCard);
        }
      }
    }

    setselectedDate(value);
    setitems([...items]);
    setselected([...selected]);

  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  // const id2List = {
  //   droppable: "items",
  //   droppable2: "selected"
  // };

  //const getList = id => id2List[id];

  const onDragEnd = async result => {
    try {

      const { source, destination, draggableId } = result;

      const options = HEADER_OPTIONS_AUTH(user.token);
      // dropped outside the list
      if (!destination) {
        return;
      }

      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          source.droppableId === "items" ? items : selected,
          source.index,
          destination.index
        );

        if (source.droppableId === "droppable2") {
          setselected([...items]);
        }

      } else {
        //changing close status
        message.loading("Changing Status...", 0);
        await axios.get(
          `${BASE_URL}/skid/${draggableId}`,
          options
        )
          .then(async function (response) {
            let newSkid = response.data.data;
            newSkid.close_status = destination.droppableId === "droppable2" ? "Closed" : "Open";
            delete newSkid['totalProcessedToday'];
            await axios.put(`${BASE_URL}/skid`, newSkid, options)
              .then(async function (response) {
                console.log("droppable: " + JSON.stringify(items));
                console.log("droppable2: " + JSON.stringify(selected));
                const result = move(
                  source.droppableId === "items" ? items : selected,
                  destination.droppableId === "items" ? items : selected,
                  source,
                  destination
                );
                console.log("result= " + JSON.stringify(result));
                //setitems([...result.droppable]);
                //setselected([...result.droppable2]);

                openNotificationWithIcon(
                  "success",
                  `Set ${draggableId} to ${newSkid.close_status}`
                );

                //getSkids(user, dispatch);
                message.destroy();
              })
              .catch(function (error) {
                message.destroy();
                if (error.response) {
                  // Request made and server responded
                  openNotificationWithIcon(
                    "error",
                    error.response.data.message
                  );
                } else if (error.request) {
                  // The request was made but no response was received
                  openNotificationWithIcon(
                    "error",
                    "No internet!"
                  );
                } else {
                  // Something happened in setting up the request that triggered an Error
                  openNotificationWithIcon(
                    "error",
                    error.message
                  );
                }

              });

          })
          .catch(function (error) {
            message.destroy();
            if (error.response) {
              // Request made and server responded
              openNotificationWithIcon(
                "error",
                error.response.data.message
              );

            } else if (error.request) {
              // The request was made but no response was received
              openNotificationWithIcon(
                "error",
                "No internet!"
              );
            } else {
              // Something happened in setting up the request that triggered an Error
              openNotificationWithIcon(
                "error",
                error.message
              );
            }
            message.destroy();
          });
      }
    } catch (error) {
      message.destroy();
      openNotificationWithIcon("error", `Change stutas`);
    }


  };
  return (
    <div>
      <h1 align="center">Skids Status Controler</h1>
      <Card
        title={
          <h5>
            <Spin />
            Receiving Date:
          </h5>
        }
        bordered={false}
      >
        <div>
          <Select
            defaultValue={selectedDate}
            value={selectedDate}
            size={"large"}
            showArrow
            style={{ width: 500 }}
            onChange={handleChange}
          >
            {optionList}
          </Select>
        </div>
      </Card>
      <Divider />

      <DragDropContext onDragEnd={onDragEnd}>
        <div>
          <Row>
            <Col span={12}>
              <h4>
                <Tag color="purple">Open Skids</Tag>
              </h4>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >

                      {items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )
                }
                }
              </Droppable>
            </Col>
            <Col span={12}>
              <h4>
                <Tag color="#f50">Closed Skids</Tag>
              </h4>
              <Droppable droppableId="droppable2">
                {(provided, snapshot) => {

                  return (
                    <div
                      ref={provided.innerRef}
                      style={
                        {
                          background: snapshot.isDraggingOver ? "lightblue" : "rgb(235, 236, 240)",
                          padding: grid,
                          width: 450,
                          height: 800,
                          overflowY: "scroll"
                        }}
                    >

                      {selected.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )
                }

                }
              </Droppable>
            </Col>
          </Row>
        </div>

      </DragDropContext>
    </div>
  );

}
