export const CHANGE_AUTH = 'change_auth';
export const FETCH_ERROR_MESSAGE = 'fetch_error_message';
export const GET_USERS = 'get_users';
export const GET_BUSINESS_REP_LIST_PO = 'get_business_rep_list_po';
export const GET_ALL_USERS = 'get_all_users';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SELF = 'update_user_self';

export const GET_SKIDS = 'get_skids';

export const UPDATE_SKID = 'update_skid';
export const CREATE_SKID = 'create_skid';
export const GET_RECEIVED_SKIDS_BARCODES = 'get_received_skids_barcodes';
export const GET_PROCESSING_INVOICE_SKIDS = 'get_processing_invoice_skids';

export const GET_ITEMS = 'get_items';

export const GET_SKID_DIMENSIONS = 'get_skid_dimensions';
export const GET_SKIDS_FOR_PRODUCT_TRANSFER = 'get_skids_for_product_transfer';
export const GET_SKIDS_FOR_RECEIVING_INVOICE = 'get_skids_for_receiving_invoice';
export const GET_WAREHOUSES = 'get_warehouse';
export const GET_SHIPMENTS = 'get_shipment';
export const UPDATE_SHIPMENT = 'update_shipment';
export const CREATE_SHIPMENT = 'create_shipment';

export const GET_PURCHASE_ORDERS = 'get_purchase_orders';
export const GET_CARRIERS = 'get_carriers';
export const CREATE_PURCHASE_ORDERS = 'create_purchase_orders';
export const UPDATE_PURCHASE_ORDERS = 'update_purchase_orders';

export const GET_WEEKLY_KPI = 'get_weekly_kpi';
export const UPDATE_WEEKLY_KPI = 'update_weekly_kpi';

export const GET_MONTHLY_KPI = 'get_monthly_kpi';
export const UPDATE_MONTHLY_KPI = 'update_monthly_kpi';

export const GET_DAILY_KPI = 'get_daily_kpi';
export const UPDATE_DAILY_KPI = 'update_daily_kpi';
export const CREATE_BARTH_DAILY_KPI = 'create_batch_daily_kpi';
export const DELETE_DAILY_KPI = 'delete_daily_kpi';

export const GET_COMPANY = 'get_company';

export const GET_PRODUCT_TYPES = 'get_product_types';
export const GET_CLIENTS = 'get_clients';

export const GET_PRODUCT_TYPE_LIST = 'get_product_type_list';

export const GET_PRODUCT_TABLE_DATA = 'product_table_data';

export const GET_PRODUCTS = 'get_products';
export const GET_BUFFER_LIST = 'get_buffer_list';

export const GET_SKID_STATUS_DATA = 'get_skid_status_data';
export const GET_PRODUCT_STATUS_DATA = 'get_product_status_data';

export const GET_WEEKLY_RECORDS = 'get_weekly_records';
export const GET_MONTHLY_RECORDS = 'get_monthly_records';
export const GET_INFLATORS_INVENTORY = 'get_inflators_inventory';

export const GET_DAILY_SKID_KPI = 'get_daily_skid_kpi';
export const UPDATE_DAILY_SKID_KPI = 'update_daily_skid_kpi';
export const CREATE_DAILY_BARTH_SKID_KPI = 'create_batch_daily_skid_kpi';
export const DELETE_DAILY_SKID_KPI = 'delete_daily_skid_kpi';
export const GET_WEEKLY_SKID_KPI = 'get_weekly_skid_kpi';
export const GET_WEEKLY_SKID_RECORDS = 'get_weekly_skid_records';
export const UPDATE_WEEKLY_SKID_KPI = 'update_weekly_skid_kpi';

//========================================quick book=======================================
export const CREATE_INVOICE_BY_SELECTED_DATE = 'create_invoice_by_selected_date';
export const GET_QUICKBOOK_TOKEN = 'get_quick_book_Token';


//========================================quick book=======================================
export const GET_ALL_PRICES = 'get_all_prices';
export const UPDATE_PRICE = 'update_price';


