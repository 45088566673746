import { BookOutlined, CodeSandboxOutlined, HistoryOutlined, SettingOutlined } from '@ant-design/icons';
import {
    Breadcrumb, Layout,
    Menu
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { ProcessingInvoiceUI } from "./CreateInvoiceContainer/CreateInvoiceBySkid";
import { ReceivingInvoiceUI } from "./CreateInvoiceContainer/CreateInvoiceByStatus";
import { ProductDefinition } from "./ProductManagerContainer/ProductDefinition";
import { ManageProductType } from "./ProductManagerContainer/ManageProductType";
import { ProductTransfer } from "./ProductManagerContainer/ProductTransfer";
import { SkidsStatusControler } from "./ProductManagerContainer/SkidsStatusControler";
import BufferSetting from "./SettingContainer/BufferSetting";
import { PriceSetting } from "./SettingContainer/PriceSetting";
import { InvoiceHistoryUI } from "./TimeLineContainer/InvoiceHistory";
import { ReportUI } from "./TimeLineContainer/Report";
import { TransactionTimelineUI } from "./TimeLineContainer/TransactionTimeline";
const { SubMenu } = Menu;
const { Content, Sider } = Layout;

export const SystemManagerUI = (params) => {

    const user = useSelector(state => state.authenticated);
    const [mainCategoryName, setmainCategoryName] = useState("");
    const [subCategoryName, setsubCategoryName] = useState("");
    const [key, setkey] = useState("1");
    const [contentMap, setcontentMap] = useState({});
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        //console.log("System Manager Page loads!!!!!");
        if (user && user.role === "superAdmin") {

            setmainCategoryName("Invoice")
            setsubCategoryName("Receiving Invoicing")
            setkey("1");

            setcontentMap({
                "1": <ReceivingInvoiceUI />,
                "2": <ProcessingInvoiceUI />,
                "3": <InvoiceHistoryUI />,
                "4": <TransactionTimelineUI />,
                "5": <ReportUI />,
                "6": <PriceSetting />,
                "7": <ProductDefinition />,
                "8": <ManageProductType />,
                "9": <SkidsStatusControler />,
                "10": <ProductTransfer />
            });


        } else if (user && user.role === "admin") {

            setmainCategoryName("Time Line")
            setsubCategoryName("Transaction TimeLine")
            setkey("3");
            setcontentMap({
                "1": <ReceivingInvoiceUI />,
                "2": <ProcessingInvoiceUI />,
                "3": <TransactionTimelineUI />,
                "4": <ReportUI />,
                "5": <SkidsStatusControler />,
                "6": <ProductTransfer />
            });
        }
        else if (user && user.role === "businessRep") {

            setmainCategoryName("Time Line")
            setsubCategoryName("Transaction TimeLine")
            setkey("1");
            setcontentMap({
                "1": <TransactionTimelineUI />,
                "2": <ReportUI />,
                "3": <ProductDefinition />
            });
        }
        else {
            history.push('/');
        }
    }, [])

    return (
        user && user.role === "superAdmin" ? (

            <div>
                <Layout style={{ minHeight: "950px" }}>
                    <Sider width={250} style={{ background: "#fff" }}>


                        <Menu
                            mode="inline"
                            defaultSelectedKeys={["1"]}
                            defaultOpenKeys={["sub1"]}
                            style={{ height: "100%", borderRight: 0 }}
                        >

                            <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <BookOutlined />
                                        Invoice
                                    </span>
                                }
                            >
                                <Menu.Item
                                    key="1"
                                    onClick={() => {
                                        setkey("1")
                                        setmainCategoryName("Invoice")
                                        setsubCategoryName("Receiving Invoicing")
                                    }
                                    }
                                >
                                    Receiving Invoicing
                                </Menu.Item>
                                <Menu.Item
                                    key="2"
                                    onClick={() => {
                                        setkey("2")
                                        setmainCategoryName("Invoice")
                                        setsubCategoryName("Skid Status Invoicing")
                                    }
                                    }
                                >
                                    Skid Status Invoicing
                                </Menu.Item>
                            </SubMenu>


                            <Menu.Divider />

                            <SubMenu
                                key="sub2"
                                title={
                                    <span>
                                        <HistoryOutlined />
                                        Time Line
                                    </span>
                                }
                            >

                                <Menu.Item
                                    key="3"
                                    onClick={() => {
                                        setkey("3")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Invoice History")
                                    }
                                    }
                                >
                                    Invoice History
                                </Menu.Item>

                                <Menu.Item
                                    key="4"
                                    onClick={() => {

                                        setkey("4")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Transaction TimeLine")
                                    }
                                    }
                                >
                                    Transaction TimeLine
                                </Menu.Item>
                                <Menu.Item
                                    key="5"
                                    onClick={() => {
                                        setkey("5")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Report")
                                    }
                                    }
                                >
                                    Report
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Divider />
                            <SubMenu
                                key="sub3"
                                title={
                                    <span>
                                        <SettingOutlined />
                                        Setting
                                    </span>
                                }
                            >
                                <Menu.Item
                                    key="6"
                                    onClick={() => {
                                        setkey("6")
                                        setmainCategoryName("Setting")
                                        setsubCategoryName("Price Setting")
                                    }
                                    }
                                >
                                    Price Setting
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Divider />
                            <SubMenu
                                key="sub4"
                                title={
                                    <span>
                                        <CodeSandboxOutlined />
                                            Product Manager
                                        </span>
                                }
                            >
                                <Menu.Item
                                    key="7"
                                    onClick={() => {
                                        setkey("7")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("Manage Product")
                                    }
                                    }
                                >
                                    Manage Product
                                </Menu.Item>
                                <Menu.Item
                                    key="8"
                                    onClick={() => {
                                        setkey("8")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("Manage Product Type")
                                    }
                                    }
                                >
                                    Manage Product Type
                                </Menu.Item>
                                <Menu.Item
                                    key="9"
                                    onClick={() => {
                                        setkey("9")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("Skids Status Controler")
                                    }
                                    }
                                >
                                    Skids Status Controller
                                </Menu.Item>
                                <Menu.Item
                                    key="10"
                                    onClick={() => {
                                        setkey("10")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("InflatorTransfer")
                                    }
                                    }
                                >
                                    Product Transfer
                                </Menu.Item>
                            </SubMenu>

                        </Menu>
                    </Sider>
                    <Layout style={{ padding: "0 24px 24px" }}>
                        <Breadcrumb style={{ margin: "20px 0" }}>
                            <Breadcrumb.Item>{mainCategoryName}</Breadcrumb.Item>
                            <Breadcrumb.Item>{subCategoryName}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content
                            style={{
                                background: "#fff",
                                padding: 24,
                                margin: 0,
                                minHeight: 280
                            }}
                        >
                            {contentMap[key]}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        ) : user && user.role === "admin" ? (
            <div>
                <Layout style={{ minHeight: "950px" }}>
                    <Sider width={250} style={{ background: "#fff" }}>


                        <Menu
                            mode="inline"
                            defaultSelectedKeys={["3"]}
                            defaultOpenKeys={["sub2"]}
                            style={{ height: "100%", borderRight: 0 }}
                        >

                            <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <BookOutlined />
                                        Invoice
                                    </span>
                                }
                            >
                                <Menu.Item
                                    key="1"
                                    onClick={() => {
                                        setkey("1")
                                        setmainCategoryName("Invoice")
                                        setsubCategoryName("Receiving Invoicing")
                                    }
                                    }
                                >
                                    Receiving Invoicing
                                </Menu.Item>
                                <Menu.Item
                                    key="2"
                                    onClick={() => {
                                        setkey("2")
                                        setmainCategoryName("Invoice")
                                        setsubCategoryName("Skid Status Invoicing")
                                    }
                                    }
                                >
                                    Skid Status Invoicing
                                </Menu.Item>
                            </SubMenu>


                            <Menu.Divider />
                            <SubMenu
                                key="sub2"
                                title={
                                    <span>
                                        <HistoryOutlined />
                    Time Line
                  </span>
                                }
                            >

                                <Menu.Item
                                    key="3"
                                    onClick={() => {
                                        setkey("3")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Transaction TimeLine")
                                    }
                                    }
                                >
                                    Transaction TimeLine
                </Menu.Item>
                                <Menu.Item
                                    key="4"
                                    onClick={() => {
                                        setkey("4")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Report")
                                    }
                                    }
                                >
                                    Report
                </Menu.Item>
                            </SubMenu>
                            <Menu.Divider />
                            <SubMenu
                                key="sub3"
                                title={
                                    <span>
                                        <CodeSandboxOutlined />
                                            Product Manager
                                        </span>
                                }
                            >

                                <Menu.Item
                                    key="5"
                                    onClick={() => {
                                        setkey("5")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("Skids Status Controler")
                                    }
                                    }
                                >
                                    Skids Status Controller
                                </Menu.Item>
                                <Menu.Item
                                    key="6"
                                    onClick={() => {
                                        setkey("6")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("InflatorTransfer")
                                    }
                                    }
                                >
                                    Product Transfer
                                </Menu.Item>
                            </SubMenu>

                        </Menu>
                    </Sider>
                    <Layout style={{ padding: "0 24px 24px" }}>
                        <Breadcrumb style={{ margin: "20px 0" }}>
                            <Breadcrumb.Item>{mainCategoryName}</Breadcrumb.Item>
                            <Breadcrumb.Item>{subCategoryName}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content
                            style={{
                                background: "#fff",
                                padding: 24,
                                margin: 0,
                                minHeight: 280
                            }}
                        >
                            {contentMap[key]}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        ) : user && user.role === "businessRep" ? (
            <div>
                <Layout style={{ minHeight: "950px" }}>
                    <Sider width={250} style={{ background: "#fff" }}>


                        <Menu
                            mode="inline"
                            defaultSelectedKeys={["1"]}
                            defaultOpenKeys={["sub1"]}
                            style={{ height: "100%", borderRight: 0 }}
                        >

                            <SubMenu
                                key="sub1"
                                title={
                                    <span>
                                        <HistoryOutlined />
                    Time Line
                  </span>
                                }
                            >

                                <Menu.Item
                                    key="1"
                                    onClick={() => {
                                        setkey("1")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Transaction TimeLine")
                                    }
                                    }
                                >
                                    Transaction TimeLine
                </Menu.Item>
                                <Menu.Item
                                    key="2"
                                    onClick={() => {
                                        setkey("2")
                                        setmainCategoryName("Time Line")
                                        setsubCategoryName("Report")
                                    }
                                    }
                                >
                                    Report
                </Menu.Item>
                            </SubMenu>
                            <SubMenu
                                key="sub4"
                                title={
                                    <span>
                                        <CodeSandboxOutlined />
                                            Product Manager
                                        </span>
                                }
                            >
                                <Menu.Item
                                    key="3"
                                    onClick={() => {
                                        setkey("3")
                                        setmainCategoryName("Product Manager")
                                        setsubCategoryName("Manage Product")
                                    }
                                    }
                                >
                                    Manage Product
                                </Menu.Item>
                            </SubMenu>
                        </Menu>


                    </Sider>
                    <Layout style={{ padding: "0 24px 24px" }}>
                        <Breadcrumb style={{ margin: "20px 0" }}>
                            <Breadcrumb.Item>{mainCategoryName}</Breadcrumb.Item>
                            <Breadcrumb.Item>{subCategoryName}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content
                            style={{
                                background: "#fff",
                                padding: 24,
                                margin: 0,
                                minHeight: 280
                            }}
                        >
                            {contentMap[key]}
                        </Content>
                    </Layout>
                </Layout>
            </div>
        ) : (
            <div>
            </div>)

    )
}
