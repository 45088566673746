import {
    Button, Form,
    Input,


    Tabs
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ReactTable from 'react-table';
import { getClients } from '../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from '../lib/utils';
import { CreateClient } from "./CreateClient";

const { TabPane } = Tabs;


export const ManageClientUI = (params) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(state => state.authenticated);
    const clients = useSelector(state => state.clients);
    const [activeTab, setactiveTab] = useState("1");


    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "businessRep" && user.role !== "admin")) {
            history.push('/');
        } else {
            getClients(user, dispatch);
        }
    }, [])

    const changeTab = activeKey => {
        setactiveTab(activeKey);
    };

    const ManageClientTabs = () => {
        return (
            <Tabs
                type="card"
                centered
                activeKey={activeTab}
                onChange={changeTab}
            >
                <TabPane tab="List" key="1">
                    <ManageClientScreen />
                </TabPane>
                <TabPane tab="Create New" key="2">
                    <CreateClient />
                </TabPane>
            </Tabs>
        );
    };

    const ManageClientScreen = () => {
        const [selectedClient, setSelectedClient] = useState({});
        const [showModal, setShowModal] = useState(false);
        const [loading, setLoading] = useState(false);

        const handleClose = () => {
            setLoading(false);
            setShowModal(false);
        };

        const handleShow = (val) => {
            setSelectedClient(val);
            setShowModal(true);
        };

        const handleUpdateClient = async (data) => {
            setLoading(true);
            data["userId"] = selectedClient["userId"];
            data["companyID"] = selectedClient["companyID"];

            try {
                const options = HEADER_OPTIONS_AUTH(user.token);
                await axios.post(`${BASE_URL}/updateClient`, data, options)
                    .then(function (response) {
                        setLoading(false);
                        openNotificationWithIcon('success', 'Client Updated');
                        setShowModal(false);
                        getClients(user, dispatch);
                    })
                    .catch(function (error) {
                        if (error.response) {
                            // Request made and server responded
                            setLoading(false);
                            openNotificationWithIcon('error', `Update Client due to ${JSON.stringify(error.response.data.message)}`);

                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });

            }
            catch (e) {
                console.log(e);
            }

        }

        const handleActivate = async (client) => {
            if (window.confirm('Do you confirm to Activate?')) {

                let data = {};
                data.userId = client.userId;
                // await this.props.activateClient(user, data);
                try {
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.post(`${BASE_URL}/activateUser`, data, options)
                        .then(function (response) {
                            refreshPage();
                        })
                        .catch(function (error) {
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Activate Client due to ${error.response.data.message}`);
                                //  this.setState({ response: error.response.data.message })

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    console.log(e);
                }

            }
        };

        const refreshPage = () => {
            getClients(user, dispatch);
        }
        const handleDeactivate = async (val) => {
            if (window.confirm('Do you confirm to Deactivate?')) {

                let data = {};
                data.userId = val;

                try {
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.post(`${BASE_URL}/deactivateUser`, data, options)
                        .then(function (response) {
                            refreshPage();
                        })
                        .catch(function (error) {
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Deactivate Client due to ${error.response.data.message}`);
                                //this.setState({ response: error.response.data.message })

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {

                }

            }
        };

        const columns = [
            {
                id: 'companyName',
                Header: 'COMPANY NAME',
                accessor: `companyName`,

                filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value)
            },
            {
                id: 'summarizedInvoice',
                Header: 'INVOICE TYPE',
                accessor: `summarizedInvoice`,
                accessor: row =>
                    row.summarizedInvoice === 0
                        ? `Detailed`
                        : "Summarized",
                filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value)
            },
            {
                Header: 'SHORT CUT',
                accessor: 'shortCut',
                filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value)
            },
            {
                Header: 'EMAIL',
                accessor: 'email',
                filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value)
            }, {
                // Header: props => <span>Friend Age</span>, // Custom header components!
                Header: 'PHONE',
                accessor: 'phone1',
                filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value)
            }, {
                // Header: props => <span>Friend Age</span>, // Custom header components!
                Header: 'PRIMARY CONTACT NAME',
                accessor: 'primarycontactname',
                filterMethod: (filter, row) =>
                    row[filter.id].startsWith(filter.value)
            }, {
                Header: '',
                sortable: false,
                filterable: false,
                Cell: (pro) =>
                    pro.original.activate === 1 ?
                        <Button type="danger" size="sm block" onClick={() => handleDeactivate(pro.original.userId)}>
                            Deactivate
        </Button> :
                        <Button type="primary" size="sm block" onClick={() => handleActivate(pro.original)}>
                            Activate
        </Button>
            }, {
                Header: 'EDIT',
                sortable: false,
                filterable: false,
                Cell: (pro) =>
                    <Button type="primary" size="sm block" hidden={user.role === "businessRep" || user.role === "admin"} onClick={() => {
                        handleShow(pro.original);
                    }}>
                        Edit
        </Button>
            }];

        return (
            <div style={{ margin: "10px", textAlign: 'center' }}>

                {
                    selectedClient !== undefined &&
                    <Modal backdrop="static" show={showModal} onHide={handleClose} centered dialogClassName={'user-modal'}>
                        <Modal.Header closeButton>
                            <Modal.Title>Editing Client</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form
                                layout="horizontal"
                                size="medium"
                                className="update-client-form"
                                onFinish={handleUpdateClient}
                                initialValues={selectedClient}
                                labelCol={{ span: 12 }}
                                wrapperCol={{ span: 12 }}
                                style={{
                                    marginLeft: "10%", marginRight: "10%"
                                }}
                            >
                                <Form.Item
                                    label="Display Name:"
                                    name="DisplayName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Display Name!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Company Name:"
                                    name="companyName"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Company Name!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Short Cut:"
                                    name="shortCut"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Short Cut!"
                                        }, {

                                            pattern: new RegExp(
                                                /^[A-Z]+$/i
                                            ),
                                            message: "Please input only Capital Letters!"
                                        }
                                    ]}
                                >
                                    <Input maxLength={4} />
                                </Form.Item>

                                <Form.Item
                                    label="Email Address:"
                                    name="email"
                                    rules={[
                                        {
                                            type: "email",
                                            required: true,
                                            message: "The input is not valid E-mail!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>



                                <Form.Item
                                    label="Family Name:"
                                    name="lastName"

                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Primary Phone:"
                                    name="phone"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Primary Phone!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="City:"
                                    name="city"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input City!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Postal Code:"
                                    name="postalcode"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Postal Code!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Address Line 1:"
                                    name="line1"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Address!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Country:"
                                    name="country"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Country!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="GivenName:"
                                    name="name"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input GivenName!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Primary Contact Name:"
                                    name="primarycontactname"

                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="State or Province:"
                                    name="stateorprovince"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Province!"
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Button

                                    type="primary"
                                    htmlType="submit"
                                    style={{ float: "right", marginLeft: "12px" }}
                                    loading={loading}
                                >
                                    Save Changes
        </Button>
                                <Button
                                    type="secondary"
                                    style={{ float: "right" }}
                                    onClick={() => handleClose(false)}
                                >
                                    Close
        </Button>

                            </Form>
                        </Modal.Body>
                    </Modal>
                }
                {
                    clients.length !== undefined &&
                    <ReactTable
                        data={clients}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={columns}
                    />
                }
            </div>
        )
    }

    return (
        <div style={{ margin: "10px", textAlign: 'center' }}>
            <h1>Manage Client</h1>

            <ManageClientTabs />
        </div>
    )

}