import { GET_BUFFER_LIST } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {

        case GET_BUFFER_LIST:
            return action.payload;

        default:
            return state;
    }
}