import { GET_ALL_PRICES, UPDATE_PRICE } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {
        case GET_ALL_PRICES:
            return action.payload;
        case UPDATE_PRICE:
            return state.map((price) => {
                if (price.id === action.payload.id) {
                    // Return a new object
                    return action.payload;
                }
                return price;
            });
        default:
            return state;
    }
}