import { GET_PRODUCT_TYPES } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {

        case GET_PRODUCT_TYPES:
            return action.payload;

        default:
            return state;
    }
}