import { Button, Col, Form, Input, InputNumber, message, Row, Select, Tabs, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { getProductsTableData, getProductTypes, getUsers } from '../../../../actions/index';
import { BASE_URL, HEADER_FORMDATA_OPTIONS_AUTH, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';
const { TabPane } = Tabs;
const { Option } = Select;

export const ProductDefinition = (params) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.authenticated);
    const productsTableData = useSelector(state => state.productsTableData);
    const productTypes = useSelector(state => state.productTypes);
    const allusers = useSelector(state => state.users);
    const [users, setusers] = useState([]);
    const [formattedMainTableData, setformattedMainTableData] = useState([]);
    const [formattedSubTableData, setformattedSubTableData] = useState({});

    const [activeTab, setactiveTab] = useState("1");

    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "businessRep")) {
            history.push('/');
        } else {
            getUsers(user, dispatch);
            getProductTypes(user, dispatch);
            getProductsTableData(user, dispatch);
        }
    }, []);

    useEffect(() => {
        if (productsTableData) {
            var formattedMain = [];
            var formattedSub = {};
            productsTableData.forEach((product) => {
                if (!formattedSub.hasOwnProperty(product.companyId)) {
                    formattedSub[product.companyId] = [];
                    formattedMain.push(product);
                }
                formattedSub[product.companyId].push(product);
            })
            setformattedMainTableData([...formattedMain]);
            setformattedSubTableData({ ...formattedSub });
        }
    }, [productsTableData]);

    useEffect(() => {
        if (allusers) {
            setusers(allusers.filter(
                user => user.companyName !== "LACERO" && user.role === "client"
            ));
        }
    }, [allusers]);

    const changeTab = activeKey => {
        setactiveTab(activeKey);
    };

    const ProductDefinitionTabs = () => {
        return (
            <Tabs
                type="card"
                centered
                activeKey={activeTab}
                onChange={changeTab}
            >
                <TabPane tab="List" key="1">
                    <ManageProductScreen />
                </TabPane>
                <TabPane tab="Create New" key="2">
                    <CreateProductScreen />
                </TabPane>
            </Tabs>
        );
    };

    const ManageProductScreen = () => {

        const [loadingActivate, setloadingActivate] = useState(false);
        const [loadingDeactivate, setloadingDeactivate] = useState(false);
        const [selectedProduct, setselectedProduct] = useState({});
        const [showModal, setShowModal] = useState(false);
        const [loading, setLoading] = useState(false);
        const [editForm] = Form.useForm();

        const refreshPage = () => {
            getProductsTableData(user, dispatch);
        }

        const handleClose = () => {
            setLoading(false);
            setShowModal(false);
        };

        const mainTableCol = [
            {
                title: 'Company Name', dataIndex: 'companyName', key: 'companyId'
            },
            {
                title: 'Total Products', dataIndex: 'companyId',
                render: (companyId) => { return (formattedSubTableData[companyId].length) },

            }
        ];

        const handleEditShow = (val) => {
            console.log(val);
            setselectedProduct(val);
            setShowModal(true);
        }

        const handleActivate = async (pro) => {
            if (window.confirm('Do you confirm to Activate?')) {
                setloadingActivate(true);
                try {
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.put(`${BASE_URL}/activateProduct`, pro, options)
                        .then(function (response) {
                            setloadingActivate(false);
                            refreshPage();
                        })
                        .catch(function (error) {
                            setloadingActivate(false);
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Activate Product due to ${error.response.data.message}`);
                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    setloadingActivate(false);
                    console.log(e);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }

            }
        }

        const handleDeactivate = async (pro) => {
            console.log(pro);
            if (window.confirm('Do you confirm to Deactivate?')) {
                setloadingDeactivate(true);
                try {
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.put(`${BASE_URL}/deactivateProduct`, pro, options)
                        .then(function (response) {
                            setloadingDeactivate(false);
                            refreshPage();
                        })
                        .catch(function (error) {
                            setloadingDeactivate(false);
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Deactivate Product due to ${error.response.data.message}`);
                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    setloadingDeactivate(false);
                    console.log(e);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }

            }
        }

        const expandedRowRender = (e) => {
            const columns = [
                { title: 'Company Name', dataIndex: 'companyName', key: 'companyName' },
                { title: 'Product Name', dataIndex: 'productName', key: 'productName' },
                {
                    title: '',
                    sortable: false,
                    filterable: false,
                    render: (text, pro) => (
                        <div>
                            {pro.active ?
                                <Button style={{ marginLeft: '5px' }} type="danger" size="sm block" loading={loadingDeactivate} onClick={() => handleDeactivate(pro)}>
                                    Deactivate
                            </Button>
                                : <Button style={{ marginLeft: '5px' }} type="primary" size="sm block" loading={loadingActivate} onClick={() => handleActivate(pro)}>
                                    Activate
                            </Button>}
                        </div>)
                }, {
                    title: 'Edit',
                    sortable: false,
                    filterable: false,
                    render: (text, pro) => (
                        <div>
                            <Button type="primary" size="sm block" onClick={() => handleEditShow(pro)}>
                                Edit
                            </Button>
                        </div>)
                },
            ];

            return (<div style={{ paddingLeft: "4%", paddingRight: "4%", paddingBottom: "4%" }}><Table columns={columns} dataSource={formattedSubTableData[e.companyId]} pagination={false} rowKey={record => record.id} /></div>);
        };

        const onNext = async (val) => {
            console.log(val);
            val["id"] = selectedProduct.id;
            if (window.confirm('Do you confirm to Update Product?')) {
                setLoading(true);
                try {
                    await axios.put(`${BASE_URL}/updateProduct`, val, HEADER_OPTIONS_AUTH(user.token))
                        .then(function (response) {
                            setLoading(false);
                            openNotificationWithIcon('success', 'Update Product');
                            getProductsTableData(user, dispatch);
                            editForm.resetFields();
                        })
                        .catch(function (error) {
                            setLoading(false);
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Update Product due to ${error.response.data.message}`);

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });
                } catch (e) {
                    console.log(e);
                    setLoading(false);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }
            }
        }

        return (
            <div>
                <Table
                    className="products-nested-table"
                    columns={mainTableCol}
                    expandedRowRender={expandedRowRender}
                    defaultExpandAllRows={true}
                    pagination={false}
                    dataSource={formattedMainTableData}
                    rowKey={record => record.companyId}
                />
                <Modal backdrop="static" show={showModal} onHide={handleClose} centered dialogClassName={'user-modal'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Product</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >

                        <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 14 }}
                            layout="horizontal"
                            form={editForm}
                            requiredMark={true}
                            onFinish={onNext}
                            size="medium"
                            style={{ marginTop: "15px" }}
                        >

                            <Form.Item
                                label="Item Name:"
                                name="productName"
                                initialValue={selectedProduct.productName}
                                preserve={false}
                                rules={[
                                    { required: true, message: "Please input Order Number!" }
                                ]}>
                                <Input maxLength={32} />
                            </Form.Item>
                            <Form.Item
                                label="Product Type"
                                name="productType"
                                initialValue={selectedProduct.productTypeId}
                                preserve={false}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Product Type!"
                                    }
                                ]}>
                                <Select initialvalue={selectedProduct.productType}>
                                    {productTypes ? productTypes.map((productType, index) => {
                                        return (
                                            <Option key={index} value={productType.id}>
                                                {productType.name}
                                            </Option>
                                        );
                                    }) : null}
                                </Select>

                            </Form.Item>
                            <Form.Item
                                label="Brand:"
                                name="companyId"
                                initialValue={selectedProduct.companyId}
                                preserve={false}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Brand!"
                                    }
                                ]}>
                                <Select>
                                    {users.map((user, index) => {
                                        return (
                                            <Option key={index} value={user.companyID}>
                                                {user.companyName}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Short Description:"
                                name="productDetail"
                                initialValue={selectedProduct.productDetail}
                                preserve={false}
                            >
                                <Input maxLength={32} />

                            </Form.Item>

                            <Form.Item
                                label="Model / SKU :"
                                name="sku"
                                initialValue={selectedProduct.sku}
                                preserve={false}
                            >
                                <Input maxLength={16} />
                            </Form.Item>
                            <Form.Item
                                label="UPC:"
                                name="upc"
                                initialValue={selectedProduct.upc}
                                preserve={false}
                            >
                                <Input maxLength={13} />
                            </Form.Item>
                            <Form.Item
                                label="Weight:"
                                name="weight"
                                initialValue={selectedProduct.weight}
                                preserve={false}>
                                <InputNumber min={0} addonAfter="g" maxLength={9} />
                            </Form.Item>
                            <Form.Item
                                label="Height:"
                                name="height"
                                initialValue={selectedProduct.height}
                                preserve={false}>
                                <InputNumber min={0} addonAfter="cm" maxLength={9} />
                            </Form.Item>
                            <Form.Item label="Width:"
                                name="width"
                                initialValue={selectedProduct.width}
                                preserve={false}>
                                <InputNumber min={0} addonAfter="cm" maxLength={9} />
                            </Form.Item>
                            <Form.Item label="Length:"
                                name="length"
                                initialValue={selectedProduct.length}
                                preserve={false}>
                                <InputNumber min={0} addonAfter="cm" maxLength={9} />
                            </Form.Item>

                            < Form.Item
                                wrapperCol={{
                                    span: 5,
                                    offset: 9
                                }}
                                style={{ marginTop: "35px" }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={loading}
                                >
                                    Update Product
             </Button>
                            </Form.Item>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }

    const CreateProductScreen = () => {
        const [loading, setLoading] = useState(false);
        const [form] = Form.useForm();

        const onNext = async (values) => {
            if (window.confirm('Do you confirm to Add New Product?')) {
                setLoading(true);
                try {
                    await axios.post(`${BASE_URL}/addNewProduct`, values, HEADER_OPTIONS_AUTH(user.token))
                        .then(function (response) {
                            setLoading(false);
                            openNotificationWithIcon('success', 'Create Product');
                            getProductsTableData(user, dispatch);
                            form.resetFields();
                        })
                        .catch(function (error) {
                            setLoading(false);
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Create Product due to ${error.response.data.message}`);

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });
                } catch (e) {
                    console.log(e);
                    setLoading(false);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }
            }
        }

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ padding: '5px', marginTop: "10pxdsd", textAlign: 'left' }}>
                    <Form
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 20 }}
                        layout="horizontal"
                        form={form}
                        requiredMark={true}
                        onFinish={onNext}
                        size="medium"
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Item Name:"
                                    name="productName"
                                    rules={[
                                        { required: true, message: "Please input Order Number!" }
                                    ]}>
                                    <Input maxLength={32} />
                                </Form.Item>
                                <Form.Item
                                    label="Product Type"
                                    name="productType"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Product Type!"
                                        }
                                    ]}>
                                    <Select >
                                        {productTypes ? productTypes.map((productType, index) => {
                                            return (
                                                <Option key={index} value={productType.id}>
                                                    {productType.name}
                                                </Option>
                                            );
                                        }) : null}
                                    </Select>

                                </Form.Item>
                                <Form.Item
                                    label="Brand:"
                                    name="companyId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Brand!"
                                        }
                                    ]}>
                                    <Select>
                                        {users.map((user, index) => {
                                            return (
                                                <Option key={index} value={user.companyID}>
                                                    {user.companyName}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Short Description:"
                                    name="productDetail"
                                >
                                    <Input maxLength={32} />

                                </Form.Item>

                                <Form.Item
                                    label="Model / SKU :"
                                    name="sku"
                                >
                                    <Input maxLength={16} />
                                </Form.Item>
                                <Form.Item
                                    label="UPC:"
                                    name="upc"
                                >
                                    <Input maxLength={13} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: "5px" }}>
                                <Form.Item
                                    label="Receive Price:"
                                    name="receivePrice"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input RECEIVE_SERVICE!"
                                        }
                                    ]}>
                                    <InputNumber min={0} />
                                </Form.Item>
                                <Form.Item label="Process Price:"
                                    name="processPrice"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input PROCESS_SERVICE!"
                                        }
                                    ]} >
                                    <InputNumber min={0} />
                                </Form.Item>

                                <Form.Item
                                    label="Weight:"
                                    name="weight">
                                    <InputNumber min={0} addonAfter="g" maxLength={9} />
                                </Form.Item>
                                <Form.Item
                                    label="Height:"
                                    name="height">
                                    <InputNumber min={0} addonAfter="cm" maxLength={9} />
                                </Form.Item>
                                <Form.Item label="Width:"
                                    name="width">
                                    <InputNumber min={0} addonAfter="cm" maxLength={9} />
                                </Form.Item>
                                <Form.Item label="Length:" name="length">
                                    <InputNumber min={0} addonAfter="cm" maxLength={9} />
                                </Form.Item>
                            </Col>
                        </Row>
                        < Form.Item
                            wrapperCol={{
                                span: 5,
                                offset: 10
                            }}
                            style={{ marginTop: "35px" }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={loading}
                            >
                                Add New Product
                         </Button>
                        </Form.Item>
                    </Form>

                </div>
            </div>
        );
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <h1 style={{ marginBottom: "20px" }}>Manage Product</h1>

            <ProductDefinitionTabs />
        </div>
    );

}
