import { Form, Button, Checkbox, InputNumber, Select, DatePicker, Row, Col, Input } from 'antd';
import moment from 'moment';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import { Modal, Container } from 'react-bootstrap';
import { getCompanies, getDailyKPI } from "../../actions/index";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from '../lib/utils';

export const ManageKPI2 = (params) => {

    const dispatch = useDispatch();
    let history = useHistory();
    const { RangePicker } = DatePicker;
    const user = useSelector(state => state.authenticated);
    const companies = useSelector(state => state.companies);
    const dailyKPI = useSelector(state => state.dailyKPI);
    const localizer = momentLocalizer(moment);
    const { Option } = Select;
    const [createForm] = Form.useForm();
    const [editForm] = Form.useForm();

    const [events, setevents] = useState([]);
    const [showCreate, setshowCreate] = useState(false);
    const [showUpdate, setshowUpdate] = useState(false);
    const [selectedKPI, setselectedKPI] = useState(undefined);
    const [loadingCreate, setloadingCreate] = useState(false);
    const [loadingUpdate, setloadingUpdate] = useState(false);

    useEffect(() => {
        if (!user || (user.role !== "superAdmin")) {
            history.push('/');
        } else {
            getCompanies(user, dispatch);
            getDailyKPI(user, dispatch);
        }
    }, []);

    useEffect(() => {
        const dailyInfators = dailyKPI ? dailyKPI.map(e => {
            let color;
            switch (e.companyID) {
                case 2:
                    color = "#42d1f5";
                    break;
                case 3:
                    color = "#e9f542";
                    break;
                case 4:
                    color = "#f024f5";
                    break;
                case 5:
                    color = "#5213f5";
                    break;
                default:
                    color = "#3174ad"
            }
            return {
                id: e.id,
                companyID: e.companyID,
                kpi: e.kpi,
                companyName: e.companyName,
                date: e.date,
                title: `${e.companyName} - ${e.kpi}`,
                allDay: true,
                start: e.date,
                end: e.date,
                hexColor: color
            }
        }) : [];
        setevents(dailyInfators)
    }, [dailyKPI])


    const eventStyleGetter = (event) => {
        const backgroundColor = event.hexColor;
        const style = {
            backgroundColor: backgroundColor,
            borderRadius: '0px',
            opacity: 0.8,
            color: 'black',
            border: '0px',
            display: 'block'
        };
        return {
            style: style
        };
    };

    const handleSelectEvent = (event) => {
        setselectedKPI(event);
        setshowUpdate(true);
    }

    const closeCreateKPI = () => {
        setshowCreate(false);
    }

    const showCreateKPI = () => {
        setshowCreate(true);
    }

    const handleCreateKPI = async (values) => {

        const start = values.dateRange[0].add(-1, 'days').startOf('day');;
        const end = values.dateRange[1].endOf('day');;
        const kpi = values.kpi;
        const companyID = values.companyID;
        const Saturday = values.Saturday;
        const Sunday = values.Sunday;
        const data = [];

        while (start.add(1, 'days').diff(end) < 0) {
            if ((!Saturday && !Sunday) ? (start.day() !== 0 && start.day() !== 6) : (Saturday && !Sunday) ? (start.day() !== 0) : (Sunday && !Saturday) ? (start.day() !== 6) : true) {
                data.push([
                    companyID,
                    kpi,
                    moment(start.clone().toDate()).format("YYYY-MM-DD")
                ])
            }
        }

        createDailyKPI(data);

    }

    const createDailyKPI = async (data) => {

        try {
            const options = HEADER_OPTIONS_AUTH(user.token);
            setloadingCreate(true);
            await axios.post(`${BASE_URL}/daily-kpis`, data, options)
                .then(function (response) {
                    getDailyKPI(user, dispatch);
                    setloadingCreate(false);
                    setshowCreate(false);
                    createForm.resetFields();
                })
                .catch(function (error) {
                    setloadingCreate(false);
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });

        }
        catch (e) {
            console.log(e);
            openNotificationWithIcon(
                "error",
                JSON.stringify(e)
            );
        }
    }

    const handleUpdateKPI = async (val) => {
        if (window.confirm("Do you confirm to Update KPI?")) {
            var data = {};
            data["id"] = selectedKPI.id;
            data["companyID"] = selectedKPI.companyID;
            data["kpi"] = val.kpi;
            data["date"] = moment(selectedKPI.date).format("YYYY-MM-DD");
            try {
                setloadingUpdate(true);
                const options = HEADER_OPTIONS_AUTH(user.token);
                await axios.put(`${BASE_URL}/daily-kpi`, data, options)
                    .then(function (response) {
                        setloadingUpdate(false);
                        getDailyKPI(user, dispatch);
                        setshowUpdate(false);
                        openNotificationWithIcon(
                            "success",
                            "KPI Updated successfully."
                        );
                    })
                    .catch(function (error) {
                        setloadingUpdate(false);
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon(
                                "error",
                                error.response.data.message
                            );

                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });
            }
            catch (e) {
                setloadingUpdate(false);
                console.log(e);
                openNotificationWithIcon(
                    "error",
                    e
                );
            }
        }
    }

    const handleDeleteKPI = async () => {
        if (window.confirm("Do you confirm to Delete KPI?")) {
            try {
                setloadingUpdate(true);
                const options = HEADER_OPTIONS_AUTH(user.token);
                await axios.delete(`${BASE_URL}/daily-kpi/${selectedKPI.id}`, options)
                    .then(function (response) {
                        setloadingUpdate(false);
                        getDailyKPI(user, dispatch);
                        setshowUpdate(false);
                        openNotificationWithIcon(
                            "success",
                            "KPI Deleted successfully."
                        );
                    })
                    .catch(function (error) {
                        setloadingUpdate(false);
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon(
                                "error",
                                error.response.data.message
                            );

                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });
            }
            catch (e) {
                setloadingUpdate(false);
                console.log(e);
                openNotificationWithIcon(
                    "error",
                    e
                );
            }
        }
    }

    const closeUpdate = () => {
        setshowUpdate(false);
        setselectedKPI(undefined);
    }

    return (
        <Container style={{ textAlign: 'center' }}>
            <span>
            </span>
            {
                selectedKPI !== undefined &&
                <Modal backdrop="static" show={showUpdate} onHide={() => closeUpdate()} centered dialogClassName={'update-kpi-modal'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Manage KPI</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form
                            labelCol={{ span: 9 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                            form={editForm}
                            requiredMark={true}
                            onFinish={handleUpdateKPI}
                            size="medium"
                        >
                            <Form.Item
                                label="Date"
                                name="date"
                                preserve={false}
                                initialValue={selectedKPI.date.slice(0, 10)}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="Company:"
                                name="companyID"
                                preserve={false}
                                initialValue={selectedKPI.companyName}
                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                label="KPI:"
                                name="kpi"
                                preserve={false}
                                initialValue={selectedKPI.kpi}
                                rules={[
                                    { required: true, message: "Please input KPI!" }
                                ]}>
                                <InputNumber step={1} min={1} />
                            </Form.Item>
                            < Form.Item
                                wrapperCol={{
                                    span: 24,
                                    offset: 7
                                }}
                                style={{ marginTop: "15px" }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={loadingUpdate}
                                >
                                    Update KPI
                           </Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    loading={loadingUpdate}
                                    onClick={handleDeleteKPI}
                                    style={{ marginLeft: "15px" }}
                                    danger
                                >
                                    Delete KPI
                           </Button>
                            </ Form.Item>
                        </Form>
                    </Modal.Body>
                </Modal>
            }
            <Modal backdrop="static" show={showCreate} onHide={() => closeCreateKPI()} centered dialogClassName={'create-kpi-modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>Create New KPI</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form
                        labelCol={{ span: 9 }}
                        wrapperCol={{ span: 12 }}
                        layout="horizontal"
                        form={createForm}
                        requiredMark={true}
                        onFinish={handleCreateKPI}
                        size="medium"
                    >
                        <Form.Item
                            label="Company:"
                            name="companyID"
                            preserve={false}
                            rules={[
                                {
                                    required: true,
                                    message: "Please input Company!"
                                }
                            ]}>
                            <Select>
                                {companies.map((company, index) => {
                                    return (
                                        <Option key={index} value={company.companyID}>
                                            {company.companyName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Date:"
                            name="dateRange"
                            preserve={false}
                            rules={[
                                { required: true, message: "Please input Start Date!" }
                            ]}>
                            <RangePicker />
                        </Form.Item>

                        <Form.Item
                            label="KPI:"
                            name="kpi"
                            preserve={false}
                            rules={[
                                { required: true, message: "Please input KPI!" }
                            ]}>
                            <InputNumber step={1} min={1} />
                        </Form.Item>
                        <Form.Item
                            name="Saturday"
                            valuePropName="checked"
                            initialValue={false}
                            preserve={false}
                            wrapperCol={{
                                span: 7,
                                offset: 8
                            }}
                        >
                            <Checkbox>Include Saturday</Checkbox>
                        </Form.Item>
                        <Form.Item
                            name="Sunday"
                            valuePropName="checked"
                            initialValue={false}
                            preserve={false}
                            wrapperCol={{
                                span: 7,
                                offset: 8
                            }}
                        >
                            <Checkbox>Include Sunday</Checkbox>
                        </Form.Item>
                        < Form.Item
                            wrapperCol={{
                                span: 6,
                                offset: 9
                            }}
                            style={{ marginTop: "30px" }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={loadingCreate}
                            >
                                Create KPI
                           </Button>

                        </Form.Item>
                    </Form>
                </Modal.Body>
            </Modal>

            <Row style={{ marginTop: "20px" }}>
                <Col span={20}  >
                    <h2 style={{ color: 'rgb(88, 88, 88, 0.8)' }}>Manage KPI</h2>
                </Col>
                <Col span={4}>
                    <Button size="large" type="primary" onClick={() => showCreateKPI()}>Create New KPI</Button>

                </Col>
            </Row>
            <hr />
            <Calendar
                style={{ height: '600px', }}
                localizer={localizer}
                events={events}
                defaultView={Views.MONTH}
                //views={['month']}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
            />
        </Container>
    )
}
