import { CREATE_DAILY_BARTH_SKID_KPI, DELETE_DAILY_SKID_KPI, GET_DAILY_SKID_KPI, UPDATE_DAILY_SKID_KPI } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {
        case GET_DAILY_SKID_KPI:
            return action.payload;
        case UPDATE_DAILY_SKID_KPI:
            return state.map((kpi) => {
                if (kpi.id === action.payload.id) {
                    return action.payload;
                }
                return kpi;
            });
        case CREATE_DAILY_BARTH_SKID_KPI:
            return [...state, ...action.payload];
        case DELETE_DAILY_SKID_KPI:
            return state.filter((kpi) => kpi.id !== action.payload);
        default:
            return state;
    }
}