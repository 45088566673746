import axios from 'axios';
import localStorage from 'local-storage';
import React, { Component } from 'react';
import { Button, ButtonToolbar, Container, Form, Jumbotron } from 'react-bootstrap';
import { BASE_URL, HEADER_OPTIONS } from './lib/basic';
import { openNotificationWithIcon } from "./lib/utils";
class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailSent: false,
            message: ''
        }
    }
    componentWillMount() {
        localStorage.clear();
    }
    onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        })
    };

    handleSendEmail = async () => {
        await axios.post(`${BASE_URL}/forget`, { email: this.state.email }, HEADER_OPTIONS)
            .then(function (response) {
                this.setState({
                    emailSent: true,
                    message: response.data.message
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    openNotificationWithIcon(
                        "error",
                        error.response.data.message
                    );
                    this.setState({
                        message: error.response.data.message
                    })
                } else if (error.request) {
                    // The request was made but no response was received
                    openNotificationWithIcon(
                        "error",
                        "No internet!"
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    openNotificationWithIcon(
                        "error",
                        error.message
                    );
                }
            });

    };

    render() {
        return (
            <Container className={'loading'}>
                <style type="text/css">
                    {`
                        .loading {
                            background-color: red;
                            min-height: 100vh;
                            min-width: 100vw;
                           background-image: url('/img/background.jpg');
                            background-size: 120vw;
                            opacity: 0.6;
                        }
          
                        .vertical-center {
                           width: 75vh;
                           margin: 0 auto;
                           background: linear-gradient( rgba(0, 255, 204,0.5),rgba(0, 204, 102, 1));
                        }
                        .btn-flat {
                          background-color: purple;
                          color: white;
                          margin: 3vh auto 0;
                        }
                    
                        .btn-xxl {
                          padding: 1rem 1.5rem;
                          font-size: 1.5rem;
                        }
                       
                        .login{
                             text-align: center;
                             margin-bottom: 1em;
                             color: white
                        }
                        .login-label{
                           font-size: 1.5rem;
                           font-weight:bold; 
                        }
                        
                    `}
                </style>
                <hr style={{ marginBottom: '20vh', marginTop: '0' }} />
                {
                    !this.state.emailSent &&
                    <Jumbotron className="vertical-center">
                        <h1 className={"login"}>Forget Password</h1>
                        <h2 style={{ textAlign: 'center', color: 'red', marginBottom: '1.5em' }}>{this.state.message}</h2>
                        <Form>
                            <Form.Group controlId="formGroupEmail">
                                <Form.Label className={"login-label"}>Enter Email</Form.Label>
                                <Form.Control type="email" placeholder="Please enter email" name={'email'} onChange={this.onChange} />
                            </Form.Group>
                        </Form>
                        <ButtonToolbar>
                            <Button variant="flat" size="lg" onClick={this.handleSendEmail}>Send Reset Email</Button>
                        </ButtonToolbar>
                    </Jumbotron>
                }
                {
                    this.state.emailSent &&
                    <Jumbotron className="vertical-center">
                        <h1 className={"login"}>{this.state.message}</h1>
                        <ButtonToolbar>
                            <Button variant="flat" size="lg" href="/">Back to Login</Button>
                        </ButtonToolbar>
                    </Jumbotron>
                }
            </Container>
        )
    }
}

export default ForgetPassword;