import axios from 'axios';
import localStorage from 'local-storage';
import { BASE_URL, HEADER_OPTIONS, HEADER_OPTIONS_AUTH } from "../components/lib/basic";
import { openNotificationWithIcon } from '../components/lib/utils';
import {
  CHANGE_AUTH,


  CREATE_BARTH_DAILY_KPI,
  CREATE_DAILY_BARTH_SKID_KPI, CREATE_SHIPMENT, CREATE_SKID,

  DELETE_DAILY_KPI,

  DELETE_DAILY_SKID_KPI, FETCH_ERROR_MESSAGE, GET_BUSINESS_REP_LIST_PO,




  GET_ALL_PRICES,

  GET_ALL_USERS, GET_BUFFER_LIST, GET_CARRIERS, GET_CLIENTS, GET_COMPANY,

  GET_DAILY_KPI,
  GET_DAILY_SKID_KPI, GET_INFLATORS_INVENTORY, GET_ITEMS, GET_MONTHLY_KPI,

  GET_MONTHLY_RECORDS,




  GET_PROCESSING_INVOICE_SKIDS, GET_PRODUCTS,

  GET_PRODUCT_STATUS_DATA, GET_PRODUCT_TYPES, GET_PURCHASE_ORDERS, GET_QUICKBOOK_TOKEN,


  GET_RECEIVED_SKIDS_BARCODES, GET_SHIPMENTS, GET_SKIDS,






  GET_SKIDS_FOR_PRODUCT_TRANSFER, GET_SKID_DIMENSIONS,

  GET_SKID_STATUS_DATA, GET_USERS, GET_WAREHOUSES, GET_WEEKLY_KPI,


  GET_WEEKLY_RECORDS,

  GET_WEEKLY_SKID_KPI, GET_WEEKLY_SKID_RECORDS, UPDATE_DAILY_KPI,
  UPDATE_DAILY_SKID_KPI, UPDATE_MONTHLY_KPI,





  UPDATE_PRICE, UPDATE_SHIPMENT, UPDATE_USER,
  UPDATE_USER_SELF, UPDATE_WEEKLY_KPI, GET_PRODUCT_TABLE_DATA, GET_PRODUCT_TYPE_LIST
} from "./types";

export const login = async (data, dispatch) => {

  try {
    await axios.post(`${BASE_URL}/login`, data, HEADER_OPTIONS)
      .then(function (response) {
        const user = response.data.data;
        delete user.password;
        dispatch({ type: CHANGE_AUTH, payload: user });
        dispatch({ type: FETCH_ERROR_MESSAGE, payload: '' });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: FETCH_ERROR_MESSAGE, payload: error.response.data.message });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "Can't connect to server!"
          );
          dispatch({ type: FETCH_ERROR_MESSAGE, payload: "Check your internet connection!" });
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
          dispatch({ type: FETCH_ERROR_MESSAGE, payload: error.message });
        }
      });


  }
  catch (e) {
    dispatch({ type: FETCH_ERROR_MESSAGE, payload: 'Wrong user name or password' });
  }
};

export const logout = async (user, dispatch) => {

  try {
    const options = {
      headers: {
        "Authorization": 'Bearer ' + user.token
      }
    };
    await axios.post(`${BASE_URL}/logout`, {}, options);
    dispatch({ type: CHANGE_AUTH, payload: false });
    dispatch({ type: GET_USERS, payload: [] });
    dispatch({ type: GET_SKIDS, payload: [] });
    dispatch({ type: GET_WAREHOUSES, payload: [] });
    dispatch({ type: GET_ITEMS, payload: [] });
    dispatch({ type: GET_WEEKLY_KPI, payload: [] });
    dispatch({ type: GET_MONTHLY_KPI, payload: [] });
    dispatch({ type: GET_COMPANY, payload: [] });
    dispatch({ type: GET_WEEKLY_RECORDS, payload: [] });
    dispatch({ type: GET_DAILY_KPI, payload: [] });
    dispatch({ type: GET_MONTHLY_RECORDS, payload: [] });
    dispatch({ type: GET_INFLATORS_INVENTORY, payload: [] });
    dispatch({ type: GET_DAILY_SKID_KPI, payload: [] });
    dispatch({ type: GET_WEEKLY_SKID_RECORDS, payload: [] });
    dispatch({ type: GET_QUICKBOOK_TOKEN, payload: [] });
    dispatch({ type: GET_PRODUCTS, payload: [] });
    dispatch({ type: GET_ALL_PRICES, payload: [] });
    //clean localstorage
    localStorage.remove("AuthorizationUrl");
    localStorage.remove("Token");
    localStorage.remove("oauthTokenSecret");
    localStorage.remove("timer");

  } catch (e) {
    dispatch({ type: CHANGE_AUTH, payload: false });
    dispatch({ type: GET_USERS, payload: [] });
    dispatch({ type: GET_SKIDS, payload: [] });
    dispatch({ type: GET_WAREHOUSES, payload: [] });
    dispatch({ type: GET_ITEMS, payload: [] });
    dispatch({ type: GET_WEEKLY_KPI, payload: [] });
    dispatch({ type: GET_MONTHLY_KPI, payload: [] });
    dispatch({ type: GET_COMPANY, payload: [] });
    dispatch({ type: GET_WEEKLY_RECORDS, payload: [] });
    dispatch({ type: GET_DAILY_KPI, payload: [] });
    dispatch({ type: GET_MONTHLY_RECORDS, payload: [] });
    dispatch({ type: GET_INFLATORS_INVENTORY, payload: [] });
    dispatch({ type: GET_DAILY_SKID_KPI, payload: [] });
    dispatch({ type: GET_WEEKLY_SKID_RECORDS, payload: [] });
    dispatch({ type: GET_QUICKBOOK_TOKEN, payload: [] });
    dispatch({ type: GET_ALL_PRICES, payload: [] });
    dispatch({ type: GET_PRODUCTS, payload: [] });
    //clean localstorage
    localStorage.remove("AuthorizationUrl");
    localStorage.remove("Token");
    localStorage.remove("oauthTokenSecret");
    localStorage.remove("timer");
  }
};

export const removeError = async (dispatch) => {
  dispatch({ type: FETCH_ERROR_MESSAGE, payload: '' });
};

export const getSkids = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/skid`, options)
      .then(function (response) {
        const skids = response.data.data;
        dispatch({ type: GET_SKIDS, payload: skids });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_SKIDS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_SKIDS, payload: [] });
  }
};

export const getSkidsForProductTransfer = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getSkidsForProductTransfer`, options)
      .then(function (response) {

        const skids = response.data.data;
        dispatch({ type: GET_SKIDS_FOR_PRODUCT_TRANSFER, payload: skids });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_SKIDS_FOR_PRODUCT_TRANSFER, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_SKIDS_FOR_PRODUCT_TRANSFER, payload: [] });
  }
};

export const getProcessingInvoiceSkids = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/processingInvoiceSkids`, options)
      .then(function (response) {

        const processingSkids = response.data.data;
        dispatch({ type: GET_PROCESSING_INVOICE_SKIDS, payload: processingSkids });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_SKIDS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });



  }
  catch (e) {
    dispatch({ type: GET_SKIDS, payload: [] });
  }
};

export const getSkidDimensions = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/skid_default_dimensions`, options)
      .then(function (response) {

        const skids = response.data.data;
        dispatch({ type: GET_SKID_DIMENSIONS, payload: skids });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_SKID_DIMENSIONS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_SKID_DIMENSIONS, payload: [] });
  }
};

export const getItems = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/process_task`, options)
      .then(function (response) {

        const items = response.data.data;
        dispatch({ type: GET_ITEMS, payload: items });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_ITEMS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_ITEMS, payload: [] });
  }
};

//admin
export const getUsers = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/user`, options)
      .then(function (response) {

        const users = response.data.data;
        dispatch({ type: GET_USERS, payload: users });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_USERS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    console.log(e);
    dispatch({ type: GET_USERS, payload: [] });
  }
};

export const getBusinessRepWithPO = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getBusinessRepListWithPO`, options)
      .then(function (response) {
        let businessRepWithPO = response.data.data;
        dispatch({ type: GET_BUSINESS_REP_LIST_PO, payload: businessRepWithPO });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_BUSINESS_REP_LIST_PO, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    console.log(e);
    dispatch({ type: GET_USERS, payload: [] });
  }
};


export const getReceivedSkidBarcodes = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/receivedSkidsBarcode`, options)
      .then(function (response) {

        const barCodes = response.data.data;
        dispatch({ type: GET_RECEIVED_SKIDS_BARCODES, payload: barCodes });

      })
      .catch(function (error) {
        dispatch({ type: GET_RECEIVED_SKIDS_BARCODES, payload: [] });
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    console.log(e);
    dispatch({ type: GET_RECEIVED_SKIDS_BARCODES, payload: [] });
  }
};

export const getAllUsers = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getAllUsers`, options)
      .then(function (response) {

        const users = response.data.data;
        dispatch({ type: GET_ALL_USERS, payload: users });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_ALL_USERS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  }
  catch (e) {
    dispatch({ type: GET_ALL_USERS, payload: [] });
  }
};

export const upDateUser = async (user, toUpdateUser, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.put(`${BASE_URL}/user`, toUpdateUser, options)
      .then(function (response) {

        const updatedUser = response.data.data;
        dispatch({ type: UPDATE_USER, payload: updatedUser });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_USER, payload: toUpdateUser });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_USER, payload: toUpdateUser });
  }
};

export const upDateUserSelf = (user, toUpdateUser) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.patch(`${BASE_URL}/user`, toUpdateUser, options)
      .then(function (response) {

        const updatedUser = response.data.data;
        dispatch({ type: UPDATE_USER_SELF, payload: updatedUser });
        window.location.reload();

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    alert(e.message);
    return (e.message)
  }
};





export const uploadAveryDataFile = (user, data) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.post(`${BASE_URL}/skid/data`, data, options)

      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: CREATE_SKID, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  }
  catch (e) {
    dispatch({ type: CREATE_SKID, payload: [] });
  }
};

export const getWarehouse = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/warehouse`, options)
      .then(function (response) {

        const users = response.data.data;

        dispatch({ type: GET_WAREHOUSES, payload: users });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_WAREHOUSES, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_WAREHOUSES, payload: [] });
  }
};

export const getShipment = (user) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/shipment`, options)
      .then(function (response) {

        const users = response.data.data;
        dispatch({ type: GET_SHIPMENTS, payload: users });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_SHIPMENTS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_SHIPMENTS, payload: [] });
  }
};

export const updateShipment = (user, shipment) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.put(`${BASE_URL}/shipment`, shipment, options)
      .then(function (response) {

        const updatedShipment = response.data.data;
        dispatch({ type: UPDATE_SHIPMENT, payload: updatedShipment });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_SHIPMENT, payload: shipment });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_SHIPMENT, payload: shipment });
  }
};

export const createShipment = (user, data) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.post(`${BASE_URL}/shipment`, data, options)
      .then(function (response) {

        dispatch({ type: CREATE_SHIPMENT, payload: response.data.data });
        dispatch({ type: CREATE_SHIPMENT, payload: response.data.data });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: CREATE_SHIPMENT, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: CREATE_SHIPMENT, payload: [] });
  }
};

export const getPurchaseOrders = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/purchase_order`, options)
      .then(function (response) {

        const purchaseOrders = response.data.data;
        dispatch({ type: GET_PURCHASE_ORDERS, payload: purchaseOrders });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_PURCHASE_ORDERS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }
  catch (e) {
    dispatch({ type: GET_PURCHASE_ORDERS, payload: [] });
  }
};

export const getCarriers = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getAllCarriers`, options)
      .then(function (response) {

        const carriers = response.data.data;
        dispatch({ type: GET_CARRIERS, payload: carriers });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_CARRIERS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_CARRIERS, payload: [] });
  }
};


export const getProductTypes = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getAllActiveProductTypes`, options)
      .then(function (response) {

        const productTypes = response.data.data;
        dispatch({ type: GET_PRODUCT_TYPES, payload: productTypes });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_PRODUCT_TYPES, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_PRODUCT_TYPES, payload: [] });
  }
};

export const getClients = async (user, dispatch) => {

  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/client`, options)
      .then(function (response) {

        const clients = response.data.data;
        dispatch({ type: GET_CLIENTS, payload: clients });


      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_CLIENTS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_CLIENTS, payload: [] });
  }
};


export const getWeeklyKPI = async (user, dateTime, companyID, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/weekly-kpi/${dateTime}/${companyID}`, options)
      .then(function (response) {

        const kpis = response.data.data;
        dispatch({ type: GET_WEEKLY_KPI, payload: kpis });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_WEEKLY_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_WEEKLY_KPI, payload: [] });
  }
};

export const updateWeeklyKPI = (user, kpi) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    let kpiCopy = Object.assign({}, kpi);
    delete kpiCopy.companyName;
    await axios.put(`${BASE_URL}/weekly-kpi`, kpiCopy, options)
      .then(function (response) {

        dispatch({ type: UPDATE_WEEKLY_KPI, payload: kpi });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_WEEKLY_KPI, payload: kpi });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_WEEKLY_KPI, payload: kpi });
  }
};

export const updateMonthlyKPI = (user, kpi) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    let kpiCopy = Object.assign({}, kpi);
    delete kpiCopy.companyName;
    await axios.put(`${BASE_URL}/monthly-kpi`, kpiCopy, options)
      .then(function (response) {

        dispatch({ type: UPDATE_MONTHLY_KPI, payload: kpi });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_MONTHLY_KPI, payload: kpi });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_MONTHLY_KPI, payload: kpi });
  }
};

export const getMonthlyKPI = async (user, dateTime, companyID, dispatch) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/monthly-kpi/${dateTime}/${companyID}`, options)
      .then(function (response) {

        const kpis = response.data.data;
        dispatch({ type: GET_MONTHLY_KPI, payload: kpis });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_MONTHLY_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_MONTHLY_KPI, payload: [] });
  }
};

export const clearAllKPI = async (dispatch) => {
  dispatch({ type: GET_MONTHLY_KPI, payload: [] });
  dispatch({ type: GET_WEEKLY_KPI, payload: [] });
  dispatch({ type: GET_WEEKLY_RECORDS, payload: [] });
};

export const getCompanies = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/company`, options)
      .then(function (response) {

        const companies = response.data.data;
        dispatch({ type: GET_COMPANY, payload: companies });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_COMPANY, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_COMPANY, payload: [] });
  }
};

export const getProducts = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/products`, options)
      .then(function (response) {

        const products = response.data.data;
        dispatch({ type: GET_PRODUCTS, payload: products });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_PRODUCTS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_PRODUCTS, payload: [] });
  }
};

export const getSkidStatusData = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getSkidStatusData`, options)
      .then(function (response) {

        const data = response.data.data;
        dispatch({ type: GET_SKID_STATUS_DATA, payload: data });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_SKID_STATUS_DATA, payload: {} });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  }
  catch (e) {
    dispatch({ type: GET_SKID_STATUS_DATA, payload: {} });
  }
};

export const getProductStatusData = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getProductStatusData`, options)
      .then(function (response) {

        const data = response.data.data;
        dispatch({ type: GET_PRODUCT_STATUS_DATA, payload: data });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_PRODUCT_STATUS_DATA, payload: {} });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_PRODUCT_STATUS_DATA, payload: {} });
  }
};

export const getWeeklyRecords = async (user, dateTime, companyID, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/weeklyRecords/${dateTime}/${companyID}`, options)
      .then(function (response) {

        const records = response.data.data;
        dispatch({ type: GET_WEEKLY_RECORDS, payload: records });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_WEEKLY_RECORDS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_WEEKLY_RECORDS, payload: [] });
  }
};

export const getMonthlyRecords = async (user, dateTime, companyID, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/monthlyRecords/${dateTime}/${companyID}`, options)
      .then(function (response) {

        const records = response.data.data;
        dispatch({ type: GET_MONTHLY_RECORDS, payload: records });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_MONTHLY_RECORDS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  }
  catch (e) {
    dispatch({ type: GET_MONTHLY_RECORDS, payload: [] });
  }
};

export const getDailyKPI = async (user, dispatch) => {
  try {

    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getDailyKPIForCalendar`, options)
      .then(function (response) {

        const kpis = response.data.data;
        dispatch({ type: GET_DAILY_KPI, payload: kpis });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_DAILY_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_DAILY_KPI, payload: [] });
  }
};

export const createBatchDailyKPI = (user, data) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.post(`${BASE_URL}/daily-kpis`, data, options)
      .then(function (response) {
        dispatch({ type: CREATE_BARTH_DAILY_KPI, payload: response.data.data });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: CREATE_BARTH_DAILY_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  }
  catch (e) {
    dispatch({ type: CREATE_BARTH_DAILY_KPI, payload: [] });
  }
};

export const updateDailyKPI = (user, kpi) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    const data = {
      id: kpi.id,
      companyID: kpi.companyID,
      date: kpi.date,
      kpi: kpi.kpi
    };
    await axios.put(`${BASE_URL}/daily-kpi`, data, options)
      .then(function (response) {

        data.companyName = kpi.companyName;
        dispatch({ type: UPDATE_DAILY_KPI, payload: data });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_DAILY_KPI, payload: kpi });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_DAILY_KPI, payload: kpi });
  }
};

export const deleteDailyKPI = (user, id) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.delete(`${BASE_URL}/daily-kpi/${id}`, options)
      .then(function (response) {

        dispatch({ type: DELETE_DAILY_KPI, payload: id });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: DELETE_DAILY_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: DELETE_DAILY_KPI, payload: [] });
  }
};

export const getInflatorsInventory = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/inflators-inventory`, options)
      .then(function (response) {

        const records = response.data.data;
        dispatch({ type: GET_INFLATORS_INVENTORY, payload: records });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_INFLATORS_INVENTORY, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });

  }
  catch (e) {
    dispatch({ type: GET_INFLATORS_INVENTORY, payload: [] });
  }
};



export const getDailySkidKPI = (user) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/daily-skid-kpi`, options)
      .then(function (response) {

        const kpis = response.data.data;
        dispatch({ type: GET_DAILY_SKID_KPI, payload: kpis });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_DAILY_SKID_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }
  catch (e) {
    dispatch({ type: GET_DAILY_SKID_KPI, payload: [] });
  }
};

export const createBatchDailySkidKPI = (user, data) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.post(`${BASE_URL}/daily-skid-kpis`, data, options)
      .then(function (response) {

        dispatch({ type: CREATE_DAILY_BARTH_SKID_KPI, payload: response.data.data });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: CREATE_DAILY_BARTH_SKID_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: CREATE_DAILY_BARTH_SKID_KPI, payload: [] });
  }
};

export const updateDailySkidKPI = (user, kpi) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    const data = {
      id: kpi.id,
      companyID: kpi.companyID,
      date: kpi.date,
      kpi: kpi.kpi
    };
    await axios.put(`${BASE_URL}/daily-skid-kpi`, data, options)
      .then(function (response) {

        data.companyName = kpi.companyName;
        dispatch({ type: UPDATE_DAILY_SKID_KPI, payload: data });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_DAILY_SKID_KPI, payload: kpi });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_DAILY_SKID_KPI, payload: kpi });
  }
};

export const deleteDailySkidKPI = (user, id) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.delete(`${BASE_URL}/daily-skid-kpi/${id}`, options)
      .then(function (response) {

        dispatch({ type: DELETE_DAILY_SKID_KPI, payload: id });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: DELETE_DAILY_SKID_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: DELETE_DAILY_SKID_KPI, payload: [] });
  }
};

export const getWeeklySkidKPI = async (user, dateTime, companyID, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/weekly-skid-kpi/${dateTime}/${companyID}`, options)
      .then(function (response) {

        const kpis = response.data.data;
        dispatch({ type: GET_WEEKLY_SKID_KPI, payload: kpis });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_WEEKLY_SKID_KPI, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_WEEKLY_SKID_KPI, payload: [] });
  }
};

export const getWeeklySkidRecords = async (user, dateTime, companyID, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/weekly-skid-records/${dateTime}/${companyID}`, options)
      .then(function (response) {

        const records = response.data.data;
        dispatch({ type: GET_WEEKLY_SKID_RECORDS, payload: records });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_WEEKLY_SKID_RECORDS, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_WEEKLY_SKID_RECORDS, payload: [] });
  }
};

//==========================================Quick book actions========================================
export const getQuickBookToken = async (user, aurthorizedURLcode, dispatch) => {
  try {
    // console.log(aurthorizedURLcode)

    let splitedList1 = aurthorizedURLcode.split("&");
    let code = splitedList1[0].split("=")[1];
    let state = splitedList1[1].split("=")[1];
    let realmId = splitedList1[2].split("=")[1];
    const options = HEADER_OPTIONS_AUTH(user.token);
    const response = await axios.get(`${BASE_URL}/quickBookToken/${code}/${state}/${realmId}`, options);
    localStorage.set("oauthTokenSecret", response.data.oauthTokenSecret);

    if (response.status === 200) {
      const quickBookToken = response.data.data;
      dispatch({ type: GET_QUICKBOOK_TOKEN, payload: quickBookToken });
    }

  } catch (e) {
    dispatch({ type: GET_QUICKBOOK_TOKEN, payload: [] });
  }
}

export const getInvoiceTemplate = () => async dispatch => {
  try {

  } catch (e) {

  }
}

export const downLoadInvoice = () => async dispatch => {

}


//==========================================Price Management========================================
export const getPrices = async (user, dispatch) => {
  try {
    await axios.get(`${BASE_URL}/prices`, HEADER_OPTIONS_AUTH(user.token))
      .then(function (response) {

        const records = response.data.data;
        dispatch({ type: GET_ALL_PRICES, payload: records });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_ALL_PRICES, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: GET_ALL_PRICES, payload: [] });
  }
};

export const updatePrice = (user, priceId, price) => async dispatch => {
  try {
    await axios.get(`${BASE_URL}/price/${priceId}/${price}`, HEADER_OPTIONS_AUTH(user.token))
      .then(function (response) {

        const records = response.data.data;
        console.log(records)
        dispatch({ type: UPDATE_PRICE, payload: records });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: UPDATE_PRICE, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });


  }
  catch (e) {
    dispatch({ type: UPDATE_PRICE, payload: [] });
  }
}



export const getBufferList = (user) => async dispatch => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/adjustmentBuffer`, options)
      .then(function (response) {

        const items = response.data.data;
        dispatch({ type: GET_BUFFER_LIST, payload: items });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_BUFFER_LIST, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }
  catch (e) {
    dispatch({ type: GET_BUFFER_LIST, payload: [] });
  }
};

export const getProductsTableData = async (user, dispatch) => {
  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getProductTableData`, options)
      .then(function (response) {
        let productData = response.data.data;
        dispatch({ type: GET_PRODUCT_TABLE_DATA, payload: productData });

      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_PRODUCT_TABLE_DATA, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }
  catch (e) {
    console.log(e);
    dispatch({ type: GET_PRODUCT_TABLE_DATA, payload: [] });
  }
};

export const getProductTypeList = async (user, dispatch) => {

  try {
    const options = HEADER_OPTIONS_AUTH(user.token);
    await axios.get(`${BASE_URL}/getProductTypeList`, options)
      .then(function (response) {
        const productTypes = response.data.data;
        dispatch({ type: GET_PRODUCT_TYPE_LIST, payload: productTypes });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          openNotificationWithIcon(
            "error",
            error.response.data.message
          );
          dispatch({ type: GET_PRODUCT_TYPE_LIST, payload: [] });
        } else if (error.request) {
          // The request was made but no response was received
          openNotificationWithIcon(
            "error",
            "No internet!"
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          openNotificationWithIcon(
            "error",
            error.message
          );
        }
      });
  }
  catch (e) {
    dispatch({ type: GET_PRODUCT_TYPE_LIST, payload: [] });
  }
};
