import axios from "axios";
import moment from 'moment';
import React, { Component } from 'react';
import { Form, Modal, Table } from 'react-bootstrap';
import { Line } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from "../lib/utils";
class KPIModal extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            startDate: undefined,
            endDate: undefined,
            label: [],
            kpi: [],
            processed: []
        }
    }
    handleChangeStartDate = (date) => {
        this.setState({ startDate: date })
    };
    handleChangeEndDate = (date) => {
        this.setState({ endDate: date })
    };
    handleClose = () => {
        //reset state
        this.setState({
            startDate: undefined,
            endDate: undefined,
            label: [],
            kpi: [],
            processed: [],
            companyID: undefined
        }, () => {
            this.props.close();
        });
    };
    handleCheckKPI = async () => {
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
        const companyID = this.state.companyID;
        const label = this.state.label;
        const kpi = this.state.kpi;
        const processed = this.state.processed;
        const user = this.props.authenticated;
        const options = HEADER_OPTIONS_AUTH(user.token);
        await axios.get(`${BASE_URL}/range-kpi/${startDate}/${endDate}/${companyID}`, options)
            .then(function (response) {
                const results = response.data.data;
                results.forEach(r => {
                    label.push(r.date);
                    kpi.push(r.kpi);
                    processed.push(r.processed);
                });
                this.setState({
                    label,
                    kpi,
                    processed
                })
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    openNotificationWithIcon(
                        "error",
                        error.response.data.message
                    );
                } else if (error.request) {
                    // The request was made but no response was received
                    openNotificationWithIcon(
                        "error",
                        "No internet!"
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    openNotificationWithIcon(
                        "error",
                        error.message
                    );
                }
            });

    };
    componentDidMount() {
        if (!this.props.authenticated) {
            // this.props.history.push('/');
        } else {
            const user = this.props.authenticated;
            //  this.props.getShipment(user);
            //this.props.getItems(user);
            //this.props.getSkids(user);
            //
            if (user.role === "admin" || user.role === "superAdmin" || user.role === "operator") {
                //this.props.getCompanies(user);
                //  this.props.getDailyKPI(user);
                //this.props.clearAllKPI();
            }
        }
    }
    handleSelectCompanyOnchange = async (e) => {
        const companyID = e.target.value;
        if (companyID === "") {
            this.setState({
                startDate: undefined,
                endDate: undefined,
                label: [],
                kpi: [],
                processed: [],
                companyID: undefined
            })
        } else {
            this.setState({
                companyID: companyID,
                label: [],
                kpi: [],
                processed: [],
            }, async () => {
                await this.handleCheckKPI();
            })
        }
    };
    render() {
        const label = this.state.label;
        const kpi = this.state.kpi;
        const processed = this.state.processed;
        const KPIData = {
            labels: label,
            datasets: [
                {
                    label: "KPI",
                    fill: false,
                    borderColor: 'rgb(202, 61, 204)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    pointBorderWidth: 2,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    data: kpi,
                },
                {
                    label: "Processed",
                    backgroundColor: "rgb(61, 204, 147,0.3)",
                    borderColor: 'rgb(61, 204, 147)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    pointBorderWidth: 2,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgb(61, 204, 147)',
                    data: processed,
                }
            ]
        };
        const KPIOptions = {
            title: {
                display: true,
                text: 'RANGE KPI',
                fontSize: 20,
                padding: 20
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    fontSize: 15
                }
            },
            // scales: {
            //     yAxes: [{
            //         ticks: {
            //             min: 0,
            //             suggestedMax: 20,
            //             stepSize: 50
            //         }
            //     }]
            // }
            // scales: {
            //     yAxes: [{
            //         ticks: {
            //             min: 1,
            //             suggestedMax: 5,
            //             stepSize: 1
            //         }
            //     }]
            // }
        };
        const companies = this.props.companies;
        return (
            <Modal backdrop="static" size="lg" show={this.props.show} onHide={this.handleClose} centered dialogClassName={'user-modal'}>
                <Modal.Header closeButton>
                    <Modal.Title>RANGE KPI</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive bordered={true} style={{ marginBottom: '0px' }}>
                        <tbody>
                            <tr>
                                <th>Start Date</th>
                                <td>
                                    <DatePicker
                                        selected={this.state.startDate === undefined ? null : this.state.startDate}
                                        onChange={this.handleChangeStartDate}
                                    />
                                </td>
                                <th>End Date</th>
                                <td>
                                    <DatePicker
                                        selected={this.state.endDate === undefined ? null : this.state.endDate}
                                        onChange={this.handleChangeEndDate}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Company</th>
                                {(companies.length !== undefined && companies.length > 0) &&
                                    <td colSpan={'3'}>
                                        <Form.Control as="select" onChange={this.handleSelectCompanyOnchange} >
                                            <option value={""}>--- Please Company to view KPI ---</option>
                                            {
                                                companies.map((company, index) => {
                                                    return <option key={index} value={company.id}>{company.companyName}</option>
                                                })
                                            }
                                            <option value={"undefined"}>--- Show All ---</option>
                                        </Form.Control>
                                    </td>
                                }
                            </tr>
                        </tbody>
                    </Table>
                    {
                        label.length !== 0 && <Line data={KPIData} options={KPIOptions} redraw={true} />
                    }

                </Modal.Body>
                {/*<Modal.Footer>*/}
                {/*    <Button variant="secondary" onClick={this.handleClose} >*/}
                {/*        Close*/}
                {/*    </Button>*/}
                {/*    <Button variant="primary" onClick={this.handleCheckKPI}>*/}
                {/*        Check KPI*/}
                {/*    </Button>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        companies: state.companies
    }
};
export default connect(mapStateToProps, actions)(KPIModal);