import {
  DollarTwoTone,
  SearchOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Drawer, Input, Pagination, Popconfirm, Row, Select, Space, Statistic, Table, Tag } from 'antd';
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from "react-redux";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';

const { Option } = Select;

export const ReceivingInvoiceUI = (data) => {

  const user = useSelector(state => state.authenticated);
  const [formattedSkids, setformattedSkids] = useState({});
  const [skidsTableData, setskidsTableData] = useState(undefined);
  const [loadingSkidsTableData, setloadingSkidsTableData] = useState(true);
  const [currentSkidsTablePage, setcurrentSkidsTablePage] = useState(1);
  const [totalSkids, settotalSkids] = useState(undefined);
  const [selectedCompany, setSelectedCompany] = useState("default");
  const [visible, setvisible] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [searchedColumn, setsearchedColumn] = useState("");
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getSkidsForReceivingInvoice();
    getSkidTableData(1);
  }, []);

  const getSkidsForReceivingInvoice = async () => {
    try {
      const options = HEADER_OPTIONS_AUTH(user.token);
      await axios.get(`${BASE_URL}/receivingInvoiceSkids`, options)
        .then(function (response) {

          if (response.status === 200 || response.status === 304) {
            formatData(response.data.data.rows);
          }
        }).catch(function (error) {
          setloading(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    }
    catch (e) {
      console.log("error! " + e);
      openNotificationWithIcon(
        "error",
        e
      );
    }
  };

  const getSkidTableData = async (pageNumber) => {
    try {
      setloadingSkidsTableData(true);
      const options = HEADER_OPTIONS_AUTH(user.token);
      await axios.get(`${BASE_URL}/getSkidsByPage/${pageNumber}`, options)
        .then(function (response) {
          if (response.status === 200 || response.status === 304) {
            const data = response.data.data;
            setskidsTableData(data.rows);
            settotalSkids(data.total);
            setloadingSkidsTableData(false);
          }

        }).catch(function (error) {
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    }
    catch (e) {
      console.log("error! " + e);
    }
  }

  const formatData = (skids) => {
    if (skids && skids.length > 0) {
      let formattedData = {};
      skids.map(skid => {
        if (!formattedData.hasOwnProperty(skid.companyName)) {
          formattedData[skid.companyName] = [];
        }
        if (typeof skid.skidContent !== 'object') {
          skid.skidContent = JSON.parse(skid.skidContent);
        }
        skid.receivedDate = moment(skid.receivedDate).isValid() ? moment(skid.receivedDate).format("YYYY-MM-DD") : "0000-00-00"
        formattedData[skid.companyName].push(skid);
      })
      setformattedSkids(formattedData);
    } else {
      setformattedSkids({});
    }
    setloading(false);
  }

  const changePageNumber = (pageNumber) => {

    getSkidTableData(pageNumber, user);
    setcurrentSkidsTablePage(pageNumber);
  }


  const getColumnSearchProps = (dataIndex, searchKey) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => { }, 100);
        // this.searchInput.select()
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const skidTableColumns = [
    {
      title: 'Company Name', dataIndex: 'companyName', key: 'companyName'
      //, ...getColumnSearchProps('company name','company.companyName')
    },
    {
      title: 'Skid Barcode', dataIndex: 'barCode', key: 'barCode'
      //,...getColumnSearchProps('skid barcode','skid.barCode') 
    },
    {
      title: 'Ship Date', dataIndex: 'scheduledShipDate', key: 'scheduledShipDate', render: (pro) => {
        return (moment(pro).isValid() ? moment(pro).format("YYYY-MM-DD") : "0000-00-00")
      }
    },
    {
      title: 'Receive Date', dataIndex: 'receivedDate', key: 'receivedDate', render: (pro) => {
        return (moment(pro).isValid() ? moment(pro).format("YYYY-MM-DD") : "NOT RECEIVED")
      }
    },
    { title: 'Units', dataIndex: 'units', key: 'units' },
    // {
    //   title: 'Receive Status', dataIndex: 'process_status', key: 'process_status',
    //   render: (pro) => {
    //     if (pro === "PROCESSED") {
    //       return (
    //         <span>SKID RECEIVED</span>
    //       )
    //     } else {
    //       return (
    //         <span>{pro}</span>
    //       )
    //     }
    //   }
    // },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Closed Status', dataIndex: 'close_status', key: 'close_status' },
  ];

  const expandedRowRender = (e) => {
    const columns = [
      { title: 'Number', dataIndex: 'key', key: 'key' },
      { title: 'Bar Code', dataIndex: 'barCode', key: 'barCode' },
      { title: 'Receive Date', dataIndex: 'receivedDate', key: 'receivedDate' },
      { title: 'Process Date', dataIndex: 'processDate', key: 'processDate' },
      { title: 'Status', dataIndex: 'status', key: 'status' },
      { title: 'Billing Status', dataIndex: 'billing_status', key: 'billing_status' },
    ];

    const data = [];

    return <Table columns={columns} dataSource={data} />;
  };

  const handleChangeComapnyName = (companyName) => {
    setSelectedCompany(companyName);
    if (companyName !== "default") {
      setvisible(true);
    }
  }

  const onClose = () => {
    setvisible(false);
  }

  const handleSubmitInvoice = async () => {
    // openNotificationWithIcon('warning', `Please use laceroscan.app for createing Receiving invoice.`);

    if (formattedSkids[selectedCompany].length > 0) {
      const options = HEADER_OPTIONS_AUTH(user.token);
      setloading(true);
      await axios.post(`${BASE_URL}/createQBReceivingInvoice`, formattedSkids[selectedCompany][0], options)
        .then(function (response) {
          getSkidsForReceivingInvoice(user);
          openNotificationWithIcon('success', `Invoice created successfully.`);
          setvisible(false);
        })
        .catch(function (error) {
          setloading(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });
    } else {
      openNotificationWithIcon('warning', `Please select Client to create purchase order`);
    }
  }

  const columns = [
    { title: 'Skid', dataIndex: 'barCode', key: 'barCode' },
    { title: 'ReceiveDate', dataIndex: 'receivedDate', key: 'receivedDate' },
    { title: 'Units', dataIndex: 'units', key: 'units' },
    { title: 'Status', dataIndex: 'status', key: 'status' }
  ];



  return (
    <div>
      <h1 align="center" style={{ marginBottom: '40px' }}>Products Overview</h1>
      <Table
        className="skids-nested-table"
        columns={skidTableColumns}
        expandedRowRender={expandedRowRender}
        pagination={false}
        dataSource={skidsTableData}
        loading={loadingSkidsTableData}
        size={"small"}
      />
      <div align="right">
        <Pagination
          total={totalSkids}
          current={currentSkidsTablePage}
          onChange={changePageNumber}
          loading={totalSkids === undefined}
          pageSize={10}
          responsive={true}
          showSizeChanger={false}
          style={{ marginTop: "15px" }}
        />
      </div>
      <Card style={{ marginTop: "20px" }} title={<h3><DollarTwoTone twoToneColor="#52c41a" /> Bulk Receiving Invoicing</h3>} bordered={false}>
        <div>
          <Select defaultValue={selectedCompany} value={selectedCompany} size={"large"} showArrow style={{ width: 500 }} onChange={handleChangeComapnyName}>
            <Option key="default" value={"default"}>Choose Company For Receiving Invoice</Option>
            {Object.keys(formattedSkids).map(companyName => (
              <Option key={companyName} value={companyName}>{companyName}</Option>
            ))}
          </Select>
        </div>
      </Card>

      <div>

        <Drawer
          title={<p>Create Bulk Invoice For:  <Tag color="#f50">{selectedCompany}</Tag></p>}
          placement="right"
          width={800}
          closable={false}
          onClose={onClose}
          visible={visible}
        >

          <div>
            <Select defaultValue="Skid" size={"large"} style={{ width: 400 }} >
              <Option value="skids">Skid</Option>
            </Select>
          </div>

          <Table
            size={"small"}
            columns={columns}
            dataSource={formattedSkids[selectedCompany]}
          />

          <div style={{ background: '#ECECEC', padding: '30px', marginBottom: '40px' }}>
            <Row gutter={16}>

              <Col span={24}>
                <Card>
                  <Statistic
                    title="Number of Skids"
                    value={formattedSkids[selectedCompany] ? formattedSkids[selectedCompany].length : 0}
                    valueStyle={{ color: 'black' }}
                    suffix="box"
                  />
                </Card>
              </Col>
            </Row>
          </div>


          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e8e8e8',
              padding: '10px 16px',
              textAlign: 'right',
              left: 0,
              background: '#fff',
              borderRadius: '0 0 4px 4px',
            }}
          >
            <Popconfirm
              title="Are you sure create bulk invoice?"
              onConfirm={handleSubmitInvoice}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={loading} hidden={user.role === "businessRep" || user.role === "admin"} >
                Create an Invoice
                  </Button>
            </Popconfirm>
          </div>

        </Drawer>

      </div>

    </div>
  )

}