import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from "react-redux";
import * as actions from "../../actions";
class UpdateSelf extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            //user : undefined,
            newPassword: '',
            confirmPassword: '',
            backUpEmail: ''
        }
    }
    componentDidMount() {
        if (!this.props.authenticated) {
            this.props.history.push('/');
        } else {
            this.setState({ user: this.props.authenticated, backUpEmail: this.props.authenticated.email })
        }
    }
    handleFormOnchange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const user = this.state.user;
        user[name] = value;
        this.setState({
            user
        })

    };
    handlePasswordOnchange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        })
    };
    handleCheckPassword = () => {
        const newPassword = this.state.newPassword;
        const confirmPassword = this.state.confirmPassword;
        if (newPassword !== confirmPassword) {
            alert("Password Not Match!");
            this.setState({
                newPassword: '',
                confirmPassword: ''
            })
        }
    };
    handleUpdate = async () => {
        const newPassword = this.state.newPassword;
        const confirmPassword = this.state.confirmPassword;
        //update password
        const user = this.state.user;
        if (newPassword !== "" && confirmPassword !== "") {
            user.password = newPassword;
            const res = await this.props.upDateUserSelf(this.props.authenticated, user);
            if (res === "Request failed with status code 409") {
                user.email = this.state.backUpEmail;
            }
            this.setState({
                newPassword: '',
                confirmPassword: '',
                user
            })
        }
        //don't update password
        else {
            const res = await this.props.upDateUserSelf(this.props.authenticated, user);
            if (res === "Request failed with status code 409") {
                user.email = this.state.backUpEmail;
                this.setState({ user })
            }
        }

    };
    render() {
        const user = this.state.user;
        return (
            <div style={{ textAlign: 'center' }}>
                <h1 style={{ margin: '8vh 0' }}>Update Information</h1>
                {
                    user !== undefined &&
                    <Form>
                        <Form.Group as={Row} controlId="formEmail">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                Email
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'text'} name={'email'} value={user.email} onChange={this.handleFormOnchange} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formNewPassword">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                New Password
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'password'} name={'newPassword'} value={this.state.newPassword} onChange={this.handlePasswordOnchange} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formConfirmPassword">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                Confirm Password
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'password'} name={'confirmPassword'} value={this.state.confirmPassword} onChange={this.handlePasswordOnchange} onBlur={this.handleCheckPassword} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPhone">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                Phone Number
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'text'} name={'phone'} value={user.phone} onChange={this.handleFormOnchange} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formFirstName">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                First Name
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'text'} name={'firstName'} value={user.firstName} onChange={this.handleFormOnchange} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formLastName">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                Last Name
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'text'} name={'lastName'} value={user.lastName} onChange={this.handleFormOnchange} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formUserName">
                            <Col sm="3"> </Col>
                            <Form.Label column sm="2">
                                User Name
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control type={'text'} name={'userName'} value={user.userName} onChange={this.handleFormOnchange} />
                            </Col>
                            <Col sm="3"> </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm={{ span: 10, offset: 2 }}>
                                <Button type="button" onClick={this.handleUpdate}>Save Changes</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        errorMessage: state.errorMessage
    }
};
export default connect(mapStateToProps, actions)(UpdateSelf);
