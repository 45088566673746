import {
    Button, Form,
    Select,
    Col,
    DatePicker,
    Table,
    Tabs,
    Row, Popconfirm, Typography, Input, InputNumber
} from "antd";
import {
    Button as ReactButton,
    Form as ReactForm
} from "react-bootstrap";
import axios from 'axios';
import { openNotificationWithIcon } from "../lib/utils";
import { BASE_URL, HEADER_OPTIONS_AUTH } from '../lib/basic';
import ReactTable from 'react-table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getBusinessRepWithPO } from "../../actions/index";
import { Modal } from "react-bootstrap";
const { TabPane } = Tabs;
export const ManageBusinessRepUI = (params) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(state => state.authenticated);
    const bussRepList = useSelector(state => state.bussRepListWithPO);
    const [activeTab, setactiveTab] = useState("1");
    const [showDownloadReportByYear, setshowDownloadReportByYear] = useState(false);
    const [showDownloadReportByRange, setshowDownloadReportByRange] = useState(false);
    const { Option } = Select;
    const [loadingByYear, setloadingByYear] = useState(false);
    const [loadingByRange, setloadingByRange] = useState(false);

    useEffect(() => {
        if (!user || user.role !== "superAdmin") {
            history.push('/');
        } else {
            getBusinessRepWithPO(user, dispatch);
        }
    }, [])

    const changeTab = activeKey => {
        setactiveTab(activeKey);
    };

    const ManageBusinessRepsTabs = () => {
        return (
            <Tabs
                type="card"
                centered
                activeKey={activeTab}
                onChange={changeTab}
                style={{ marginTop: "25px" }}
            >
                <TabPane tab="List" key="1" >
                    <ListbusinessRep />
                </TabPane>
                <TabPane tab="Commission Structure" key="2" >
                    <CommissionStructureUI />
                </TabPane>
            </Tabs>
        );
    };

    const CommissionStructureUI = () => {

        const [form] = Form.useForm();
        const [form1] = Form.useForm();
        const { Option } = Select;
        const [loading, setloading] = useState(false);
        const [updateLoading, setupdateLoading] = useState(false);
        const [visible, setvisible] = useState(false);
        const [data, setdata] = useState([]);
        const [selectedBusRepId, setselectedBusRepId] = useState(undefined);
        const [selectedYear, setselectedYear] = useState('');
        const [selectedBusRepName, setselectedBusRepName] = useState('');
        const [editingKey, setEditingKey] = useState('');

        const isEditing = (record) => record.key === editingKey;

        const cancel = () => {
            setEditingKey('');
        };

        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
        };

        const edit = (record) => {
            form1.setFieldsValue({
                start: '',
                end: '',
                price: '',
                ...record,
            });
            setEditingKey(record.key);
        };


        const updateCommissionStructure = async (data, newData) => {
            setupdateLoading(true);
            await axios.patch(`${BASE_URL}/updateCommissionStructure`, data, HEADER_OPTIONS_AUTH(user.token))
                .then(async function (response) {
                    setupdateLoading(false);
                    setdata(newData);
                    setEditingKey('');
                })
                .catch(async function (error) {
                    setupdateLoading(false);
                    setEditingKey('');
                    console.log(error);
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });
        }

        const save = async (key) => {
            try {
                const row = await form1.validateFields();
                const newData = [...data];
                const index = newData.findIndex((item) => key === item.key);

                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, { ...item, ...row });

                    let data = {};
                    data["tier1"] = newData[0].price;
                    data["tier2"] = newData[1].price;
                    data["tier3"] = newData[2].price;
                    data["bussRepId"] = selectedBusRepId;
                    data["year"] = selectedYear;

                    updateCommissionStructure(data, newData);
                } else {
                    newData.push(row);
                    setdata(newData);
                    setEditingKey('');
                }
            } catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
        };


        const columns = [
            {
                title: 'Starting # Units range',
                dataIndex: 'start',
                editable: false
            },
            {
                title: 'End of # Units range',
                dataIndex: 'end',
                editable: false
            },
            {
                title: 'Commission per Unit',
                dataIndex: 'price',
                editable: true
            },
            {
                title: '',
                dataIndex: 'operation',
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <a
                                href="javascript:;"
                                onClick={() => save(record.key)}
                                style={{
                                    marginRight: 8,
                                }}
                                loading={updateLoading}
                            >
                                Save
                      </a>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                <a loading={updateLoading}>Cancel</a>
                            </Popconfirm>
                        </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            },
        ];

        const showTable = () => {
            setvisible(true);
        }

        const mergedColumns = columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: col.dataIndex === 'price' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
            };
        });

        const getCommissionStructure = async (values) => {
            setloading(true);
            await axios.get(`${BASE_URL}/commissionStructure/${values.businessRepId}/${values.year.format('YYYY')}`, HEADER_OPTIONS_AUTH(user.token))
                .then(async function (response) {

                    setdata([{ key: 1, start: "0", end: "30,000", price: response.data.data.tier1 }, { key: 2, start: "30,001", end: "75,000", price: response.data.data.tier2 }, { key: 3, start: "75,001", end: "999,999,999", price: response.data.data.tier3 }]);
                    setselectedYear(values.year.format('YYYY'));
                    let businessRepObj = bussRepList && bussRepList.filter(user => user.id === values.businessRepId);
                    setselectedBusRepId(values.businessRepId);
                    setselectedBusRepName(businessRepObj[0].firstName + " " + businessRepObj[0].lastName);
                    showTable();
                    setloading(false);
                })
                .catch(async function (error) {
                    console.log(error);
                    if (error.response) {
                        // Request made and server responded
                        openNotificationWithIcon(
                            "error",
                            error.response.data.message
                        );
                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });
        }

        const getCommissionTableHeader = () => {
            return (<div style={{ fontSize: 16 }}>{"Name: " + selectedBusRepName + "  |  Year: " + selectedYear}</div>);
        }

        const EditableCell = ({
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        }) => {
            const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
            return (
                <td {...restProps}>
                    {editing ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {inputNode}
                        </Form.Item>
                    ) : (
                        children
                    )}
                </td>
            );
        };

        return (
            <div>
                <Row>
                    <Col span={9} >
                        <div style={{ position: "fixed", height: "100%", width: "37%", background: "#FAFAFA" }} >

                            <Form
                                {...layout}
                                size="medium"
                                className="commission-structure-form"
                                onFinish={getCommissionStructure}
                                form={form}
                                style={{ marginTop: "30%", marginRight: "14%" }}
                            >

                                <Form.Item
                                    label="Business Rep."
                                    name="businessRepId"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Business Rep.!"
                                        }
                                    ]}
                                >
                                    <Select name="businessRepId">
                                        {bussRepList && bussRepList.map(user =>
                                        (
                                            <Option key={user.id} value={user.id}>
                                                {user.firstName + " " + user.lastName}
                                            </Option>
                                        )
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Year"
                                    name="year"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input Year!"
                                        }
                                    ]}
                                >
                                    <DatePicker picker="year" style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    style={{ marginTop: "25px" }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ left: "50%" }}
                                        loading={loading}
                                    >
                                        Look Up
                                </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col span={15} hidden={!visible}>
                        <Form form={form1} component={false} >
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                columns={mergedColumns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                title={() => getCommissionTableHeader()}
                            />
                        </Form>
                    </Col>
                </Row>
            </div >
        )
    }

    const ListbusinessRep = () => {

        const [availablePOSelectForm] = Form.useForm();
        const [form1] = Form.useForm();
        const [showPOs, setshowPOs] = useState(false);
        const [selectedUser, setselectedUser] = useState(undefined);
        const [addPOloading, setaddPOloading] = useState(false);
        const [removePOloading, setremovePOloading] = useState(false);
        const [editingKey, setEditingKey] = useState('');
        const [data, setdata] = useState([]);
        const isEditing = (record) => record.key === editingKey;

        const cancel = () => {
            setEditingKey('');
        };

        const edit = (record) => {
            form1.setFieldsValue({
                orderNumber: '',
                ...record,
            });
            setEditingKey(record.key);
        };

        const columns = [{
            id: 'fullNAME',
            Header: 'FULL NAME',
            accessor: d => `${d.firstName}  ${d.lastName}`,
            // accessor: d => d.friend.name // Custom value accessors!
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        }, {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'EMAIL',
            accessor: 'email',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        }, {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'PHONE',
            accessor: 'phone',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            Header: 'POs',
            sortable: false,
            filterable: false,
            Cell: (pro) =>
                <Button type="primary" onClick={() => openPOsModal(pro)}>
                    POs
                </Button>
        }];

        const openPOsModal = (user) => {
            let data = [];
            if (user.original.orderNumber !== null) {
                let orderNumberArray = user.original.orderNumber.split(',');
                let poIdArray = user.original.poid.split(',');

                orderNumberArray.forEach((orderNumber, index) => {
                    data.push({ "orderNumber": orderNumber, key: poIdArray[index] });
                })
            }
            setdata(data);
            setselectedUser(user.original);
            setshowPOs(true);
        }

        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
        };


        const EditableCell = ({
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            children,
            ...restProps
        }) => {
            const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
            return (
                <td {...restProps}>
                    {editing ? (
                        <Form.Item
                            name={dataIndex}
                            style={{
                                margin: 0,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Please Input ${title}!`,
                                },
                            ]}
                        >
                            {inputNode}
                        </Form.Item>
                    ) : (
                        children
                    )}
                </td>
            );
        };


        const POsTableColumns = [
            {
                title: 'OrderNumber',
                dataIndex: 'orderNumber',
                editable: false,
                align: "center"
            },
            {
                title: '',
                dataIndex: 'operation',
                align: "center",
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <Popconfirm title="Sure to remove?" onConfirm={() => removePOFromSelectedRep(record)}>
                                <a
                                    href="javascript:;"
                                    style={{
                                        marginRight: 8,
                                    }}
                                    loading={removePOloading}
                                >
                                    Remove
                               </a>
                            </Popconfirm>
                            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                                <a>Cancel</a>
                            </Popconfirm>
                        </span>
                    ) : (
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Typography.Link>
                    );
                },
            },
        ];

        const mergedColumns = POsTableColumns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    inputType: col.dataIndex === 'price' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
            };
        });

        const addPOToSelectedRep = async (values) => {
            if (window.confirm('Do you confirm to Add?')) {
                const orderNumber = selectedUser.availableOrderNumber.split(',')[values.orderNumber];
                const poId = selectedUser.availableId.split(',')[values.orderNumber];
                let data = {};
                data["poId"] = poId;
                data["orderNumber"] = orderNumber;
                data["userId"] = selectedUser.id;
                setaddPOloading(true);
                await axios.post(`${BASE_URL}/assignPO`, data, HEADER_OPTIONS_AUTH(user.token))
                    .then(function (response) {
                        setaddPOloading(false);
                        openNotificationWithIcon('success', 'PO Added!');
                        getBusinessRepWithPO(user, dispatch);
                        form1.resetFields();
                    })
                    .catch(function (error) {
                        if (error.response) {
                            // Request made and server responded
                            setaddPOloading(false);
                            openNotificationWithIcon('error', `Create Client due to ${error.response.data.message}`);

                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });
            }
        }


        const removePOFromSelectedRep = async (values) => {
            console.log(values);
            const orderNumber = values.orderNumber;
            const poId = parseInt(values.key);
            let data = {};
            data["poId"] = poId;
            data["orderNumber"] = orderNumber;
            data["userId"] = selectedUser.id;
            setremovePOloading(true);
            await axios.post(`${BASE_URL}/removePOFromRep`, data, HEADER_OPTIONS_AUTH(user.token))
                .then(function (response) {
                    setremovePOloading(false);
                    openNotificationWithIcon('success', 'PO Removed!');
                    getBusinessRepWithPO(user, dispatch);
                    form1.resetFields();
                })
                .catch(function (error) {
                    if (error.response) {
                        // Request made and server responded
                        setremovePOloading(false);
                        openNotificationWithIcon('error', `Create Client due to ${error.response.data.message}`);

                    } else if (error.request) {
                        // The request was made but no response was received
                        openNotificationWithIcon(
                            "error",
                            "No internet!"
                        );
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        openNotificationWithIcon(
                            "error",
                            error.message
                        );
                    }
                });
        }


        return (
            <div>
                <ReactTable
                    data={bussRepList ? bussRepList : []}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                    columns={columns}
                />

                <Modal
                    backdrop="static"
                    show={showPOs}
                    onHide={() => setshowPOs(false)}
                    centered
                    dialogClassName={"showPOs-modal"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{"Manage POs - " + (selectedUser !== undefined ? selectedUser.firstName : "") + " " + (selectedUser !== undefined ? selectedUser.lastName : "")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <Form
                            {...layout}
                            size="medium"
                            className="manage-pos-form"
                            onFinish={addPOToSelectedRep}
                            form={availablePOSelectForm}
                            style={{ marginTop: "5px" }}
                        >
                            <Row style={{ marginTop: "15px" }}>
                                <Col span={17}>
                                    <Form.Item
                                        label="Order Number: "
                                        name="orderNumber"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select Order Number!"
                                            }
                                        ]}
                                    >
                                        <Select name="orderNumber">
                                            {selectedUser && selectedUser.availableOrderNumber && (selectedUser.availableOrderNumber).split(',').map((order, index) =>
                                            (
                                                <Option key={index} value={index}>
                                                    {order}
                                                </Option>
                                            )
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={7} style={{ paddingRight: "35px" }}>
                                    <Form.Item >

                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{ left: "50%" }}
                                            loading={addPOloading}
                                        >
                                            Add
                                </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <div style={{ paddingLeft: "6%", paddingRight: "6%", alignContent: "center" }}>
                            <Form form={form1} component={false} >
                                <Table
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    columns={mergedColumns}
                                    dataSource={data}
                                    bordered
                                    pagination={false}
                                />
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        )
    }


    const downloadReportByYear = async (year) => {

        let data = {};
        data["year"] = year["year"].format('YYYY');
        const options = HEADER_OPTIONS_AUTH(user.token);
        options.responseType = 'blob';
        setloadingByYear(true);
        await axios.post(`${BASE_URL}/downloadCommissionReportByYear`, data, options)
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ReportByYear${year["year"].format('YYYY')}.xlsx`);
                document.body.appendChild(link);
                link.click();
                openNotificationWithIcon('success', `Download Report Success`);
                setloadingByYear(false);
                setshowDownloadReportByYear(false);
            })
            .catch(function (error) {
                setloadingByYear(false);
                if (error.response) {
                    // Request made and server responded
                    openNotificationWithIcon('error', `Create Client due to ${error.response.data.message}`);

                } else if (error.request) {
                    // The request was made but no response was received
                    openNotificationWithIcon(
                        "error",
                        "No internet!"
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    openNotificationWithIcon(
                        "error",
                        error.message
                    );
                }
            });
    };

    const downloadReportByRange = async (value) => {
        //console.log(value);
        // console.log(year["year"].format('YYYY'));
        let data = {};
        data["businessRepId"] = value["businessRepId"];
        data["start"] = value["start"].format('YYYY-MM');
        data["endForYear"] = value["end"].format('YYYY-MM');
        data["end"] = value["end"].add(1, 'M').format('YYYY-MM');


        const options = HEADER_OPTIONS_AUTH(user.token);
        options.responseType = 'blob';
        setloadingByRange(true);
        await axios.post(`${BASE_URL}/downloadCommissionReportByRange`, data, options)
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `CommissionReport.xlsx`);
                document.body.appendChild(link);
                link.click();
                openNotificationWithIcon('success', `Download Report Success`);
                setloadingByRange(false);
                setshowDownloadReportByRange(false);
            })
            .catch(function (error) {
                setloadingByRange(false);
                if (error.response) {
                    // Request made and server responded
                    openNotificationWithIcon('error', `Download Report due to ${error.response.data.message}`);

                } else if (error.request) {
                    // The request was made but no response was received
                    openNotificationWithIcon(
                        "error",
                        "No internet!"
                    );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    openNotificationWithIcon(
                        "error",
                        error.message
                    );
                }
            });
    };



    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <ReactForm.Group
                as={Row}
                controlId="formPlaintextEmail"
                style={{ marginBottom: 5, marginTop: 21 }}
            >
                <Col span={14}>
                    <h1>Manage Business Representative</h1>
                </Col>
                <ReactForm.Label column span={5}>
                    <ReactButton variant="success" onClick={() => setshowDownloadReportByYear(true)}>
                        Download Report By Year
              </ReactButton>
                </ReactForm.Label>
                <ReactForm.Label column span={5} style={{ paddingRight: 21 }}>
                    <ReactButton variant="primary" onClick={() => setshowDownloadReportByRange(true)}>
                        Download Report By Range
              </ReactButton>
                </ReactForm.Label>
            </ReactForm.Group>
            < ManageBusinessRepsTabs />
            <Modal
                backdrop="static"
                show={showDownloadReportByYear}
                onHide={() => setshowDownloadReportByYear(false)}
                centered
                dialogClassName={"donwloadReportByYear-modal"}
            >
                <Modal.Header closeButton>
                    <Modal.Title> Download Report By Year </Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <div style={{ marginLeft: "11%", marginRight: "10%", marginBottom: "4%", marginTop: "7%" }}>
                        <Form
                            layout="horizontal"
                            size="medium"
                            onFinish={downloadReportByYear}
                        >
                            <Row>
                                <Col span={14}>
                                    <Form.Item
                                        label="Year"
                                        name="year"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please input Year!"
                                            }
                                        ]}
                                    >
                                        <DatePicker picker="year" style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        style={{ marginLeft: "39%" }}
                                    >

                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="medium"
                                            loading={loadingByYear}
                                        >
                                            Download
                                                </Button>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Form>

                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                backdrop="static"
                show={showDownloadReportByRange}
                onHide={() => setshowDownloadReportByRange(false)}
                centered
                dialogClassName={"donwloadReportByRange-modal"}
            >
                <Modal.Header closeButton>
                    <Modal.Title> Download Report By Range </Modal.Title>
                </Modal.Header>
                <Modal.Body >

                    <div style={{ marginLeft: "11%", marginRight: "15%", marginBottom: "4%", marginTop: "7%" }}>
                        <Form
                            {...layout}
                            size="medium"
                            onFinish={downloadReportByRange}
                        >

                            <Form.Item
                                label="Business Rep."
                                name="businessRepId"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select Business Rep.!"
                                    }
                                ]}
                            >
                                <Select name="businessRepId">
                                    {bussRepList && bussRepList.map(user =>
                                    (
                                        <Option key={user.id} value={user.id}>
                                            {user.firstName + " " + user.lastName}
                                        </Option>
                                    )
                                    )}
                                </Select>
                            </Form.Item>


                            <Form.Item
                                label="Start"
                                name="start"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input Start!"
                                    }
                                ]}
                            >
                                <DatePicker picker="month" style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                                label="End"
                                name="end"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input End!"
                                    }
                                ]}
                            >
                                <DatePicker picker="month" style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item
                                style={{ marginLeft: "39%", marginTop: "35px" }}
                            >

                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="medium"
                                    loading={loadingByRange}
                                >
                                    Download
                                                </Button>
                            </Form.Item>


                        </Form>

                    </div>
                </Modal.Body>
            </Modal>


        </div>
    )

}
