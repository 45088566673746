import { GET_WEEKLY_SKID_KPI, UPDATE_WEEKLY_SKID_KPI } from '../actions/types';
export default function (state = false, action) {
    switch (action.type) {
        case GET_WEEKLY_SKID_KPI:
            return action.payload;
        case UPDATE_WEEKLY_SKID_KPI:
            return state.map((kip) => {
                if (kip.id === action.payload.id) {
                    return action.payload;
                }
                return kip;
            });
        default:
            return state;
    }
}