import React, { useEffect, useState } from 'react'
import { Column } from '@ant-design/charts';
import { DatePicker, Table } from 'antd';
import { useSelector } from 'react-redux';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from '../lib/utils';
import moment from 'moment';

export const DailyTarget = (params) => {
  let history = useHistory();
  const user = useSelector(state => state.authenticated);
  const [processedKPI, setprocessedKPI] = useState([]);
  const [dailyKPI, setdailyKPI] = useState([]);
  const [selectedDate, setselectedDate] = useState(moment());
  const [loadingGraph, setloadingGraph] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);

  const getDailyProcessedKPI = async (date) => {
    try {
      const options = HEADER_OPTIONS_AUTH(user.token);
      const data = { "date": date };
      setloadingGraph(true);
      await axios.post(`${BASE_URL}/getDailyProcessedKPI`, data, options)
        .then(function (response) {
          setprocessedKPI(response.data.data);
          setloadingGraph(false);
        })
        .catch(function (error) {
          setloadingGraph(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    }
    catch (e) {
      setloadingGraph(false);
      console.log(e);
      openNotificationWithIcon(
        "error",
        e
      );
    }
  };

  const getDailyKPI = async (date) => {
    try {
      const options = HEADER_OPTIONS_AUTH(user.token);
      const data = { "date": date };
      setloadingTable(true);
      await axios.post(`${BASE_URL}/getDailyKPIByDate`, data, options)
        .then(function (response) {
          setdailyKPI(response.data.data);
          setloadingTable(false);
        })
        .catch(function (error) {
          setloadingTable(false);
          if (error.response) {
            // Request made and server responded
            openNotificationWithIcon(
              "error",
              error.response.data.message
            );
          } else if (error.request) {
            // The request was made but no response was received
            openNotificationWithIcon(
              "error",
              "No internet!"
            );
          } else {
            // Something happened in setting up the request that triggered an Error
            openNotificationWithIcon(
              "error",
              error.message
            );
          }
        });

    }
    catch (e) {
      setloadingTable(false);
      console.log(e);
      openNotificationWithIcon(
        "error",
        e
      );
    }
  };

  useEffect(() => {
    if (!user) {
      history.push('/');
    } else {
      document.getElementById("navbar").style = "display: none";
      getDailyProcessedKPI(moment().format("YYYY-MM-DD"));
      getDailyKPI(moment().format("YYYY-MM-DD"));
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(function () {
      refreshData();
    }, 180000);
    return () => clearInterval(timer);
  })

  const refreshData = () => {
    // console.log("Selected Date: " + selectedDate.format("YYYY-MM-DD"));
    // console.log("Today's Date: " + moment().format("YYYY-MM-DD"));
    // console.log(selectedDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"));
    if (selectedDate.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
      getDailyProcessedKPI(moment().format("YYYY-MM-DD"));
      getDailyKPI(moment().format("YYYY-MM-DD"));
    }
  }

  const onDateChange = (val) => {
    if (val) {
      setselectedDate(val);
      getDailyProcessedKPI(val.format("YYYY-MM-DD"));
      getDailyKPI(val.format("YYYY-MM-DD"));
    }
  }

  var config = {
    data: processedKPI,
    isStack: true,
    xField: 'client',
    yField: 'count',
    seriesField: 'productType',
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
      style: {
        fontSize: 18
      }
    },
  };

  const columns = [
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Benchmark',
      dataIndex: 'count',
      key: 'count',
    }
  ];


  return (
    <div>
      <DatePicker style={{ margin: "25px" }} onChange={onDateChange} value={selectedDate} />
      <h2 style={{ marginTop: "20px", marginBottom: "30px", position: "fixed", top: 0, left: "30%" }}>Lacero Solutions Daily Throughput KPIs</h2>
      <Column style={{ marginLeft: "25px", height: "85%", width: "70%", position: "fixed" }} {...config} loading={loadingGraph} />
      <Table dataSource={dailyKPI} rowKey={"id"} loading={loadingTable} columns={columns} style={{ marginRight: "25px", marginBottom: "15px", width: "32%", position: "fixed", right: 0, bottom: 0 }} />
    </div >
  )
}