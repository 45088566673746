import {
    Button, Form,
    Input,
    Table,
    message,
    Upload,
    Tabs
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getProductTypeList } from '../../../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH, HEADER_FORMDATA_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';

const { TabPane } = Tabs;


export const ManageProductType = (params) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const user = useSelector(state => state.authenticated);
    const productTypeList = useSelector(state => state.productTypeList);
    const [activeTab, setactiveTab] = useState("1");

    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "businessRep")) {
            history.push('/');
        } else {
            getProductTypeList(user, dispatch);
        }
    }, [])

    const changeTab = activeKey => {
        setactiveTab(activeKey);
    };

    const ManageProductTypeTabs = () => {
        return (
            <Tabs
                type="card"
                centered
                activeKey={activeTab}
                onChange={changeTab}
            >
                <TabPane tab="List" key="1">
                    <ManageProductTypeScreen />
                </TabPane>
                <TabPane tab="Create New" key="2">
                    <CreateProductTypeScreen />
                </TabPane>
            </Tabs>
        );
    };

    const CreateProductTypeScreen = () => {
        const [productImage, setProductImage] = useState(undefined);
        const [fileList, setFileList] = useState([]);
        const [loading, setLoading] = useState(false);
        const [form] = Form.useForm();

        const onNext = values => {

            let formData = new FormData();
            formData.append("name", values.name);
            formData.append("productTypeImage", productImage);
            formData.append("shortCut", values.shortCut);
            handleSave(formData);
        }

        const handleSave = async (formData) => {
            setLoading(true);
            try {
                await axios.post(`${BASE_URL}/insertNewProductType`, formData, HEADER_FORMDATA_OPTIONS_AUTH(user.token))
                    .then(function (response) {
                        openNotificationWithIcon('success', 'Create Product Type');
                        setLoading(false);
                        setProductImage(undefined);
                        setFileList([]);
                        form.resetFields();
                        getProductTypeList(user, dispatch);

                    })
                    .catch(function (error) {
                        setLoading(false);
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon('error', `Create Product Type due to ${error.response.data.message}`);
                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });
            } catch (e) {
                setLoading(false);
                console.log(e);
                openNotificationWithIcon(
                    "error",
                    e
                );
            }
        }

        const uploadButton = (
            <div>
                { <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        const handleUpload = ({ fileList }) => {

            if (fileList[0]) {
                const isJpgOrPng = fileList[0].type === 'image/jpeg' || fileList[0].type === 'image/png';
                const isLt5M = fileList[0].size / 1024 / 1024 < 1;
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                    setFileList([]);
                }
                else if (!isLt5M) {
                    message.error('Image must be smaller than 1MB');
                    setFileList([]);
                } else {
                    setFileList(fileList)
                    setProductImage(fileList[0].originFileObj);
                }
            }

        };

        const handleRemove = () => {
            setProductImage(undefined);
            setFileList([]);
        };

        return (
            <div style={{ textAlign: 'center' }}>
                <div style={{ padding: '5px', marginTop: "20px", textAlign: 'left' }}>
                    <Form
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 7 }}
                        layout="horizontal"
                        form={form}
                        requiredMark={true}
                        onFinish={onNext}
                        size="medium"
                    >

                        <Form.Item
                            label="Product Type Name:"
                            name="name"
                            rules={[
                                { required: true, message: "Please input Name!" }
                            ]}>
                            <Input maxLength={32} />
                        </Form.Item>


                        <Form.Item
                            label="ShortCut (e.g. SBT)"
                            name="shortCut"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input ShortCut!"
                                }
                            ]}>
                            <Input maxLength={3} />
                        </Form.Item>

                        <Form.Item
                            label="Icon / Thumbnail:"
                            name="productUrl"
                        >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                fileList={fileList}
                                //  onPreview={handlePreview}
                                onChange={handleUpload}
                                beforeUpload={() => false}
                                onRemove={handleRemove}
                            >
                                {productImage ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                        < Form.Item
                            wrapperCol={{
                                span: 5,
                                offset: 9
                            }}
                            style={{ marginTop: "35px" }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={loading}
                            >
                                Add New Product Type
                         </Button>
                        </Form.Item>
                    </Form>

                </div>
            </div>
        );
    }

    const ManageProductTypeScreen = () => {

        const [loading, setLoading] = useState(false);
        const [productImage, setProductImage] = useState(undefined);
        const [fileList, setFileList] = useState([]);
        const [editLoading, seteditLoading] = useState(false);
        const [editForm] = Form.useForm();

        const [selectedType, setselectedType] = useState({});
        const [showEditModal, setshowEditModal] = useState(false);

        const handleEditClose = () => {
            setshowEditModal(false);
            setFileList([]);
            setProductImage(undefined);
        };

        const handleEditShow = (val) => {
            setselectedType({ ...val });
            setshowEditModal(true);
        };


        const handleUpdateProductType = async (val) => {
            seteditLoading(true);
            let formData = new FormData();
            formData.append("id", selectedType.id);
            formData.append("productTypeImage", productImage);
            formData.append("shortCut", val.shortCut);
            formData.append("name", val.name);
            try {
                await axios.post(`${BASE_URL}/updateProductType`, formData, HEADER_FORMDATA_OPTIONS_AUTH(user.token))
                    .then(function (response) {
                        seteditLoading(false);
                        openNotificationWithIcon('success', 'Product Type Updated');
                        getProductTypeList(user, dispatch);
                        setshowEditModal(false);
                    })
                    .catch(function (error) {
                        seteditLoading(false);
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon('error', `Update Product Type due to ${JSON.stringify(error.response.data.message)}`);

                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });

            }
            catch (e) {
                seteditLoading(false);
                openNotificationWithIcon(
                    "error",
                    e
                );
                console.log(e);
            }

        }

        const handleActivate = async (productType) => {
            if (window.confirm('Do you confirm to Activate?')) {
                try {
                    setLoading(true);
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.put(`${BASE_URL}/activateProductType`, productType, options)
                        .then(function (response) {
                            setLoading(false);
                            refreshPage();
                        })
                        .catch(function (error) {
                            setLoading(false);
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Activate Product Type due to ${error.response.data.message}`);
                                //  this.setState({ response: error.response.data.message })

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    setLoading(false);
                    console.log(e);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }

            }
        };

        const refreshPage = () => {
            getProductTypeList(user, dispatch);
        }
        const handleDeactivate = async (productType) => {
            if (window.confirm('Do you confirm to Deactivate?')) {
                try {
                    setLoading(true);
                    const options = HEADER_OPTIONS_AUTH(user.token);
                    await axios.put(`${BASE_URL}/deactivateProductType`, productType, options)
                        .then(function (response) {
                            setLoading(false);
                            refreshPage();
                        })
                        .catch(function (error) {
                            setLoading(false);
                            if (error.response) {
                                // Request made and server responded
                                openNotificationWithIcon('error', `Deactivate Product Type due to ${error.response.data.message}`);
                                //this.setState({ response: error.response.data.message })

                            } else if (error.request) {
                                // The request was made but no response was received
                                openNotificationWithIcon(
                                    "error",
                                    "No internet!"
                                );
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                openNotificationWithIcon(
                                    "error",
                                    error.message
                                );
                            }
                        });

                }
                catch (e) {
                    setLoading(false);
                    console.log(e);
                    openNotificationWithIcon(
                        "error",
                        e
                    );
                }

            }
        };

        const columns = [
            {
                title: 'Product Type Name',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: 'Short Cut',
                dataIndex: 'shortCut',
                key: 'shortCut'
            },
            {
                title: '',
                sortable: false,
                filterable: false,
                render: (text, pro) => (
                    <div>
                        {pro.active ?
                            <Button style={{ marginLeft: '5px' }} type="danger" size="sm block" loading={loading} onClick={() => handleDeactivate(pro)}>
                                Deactivate
                        </Button>
                            : <Button style={{ marginLeft: '5px' }} type="primary" size="sm block" loading={loading} onClick={() => handleActivate(pro)}>
                                Activate
                        </Button>}
                    </div>)
            }, {
                title: 'Edit',
                sortable: false,
                filterable: false,
                render: (text, pro) => (
                    <div>
                        <Button type="primary" size="sm block" onClick={() => handleEditShow(pro)}>
                            Edit
                        </Button>
                    </div>)
            },
        ];


        const handleEditUpload = ({ fileList }) => {
            if (fileList[0]) {
                const isJpgOrPng = fileList[0].type === 'image/jpeg' || fileList[0].type === 'image/png';
                const isLt5M = fileList[0].size / 1024 / 1024 < 1;
                if (!isJpgOrPng) {
                    message.error('You can only upload JPG/PNG file!');
                    setFileList([]);
                }
                else if (!isLt5M) {
                    message.error('Image must be smaller than 1MB');
                    setFileList([]);
                } else {
                    console.log(fileList[0].originFileObj);
                    setFileList(fileList)
                    setProductImage(fileList[0].originFileObj);
                }
            }

        };

        const handleRemove = () => {
            setProductImage(undefined);
            setFileList([]);
        };

        const uploadButton = (
            <div>
                { <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <div style={{ margin: "10px", textAlign: 'center' }}>
                {selectedType !== undefined &&
                    <Modal backdrop="static" show={showEditModal} onHide={handleEditClose} centered dialogClassName={'edit-type-modal'}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Product Type</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form
                                labelCol={{ span: 9 }}
                                wrapperCol={{ span: 12 }}
                                layout="horizontal"
                                form={editForm}
                                requiredMark={true}
                                onFinish={handleUpdateProductType}
                                size="medium"

                            >

                                <Form.Item
                                    label="Product Type Name:"
                                    name="name"
                                    initialValue={selectedType.name}
                                    preserve={false}
                                    rules={[
                                        { required: true, message: "Please input Name!" }
                                    ]}>
                                    <Input maxLength={32} />
                                </Form.Item>


                                <Form.Item
                                    label="ShortCut (e.g. SBT)"
                                    name="shortCut"
                                    initialValue={selectedType.shortCut}
                                    preserve={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input ShortCut!"
                                        }
                                    ]}>
                                    <Input maxLength={3} />
                                </Form.Item>

                                <Form.Item
                                    label="Icon / Thumbnail:"
                                    name="productUrl"
                                >
                                    <Upload
                                        listType="picture-card"
                                        className="avatar-edit-uploader"
                                        fileList={fileList}
                                        //  onPreview={handlePreview}
                                        onChange={handleEditUpload}
                                        beforeUpload={() => false}
                                        onRemove={handleRemove}
                                    >
                                        {productImage ? null : uploadButton}
                                    </Upload>
                                </Form.Item>
                                < Form.Item
                                    wrapperCol={{
                                        span: 5,
                                        offset: 7
                                    }}
                                    style={{ marginTop: "35px" }}
                                >
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        loading={editLoading}
                                    >
                                        Update Product Type
                           </Button>
                                </Form.Item>
                            </Form>

                        </Modal.Body>
                    </Modal>
                }
                {
                    productTypeList.length !== undefined &&
                    <div style={{ marginLeft: "30px", marginRight: "30px" }}>
                        <Table
                            className="product-type-list-table"
                            columns={columns}
                            pagination={false}
                            dataSource={productTypeList}
                            rowKey={record => record.id}
                        />
                    </div>
                }
            </div >
        )
    }

    return (
        <div style={{ textAlign: 'center' }}>
            <h1 style={{ marginBottom: "20px" }}> Manage Product Type</h1>
            <ManageProductTypeTabs />
        </div>
    )

}
