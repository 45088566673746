import axios from "axios";
import { openNotificationWithIcon } from "../lib/utils";

export const USING_PRODUCTION_SERVER = true;

export const TEST_HOME_PAGE = USING_PRODUCTION_SERVER
  ? "https://dev.laceroims.com/home"
  : "http://localhost:3000/home";
export const LOGIN_PAGE = USING_PRODUCTION_SERVER
  ? "https://dev.laceroims.com/"
  : "http://localhost:3000/";
export const BASE_URL = USING_PRODUCTION_SERVER
  ? "https://dev.laceroims.com"
  : "http://localhost:8000";
export const AVERY_URL =
  "http://services.print.avery.com/dpp/public/v1/open/US_en//?averyFileName=http%3A%2F%2Flaceroapp.com%2Favery%2Fls.avery&mergeDataURL=http%3A%2F%2Flaceroapp.com%2Favery%2Flabel.xlsx&provider=Ashlin";

export const DEFAULT_QB_RECEIVE_NAME = "Receiving Service";
export const DEFAULT_QB_PROCESS_NAME = "Processing Service";

export const HEADER_OPTIONS = {
  headers: {
    "Content-Type": "application/json"
  }
};

export const HEADER_OPTIONS_AUTH = token => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  };
};

export const HEADER_FORMDATA_OPTIONS_AUTH = token => {
  return {
    headers: {
      Authorization: "Bearer " + token
    }
  };
};
//some request helpers
export const getSKIDTaskStartNumber = async (user, taskNumber) => {
  let skidTaskStartNumber = "";
  await axios.get(
    `${BASE_URL}/skid/start-number/${taskNumber}`,
    HEADER_OPTIONS_AUTH(user.token)
  )
    .then(function (response) {
      skidTaskStartNumber = response.data.data;
    })
    .catch(function (error) {
      if (error.response) {
        // Request made and server responded
        openNotificationWithIcon(
          "error",
          error.response.data.message
        );
      } else if (error.request) {
        // The request was made but no response was received
        openNotificationWithIcon(
          "error",
          "No internet!"
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        openNotificationWithIcon(
          "error",
          error.message
        );
      }
    });
  return skidTaskStartNumber;
};
