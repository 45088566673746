import {
    Button,


    Form, InputNumber, Select
} from "antd";
import axios from "axios";
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProducts, getUsers } from '../../../../actions/index';
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../../../lib/basic";
import { openNotificationWithIcon } from '../../../lib/utils';
export const PriceSetting = (params) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.authenticated);
    const [form] = Form.useForm();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const allusers = useSelector(state => state.users);
    const [users, setusers] = useState([]);
    const products = useSelector(state => state.products);
    const [selectedCompanyId, setSelectedCompanyId] = useState(undefined);
    const [selectedProduct, setSelectedProduct] = useState(undefined);



    useEffect(() => {
        if (!user || (user.role !== "superAdmin")) {
            history.push('/');
        } else {
            getUsers(user, dispatch);
            getProducts(user, dispatch);
        }
    }, []);

    useEffect(() => {
        if (allusers) {
            setusers(allusers.filter(
                user => user.companyName !== "LACERO" && user.role === "client"
            ));
        }
    }, [allusers])

    const handleFormValuesChange = changedValues => {
        const formFieldName = Object.keys(changedValues)[0];
        if (formFieldName === "companyId") {
            setSelectedCompanyId(changedValues[formFieldName]);
            setSelectedProduct(undefined);
            form.setFieldsValue({ product: undefined });
        } else if (formFieldName === "product") {
            products.filter(product => product.id === changedValues[formFieldName])
                .map(product => {
                    setSelectedProduct(cloneDeep(product));
                }
                );
        }
    };

    const onPriceChange = (id, e) => {

        if (selectedProduct) {
            const product = selectedProduct;
            product.priceList
                .filter(price => price.priceId === id)
                .map(price =>
                    price.price = e
                );

            setSelectedProduct(product);
        }
    }

    const onNext = async () => {
        if (selectedProduct) {

            setLoading(true);
            let data = {};
            data.id = selectedProduct.id;
            data.sku = selectedProduct.sku;
            data.upc = selectedProduct.upc;
            data.productName = selectedProduct.productName;
            data.productType = selectedProduct.productType;
            data.productDetail = selectedProduct.productDetail;
            data.height = selectedProduct.height;
            data.weight = selectedProduct.weight;
            data.width = selectedProduct.width;
            data.length = selectedProduct.length;
            data.productUrl = selectedProduct.productUrl;
            data.companyId = selectedProduct.companyId;
            data.activity = selectedProduct.activity;
            selectedProduct.priceList
                .forEach(price => {
                    if (price.type === "RECEIVE_SERVICE") {
                        data.receivePrice = price.price;
                    } else if (price.type === "PROCESSE_SERVICE") {
                        data.processPrice = price.price;
                    }
                });

            try {
                const options = HEADER_OPTIONS_AUTH(user.token);
                await axios.put(`${BASE_URL}/updateProductPrice`, data, options)
                    .then(function (response) {
                        openNotificationWithIcon('success', 'Default Price Updated');
                        getProducts(user, dispatch);
                        setLoading(false);
                    })
                    .catch(function (error) {
                        setLoading(false);
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon('error', `Update price due to ${error.response.data.message}`);

                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });

            }
            catch (e) {
                openNotificationWithIcon(
                    "error",
                    e
                );
                setLoading(false);
            }
        }
    }

    return (
        <div>
            <h1 style={{ textAlign: 'center', marginBottom: "35px" }}> Price Setting </h1>
            <Form
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="medium"
                form={form}
                className="pricesetting-form"
                requiredMark={false}
                onFinish={onNext}
                onValuesChange={handleFormValuesChange}
            >
                <Form.Item
                    label="Company/Customer: "

                    name="companyId"
                    rules={[
                        { required: true, message: "Please select Company!" }
                    ]}
                >
                    <Select style={{ width: "55%" }}>
                        {users.map((user, index) => {
                            return (
                                <Option key={index} value={user.companyID}>
                                    {user.companyName}
                                </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Product:"
                    name="product"
                    rules={[
                        { required: true, message: "Please select Products!" }
                    ]}
                >

                    <Select
                        style={{ width: "55%" }}
                    >
                        {products ? products
                            .filter(
                                product => product.companyId === selectedCompanyId
                            )
                            .map(product => (
                                <Option key={product.id} value={product.id}>
                                    {product.productName}
                                </Option>
                            )) : <div></div>}
                    </Select>
                </Form.Item>
                {selectedProduct ?


                    <div>
                        {selectedProduct.priceList.map(price => (

                            <Form.Item
                                label={price.type}
                                key={price.priceId}
                                rules={[
                                    { required: true, message: "Please input Price!" }
                                ]}
                            >

                                <InputNumber
                                    min={0}
                                    initialValue={price.price}
                                    defaultValue={price.price}
                                    onChange={e => {
                                        onPriceChange(price.priceId, e);
                                    }}
                                />
                            </Form.Item>


                        )
                        )}
                        < Form.Item
                            wrapperCol={{
                                span: 24,
                                offset: 10
                            }}
                            style={{ marginTop: "25px" }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ marginLeft: "15px" }}
                                size="large"
                                loading={loading}
                            >
                                Update
                         </Button>
                        </Form.Item>
                    </div>



                    : <div></div>
                }
            </Form>
        </div>
    )

}
