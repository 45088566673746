import moment from 'moment';
import React, { Component } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import ReactTable from 'react-table';
import * as actions from "../../actions";
class TaskShipment extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            showEditing: false,
            selectedShipment: undefined,
            showCreate: false,
            receivedDate: undefined,
            newShipment: {},
        }
    }
    componentDidMount() {
        if (!this.props.authenticated) {
            this.props.history.push('/');
        } else {
            const user = this.props.authenticated;
            this.props.getShipment(user);
            this.props.getUsers(user);
        }
    }
    handleSave = async () => {
        if (window.confirm('Do you confirm to update?')) {
            const user = this.props.authenticated;
            const selectedShipment = this.state.selectedShipment;
            const toUpdateShipment = Object.assign({}, selectedShipment);
            delete toUpdateShipment.companyName;
            if (this.state.receivedDate !== undefined) {
                toUpdateShipment.receivedDate = moment(this.state.receivedDate).format('YYYY-MM-DD');
            }
            await this.props.updateShipment(user, toUpdateShipment);
            this.setState({ selectedShipment: undefined, show: false });
            window.location.reload();
        }

    };
    handleCreateShow = () => {
        this.setState({ showCreate: true });
    };
    handleClose = (close) => {
        this.setState({ selectedShipment: undefined, [close]: false, receivedDate: undefined });
    };
    handleEditShow = (val) => {
        this.setState({ selectedShipment: val, showEditing: true });
    };
    handleSelectedShipmentOnchange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const selectedShipment = this.state.selectedShipment;
        selectedShipment[name] = value;
        this.setState({ selectedShipment });
    };
    handleChangeDate = (date) => {
        this.setState({ receivedDate: date })
    };
    handleChangeShipDate = (date) => {
        const newShipment = this.state.newShipment;
        newShipment.shipDate = date;
        this.setState({ newShipment })
    };
    handleSelectCompanyOnchange = (e) => {
        let value = e.target.value;
        if (value === "") {
            alert('Please choose the right CUSTOMER!');
            value = undefined;
        }
        const newShipment = this.state.newShipment;
        newShipment.companyID = value;
        this.setState({ newShipment })

    };
    NewShipmentOnchange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newShipment = this.state.newShipment;
        newShipment[name] = value;
        this.setState({ newShipment });
    };
    handleCreateSave = async () => {
        const newShipment = Object.assign({}, this.state.newShipment);
        newShipment.shipDate = moment(newShipment.shipDate).format('YYYY-MM-DD');
        const user = this.props.authenticated;
        console.log(newShipment)
        this.props.createShipment(user, newShipment);
        window.location.reload();
    };
    render() {
        const data = this.props.shipments;
        const user = this.props.authenticated;
        const users = this.props.users ? this.props.users.filter(user => user.companyName !== "LACERO") : [];
        //console.log(users )
        const columns = [{
            Header: 'COMPANY NAME',
            accessor: 'companyName',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            Header: '# UNITs',
            accessor: 'units',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'BATCH# /SHIP DATE',
            id: 'shipDate',
            accessor: row => (row.shipDate !== undefined && row.shipDate !== '0000-00-00') ? `${row.shipDate.slice(0, 10)}` : 'NOT RECEIVE',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'RECEIVED DATE',
            id: 'receivedDate',
            accessor: row => (row.receivedDate !== undefined && row.receivedDate !== '0000-00-00') ? `${row.receivedDate.slice(0, 10)}` : 'NOT RECEIVE',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            Header: 'REFERENCE #',
            accessor: 'reference',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            // Header: props => <span>Friend Age</span>, // Custom header components!
            Header: 'CARRIER CODE',
            accessor: 'carrierCode',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            Header: 'CARRIER PHONE',
            accessor: 'carrierPhone',
            filterMethod: (filter, row) =>
                row[filter.id].startsWith(filter.value)
        },
        {
            Header: 'EDIT',
            sortable: false,
            filterable: false,
            Cell: (pro) =>
                ((pro.original.receivedDate !== undefined && pro.original.receivedDate === '0000-00-00') || (user.role === "admin" || user.role === "superAdmin" || user.role === "operator")) ?
                    <Button variant="primary" size="sm block" onClick={() => this.handleEditShow(pro.original)}>
                        Edit
                    </Button> : <div> </div>
        }];
        const selectedShipment = this.state.selectedShipment;
        return (
            <div style={{ textAlign: 'center' }}>
                {
                    selectedShipment !== undefined &&
                    <Modal backdrop="static" show={this.state.showEditing} onHide={() => this.handleClose('showEditing')} centered dialogClassName={'user-modal'}>
                        <Modal.Header closeButton>
                            <Modal.Title>Editing Shipment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table responsive bordered={true} style={{ marginBottom: '0px' }}>
                                <tbody>
                                    <tr>
                                        <th>Company Name</th>
                                        <td><Form.Control type="text" value={selectedShipment.companyName} name={'companyName'} onChange={this.handleSelectedShipmentOnchange} disabled /></td>
                                    </tr>
                                    {(user.role === "admin" || user.role === "superAdmin" || user.role === "operator") &&
                                        <tr>
                                            <th>Received Date</th>
                                            <td>
                                                <DatePicker
                                                    selected={this.state.receivedDate === undefined ? null : this.state.receivedDate}
                                                    onChange={this.handleChangeDate}
                                                />
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <th>Reference #</th>
                                        <td><Form.Control type="text" value={selectedShipment.reference} name={'reference'} onChange={this.handleSelectedShipmentOnchange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Code</th>
                                        <td><Form.Control type="text" value={selectedShipment.carrierCode} name={'carrierCode'} onChange={this.handleSelectedShipmentOnchange} /></td>
                                    </tr>
                                    <tr>
                                        <th>Carrier Phone</th>
                                        <td><Form.Control type="text" value={selectedShipment.carrierPhone} name={'carrierPhone'} onChange={this.handleSelectedShipmentOnchange} /></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.handleClose('showEditing')}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={this.handleSave}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                }

                <Modal backdrop="static" show={this.state.showCreate} onHide={() => this.handleClose('showCreate')} centered dialogClassName={'user-modal'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New SKID</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table responsive bordered={true} style={{ marginBottom: '0px' }}>
                            <tbody>
                                <tr>
                                    <th>Company/Customer</th>
                                    <td>
                                        <Form.Control as="select" onChange={this.handleSelectCompanyOnchange} >
                                            <option value={""}>---Select---</option>
                                            {
                                                users.map((user, index) => {
                                                    return <option key={index} value={user.companyID}>{user.companyName} - {user.firstName} {user.lastName}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Scheduled Ship Date</th>
                                    <td>
                                        <DatePicker
                                            selected={this.state.newShipment.shipDate === undefined ? null : this.state.newShipment.shipDate}
                                            onChange={this.handleChangeShipDate}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Units</th>
                                    <td><Form.Control type="text" value={this.state.newShipment.units === undefined ? '' : this.state.newShipment.units} name={'units'} onChange={this.NewShipmentOnchange} /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleClose('showCreate')}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleCreateSave}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>


                {
                    (user.role === "admin" || user.role === "operator") ?
                        <Form.Group as={Row} controlId="formPlaintextEmail" style={{ marginBottom: '0' }}>
                            <Col sm="10">
                                <h1>Manage Shipment</h1>
                            </Col>
                            <Form.Label column sm="2">
                                <Button variant="success" onClick={this.handleCreateShow}>Create  New</Button>
                            </Form.Label>
                        </Form.Group>
                        : <Form.Group as={Row} controlId="formPlaintextEmail" style={{ marginBottom: '0' }}>
                            <Col sm="12">
                                <h1>Manage Shipment</h1>
                            </Col>
                        </Form.Group>
                }
                {
                    data.length !== undefined &&
                    <ReactTable
                        data={data}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={columns}
                    />
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        authenticated: state.authenticated,
        errorMessage: state.errorMessage,
        shipments: state.shipments,
        users: state.users
    }
};
export default connect(mapStateToProps, actions)(TaskShipment);
