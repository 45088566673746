import { message } from 'antd';
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { BASE_URL, HEADER_OPTIONS_AUTH } from "../lib/basic";
import { openNotificationWithIcon } from '../lib/utils';

export const CreateUser = (params) => {
    let history = useHistory();
    const user = useSelector(state => state.authenticated);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (!user || (user.role !== "superAdmin" && user.role !== "admin")) {
            history.push('/');
        }
    }, [])


    const handleCreateUser = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            event.stopPropagation();
            const formData = new FormData(event.target),
                formDataObj = Object.fromEntries(formData.entries())
            console.log(formDataObj);
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(formDataObj.email)) {
                message.error('Please input valid Email (for login)');
            }
            else {
                const data = {
                    companyID: '2',
                    email: formDataObj.email,
                    firstName: formDataObj.firstName,
                    lastName: formDataObj.lastName,
                    password: formDataObj.password,
                    phone: formDataObj.phone,
                    role: formDataObj.role,
                    userName: formDataObj.userName
                };

                await axios.post(`${BASE_URL}/user`, data, HEADER_OPTIONS_AUTH(user.token))
                    .then(function (response) {
                        openNotificationWithIcon('success', 'Create User');
                    })
                    .catch(function (error) {
                        if (error.response) {
                            // Request made and server responded
                            openNotificationWithIcon('error', `Create User due to ${error.response.data.message}`);
                            //  this.setState({ response: error.response.data.message })
                        } else if (error.request) {
                            // The request was made but no response was received
                            openNotificationWithIcon(
                                "error",
                                "No internet!"
                            );
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            openNotificationWithIcon(
                                "error",
                                error.message
                            );
                        }
                    });

            }
        } else {
            setValidated(true);
        }
    };

    return (
        <Container className={'loading'}>
            <style type="text/css">
                {`
                    .loading {
                        min-height: 100vh;
                        min-width: 100vw;
                        background-size: 120vw;
                    }
      
                    .vertical-center {
                       width: 70vh;
                       margin: 0 auto;
                    }
                    .btn-flat {
                      background-color: purple;
                      color: white;
                      margin: 3vh auto 0;
                    }
                
                    .btn-xxl {
                      padding: 1rem 1.5rem;
                      font-size: 1.5rem;
                    }
                   
                    .create{
                         text-align: center;
                         margin-top: 0.5em;
                     
                    }
                    .login-label{
                       font-size: 1.3em;
                    }
                    
                `}
            </style>
            {/*<hr style={{marginBottom:'2vh', marginTop:'0'}}/>*/}
            <div className="vertical-center">

                <Form noValidate validated={validated} onSubmit={handleCreateUser}>
                    { /*   <Form.Group as={Row} >
                            <Form.Label column sm="6" className={"login-label"}>
                               Company
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control className={"login-label"} as="select" onChange={this.onChange} name={'companyID'}  default={this.state.role}>
                                    <option value={'2'}>Honda</option>
                                    <option value={'3'}>Toyota</option>
                                </Form.Control>
                            </Col>
                        </Form.Group>*/}
                    <Form.Group as={Row} >
                        <Form.Label column sm="6" className={"login-label"}>
                            Email ( for login )
                            </Form.Label>
                        <Col sm="6">
                            <Form.Control className={"login-label"} type="email" placeholder="email" name={'email'} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6" className={"login-label"}>
                            Password
                            </Form.Label>
                        <Col sm="6">
                            <Form.Control className={"login-label"} type="password" placeholder="password" name={'password'} autoComplete="new-password" required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6" className={"login-label"}>
                            Role
                            </Form.Label>
                        <Col sm="6">
                            {user.role === "superAdmin" ?
                                <Form.Control className={"login-label"} as="select" name={'role'} defaultValue={'admin'}>
                                    <option value={'operator'} >Operator</option>
                                    <option value={'admin'}>Admin</option>
                                    <option value={'superAdmin'} >Super Admin</option>
                                    <option value={'businessRep'} >Sales Representative</option>

                                </Form.Control> : <Form.Control className={"login-label"} as="select" name={'role'} defaultValue={'admin'}>

                                    <option value={'operator'}>Operator</option>
                                    <option value={'admin'}>Admin</option>
                                    <option value={'businessRep'} >Sales Representative</option>
                                </Form.Control>}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6" className={"login-label"}>
                            User Name(for display)
                            </Form.Label>
                        <Col sm="6">
                            <Form.Control className={"login-label"} type="text" placeholder="user name" name={'userName'} autoComplete="new-password" required />
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} >
                        <Form.Label column sm="6" className={"login-label"}>
                            First Name
                            </Form.Label>
                        <Col sm="6">
                            <Form.Control className={"login-label"} type="text" placeholder="first name" name={'firstName'} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm="6" className={"login-label"}>
                            Last Name
                            </Form.Label>
                        <Col sm="6">
                            <Form.Control className={"login-label"} type="text" placeholder="last name" name={'lastName'} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} >
                        <Form.Label column sm="6" className={"login-label"}>
                            Phone #
                            </Form.Label>
                        <Col sm="6">
                            <Form.Control className={"login-label"} type="text" placeholder="phone #" name={'phone'} />
                        </Col>
                    </Form.Group>
                    <Button variant="flat" size="lg" type="submit" >Create new User</Button>
                </Form>
            </div>
        </Container>
    )

}
